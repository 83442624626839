import * as StompJs from "@stomp/stompjs";
import {
    getConversations,
    getUnreadAction,
    scrollDown,
    updateConversation,
    updateDialog
} from "./messages";
import {playNewMessage} from "../utils/SoundNotification";
import {socketDebug, wsUrl} from "../settings/Settings";

export const connectSocket = (stompClient, stompConfig, messageSubscription) => ({
    type: 'CONNECT_ALL',
    values: {
        stompClient: stompClient,
        stompConfig: stompConfig,
        messageSubscription: messageSubscription
    }
});

export const connectSocketAction = () => {

    const craftyToken = JSON.parse(localStorage.getItem("session"));
    let stompClient, messageSubscription;
    if (!!craftyToken){
        return (dispatch, getState) => {
            const stompConfig = {
                brokerURL: wsUrl + "/secured/room?Authorization="+craftyToken.token,
                // Keep it off for production, it can be quit verbose
                // Skip this key to disable
                debug: (str) => {
                    if (socketDebug){
                        console.log('STOMP: ' + str);
                    }
                },
                // If disconnected, it will retry after 1000ms
                reconnectDelay: 1000,

                onStompError: (receipt) => {
                    console.log('onStompError ', receipt);
                },

                // Subscriptions should be done inside onConnect as those need to reinstated when the broker reconnects
                onConnect:  (frame) => {
                    messageSubscription = stompClient.subscribe('/secured/user/secured/user/queue/specific-user', (message) => {
                        const messageDTO = JSON.parse(message.body);
                        const messages = {...getState().messages};
                        if (messages.isDialog){
                            if (messages.dialogId === messageDTO.conversationId){
                                dispatch(updateDialog(messageDTO));
                                dispatch(scrollDown());
                                stompClient.publish({destination: '/app/secured/read', body: JSON.stringify({messageId: messageDTO.id})});
                                dispatch(sendReadMessage({messageId: messageDTO.id}));
                            } else {
                                // dispatch(playMewMessage());
                                playNewMessage();
                                dispatch(updateConversation(messageDTO));
                                dispatch(getUnreadAction());
                            }
                        } else {
                            // dispatch(playMewMessage());
                            playNewMessage();
                            dispatch(getUnreadAction());
                            dispatch(getConversations());
                        }
                    });
                }
            };


            stompClient = new StompJs.Client(stompConfig);
            stompClient.activate();
            try{
                window.crafty = {
                    stompClient: stompClient,
                    stompConfig: stompConfig,
                    messageSubscription: messageSubscription
                };
            }catch (e){
                console.error(e.message);
            }
        }
    } else {
        return {
            type: 'CONNECT_ALL',
            values: {
                stompClient: null,
                stompConfig: {},
                messageSubscription: null
            }
        }
    }
};

export const sendReadMessage = (message = {messageId: 0}) => {
    return (dispatch, getState) => {
        window.crafty.stompClient.publish({destination: '/app/secured/read', body: JSON.stringify({messageId: message})});
    };
};
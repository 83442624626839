export const setMainCategory = (category) => ({
    type: 'MAIN_CATEGORY',
    settings: {
        mainCategory: category
    }
});

export const setChildCategory = (category) => ({
    type: 'CHILD_CATEGORY',
    settings: {
        child: category
    }
});

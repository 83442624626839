const notificationsReducerDefaultState = {
    open: false,
    queue: [],
    messageInfo: {
        message: '',
        key: new Date().getTime(),
        variant: 'success'
    },
};

export default (state = notificationsReducerDefaultState, action) => {
    switch (action.type) {
        case 'SEND_NOTIFICATION':
            return {
                ...state,
                ...action.notification
            };
        default:
            return state;
    }
};

import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Redirect} from "react-router-dom";
import {deleteGoodsPhoto, editGoods, getMyGoodsById, uploadGoodsPhoto} from "../api/GoodsAPI";
import {editGoodsAdmin, getAdminGoodsById} from "../api/AdminGoodsAPI";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import {emphasize} from "@material-ui/core/styles/colorManipulator";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import ChipInput from 'material-ui-chip-input';
import Slide from "@material-ui/core/Slide/Slide";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {sendNotification} from "../actions/notifications";

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: 716,
        height: 50,
        display: 'inline-grid'
    },
    textArea: {
        flexGrow: 1,
        width: 716,
        height: 110,
        display: 'inline-block'
    },
    input: {
        display: 'flex',
        padding: 0,
        paddingLeft: '20px'
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
        paddingLeft: '20px'
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: '14px',
        opacity: 0.5,
        color: '#283149',
        fontFamily: "Open Sans",
        paddingLeft: '20px'
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
});

const NoOptionsMessage = (props) => {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
};

const inputComponent = ({inputRef, ...props}) => {
    return <div ref={inputRef} {...props} />;
};

const Control = (props) => {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
};

const Option = (props) => {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
};

const Placeholder = (props) => {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
};


const SingleValue = (props) => {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
};

const ValueContainer = (props) => {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
};

const Menu = (props) => {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
};

const MultiValue = (props) => {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
};

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

class EditGoodsPage extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            step: 0,
            progress: 0,
            uploadingPhoto: false,
            displayProgress1: false,
            displayProgress2: false,
            displayProgress3: false,
            displayProgress4: false,
            displayProgress5: false,
            displayProgress6: false,
            close: false,
            validate: false,
            errorStyle: {
                borderBottom: '2px',
                borderBottomColor: '#E60936',
                borderBottomStyle: 'solid',
                paddingLeft: '20px',
                marginTop: '10px'
            },
            goods: {
                id: 0,
                createdDate: moment(),
                updatedDate: moment(),
                alias: null,
                name: '',
                categoryAlias: null,
                category: null,
                description: '',
                tags: '',
                cost: {
                    cost: null,
                    currency: 'BYN'
                },
                avgrating: 0,
                archived: false,
                deleted: false,
                approved: false,
                photos: [
                    {
                        photoMin: "",
                        photoMid: "",
                        photoMax: "",
                        orderPlace: 1
                    },
                    {
                        photoMin: "",
                        photoMid: "",
                        photoMax: "",
                        orderPlace: 2
                    },
                    {
                        photoMin: "",
                        photoMid: "",
                        photoMax: "",
                        orderPlace: 3
                    },
                    {
                        photoMin: "",
                        photoMid: "",
                        photoMax: "",
                        orderPlace: 4
                    },
                    {
                        photoMin: "",
                        photoMid: "",
                        photoMax: "",
                        orderPlace: 5
                    },
                    {
                        photoMin: "",
                        photoMid: "",
                        photoMax: "",
                        orderPlace: 6
                    },
                ],
                materials: null,
                color: null,
                color2: null,
                isAvailable: false

            },
            suggestion: [],
            selectedCategory: null,
            deleteOpen: false,
            photoDeleteNumber: 0,
            deleteInProgress: false
        };
        this.initGoods();
    }

    dialogOnOpen = (orderId) => {
        this.setState(() => ({deleteOpen: true, photoDeleteNumber: orderId}))
    };

    dialogOnClose = () => {
        this.setState(() => ({photoDeleteNumber: 0, deleteOpen: false}));
    };


    uploadPhoto = (orderId = 1) => {

        let formData = new FormData();
        formData.append('file', this.refs['uploadPhoto' + orderId].files[0]);
        this.refs['mainPhoto' + orderId].style.display = 'none';
        this.setState(() => ({['displayProgress' + orderId]: true, uploadingPhoto: true}));
        uploadGoodsPhoto(this.state.goods.id, orderId, formData, this.onProgress).then(
            response => {
                let goods = this.state.goods;
                let newPhoto = JSON.parse(response);
                goods.photos = goods.photos.filter((photo) => (photo.orderPlace !== newPhoto.orderPlace));
                goods.photos.push(newPhoto);
                this.setState(() => ({goods: goods}));
                this.refs['mainPhoto' + orderId].style.display = 'block';
                this.setState(() => ({['displayProgress' + orderId]: false, uploadingPhoto: false}));
            },
            error => {
                this.refs['mainPhoto' + orderId].style.display = 'block';
                this.setState(() => ({['displayProgress' + orderId]: false, uploadingPhoto: false}));
            }
        );

    };

    onProgress = (event) => {

        if (event.lengthComputable) {
            let complete = (event.loaded / event.total * 100 | 0);
            this.setState({progress: complete});
        }
    };

    initGoods = () => {
        (this.props.user.name && this.props.user.roles.filter((role) => (role.name === 'ROLE_ADMIN' || role.name === 'ROLE_MANAGER')).length > 0 )
            ?
            getAdminGoodsById(this.props.match.params.id).then(
                response => {
                    let goods = JSON.parse(response);
                    let category = null;
                    if (goods.categoryAlias) {
                        category = {label: goods.category.name, value: goods.categoryAlias};
                    }
                    if ((goods.tags == null) || (goods.tags.length === 1 && goods.tags[0] === '')){
                        goods.tags = [];
                    }
                    this.setState(() => ({goods: goods, selectedCategory: category}));

                },
                error => {
                    if (error.code === 403) {
                        this.setState(() => ({close: true}));
                    }
                }
            )

        :
            getMyGoodsById(this.props.match.params.id).then(
                response => {
                    let goods = JSON.parse(response);
                    let category = null;
                    if (goods.categoryAlias) {
                        category = {label: goods.category.name, value: goods.categoryAlias};
                    }
                    if ((goods.tags == null) || (goods.tags.length === 1 && goods.tags[0] === '')){
                        goods.tags = [];
                    }
                    this.setState(() => ({goods: goods, selectedCategory: category}));

                },
                error => {
                    if (error.code === 403) {
                        this.setState(() => ({close: true}));
                    }
                }
            )

    };

    selectPhoto = (orderId) => {
        this.refs['uploadPhoto' + orderId].value = null;
        this.refs['uploadPhoto' + orderId].click();
    };

    nameOnChange = (e) => {
        let goods = this.state.goods;
        goods.name = e.target.value;

        this.setState(() => ({goods: goods}));
    };

    componentDidMount() {
        // this.setState(()=>({suggestion: this.props.dict.categories.map((parent) => {
        //         return parent.child;
        //     }).reduce((acc, val) => acc.concat(val), []).map((category) => {
        //         return {label: category.name, value: category.alias};
        //     })}));
        //
        if (window){
            window.scrollTo(0, 0);
        }
        this.setState(() => ({
            suggestion: this.props.dict.categories.map((parent) => {
                return {
                    label: parent.name, options: parent.child.map((category) => {
                        return {label: category.name, value: category.alias}
                    })
                };
            })
        }));
    }

    hexToRGB = (hex) => {
        const RGB_HEX = /^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i;

        const [, short, long] = String(hex).match(RGB_HEX) || [];

        if (long) {
            const value = Number.parseInt(long, 16);
            return [value >> 16, value >> 8 & 0xFF, value & 0xFF];
        } else if (short) {
            return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
        }

    };

    onClose = () => {
        this.props.history.goBack();
        // deleteGoods(this.state.goods.id).then(
        //     response => {
        //         this.props.history.goBack();
        //     },
        //     error => {
        //         this.props.history.goBack();
        //     }
        // );
        // this.setState(()=>({close: true}));
    };

    categoryChange = (e) => {
        let goods = this.state.goods;
        goods.categoryAlias = e.value;
        this.setState(() => ({goods: goods, selectedCategory: e}));
    };

    descriptionOnChange = (e) => {
        let goods = this.state.goods;
        goods.description = e.target.value;
        this.setState(() => ({goods: goods}));
    };

    materialsOnChange = (e) => {
        let goods = this.state.goods;
        goods.materials = e.target.value.substring(0, 255);
        this.setState(() => ({goods: goods}));
    };

    tagsOnChange = (e) => {
        let goods = {...this.state.goods};
        e = e.filter((t) => (!goods.tags.includes(t)));
        goods.tags = [...goods.tags, ...e];
        this.setState(() => ({goods: goods}));
    };


    tagsOnAdd = (e) => {
        let goods = {...this.state.goods};
        if (e.trim() !== ''){
            goods.tags = goods.tags ? goods.tags : [];
            goods.tags.push(e);
        }
        this.setState(() => ({goods: goods}));
    };

    tagsOnBlur = (e) => {
        let goods = {...this.state.goods};
        if (e.trim() !== ''){
            goods.tags.push(e);
        }
        this.setState(() => ({goods: goods}));
    };

    tagsOnDelete = (e) => {
        let goods = {...this.state.goods};
        goods.tags.splice(goods.tags.indexOf(e), 1);
        this.setState({goods: goods});
    };

    colorOnChange = (color) => {
        let goods = this.state.goods;
        goods.color = (color === goods.color ? '' : color);
        this.setState(() => ({goods: goods}));
    };
    color2OnChange = (color) => {
        let goods = {...this.state.goods};
        goods.color2 = (color === goods.color2 ? '' : color);
        this.setState(() => ({goods: goods}));
    };

    costOnChange = (e) => {
        let goods = {...this.state.goods};
        let cost = e.target.value.match(/(\d{1,10}[.]?\d{0,2})/g);
        goods.cost = {
            cost: (cost === null ? '' : cost[0] ),
            currency: 'BYN'
        };
        this.setState(() => ({goods: goods}));
    };

    onDeletePhoto = () => {
        this.setState(() => ({deleteInProgress: true}));
        deleteGoodsPhoto(this.state.goods.id, this.state.photoDeleteNumber).then(
            response => {
                let goods = {...this.state.goods};
                goods.photos = JSON.parse(response);
                this.setState(() => ({goods: goods, deleteInProgress: false}));
                this.dialogOnClose();

            },
            error => {
                this.setState(() => ({deleteInProgress: false}));

                this.dialogOnClose();
            }
        );
    };

    editProduct = () => {

        let isValid = true;

        let goods = this.state.goods;

        goods.name = goods.name ? goods.name.trim() : '';
        goods.description = goods.description ? goods.description.trim() : '';

        if (!goods.name || goods.name.length < 4 || goods.name.length > 250) {
            this.setState(() => ({validate: true}));
            isValid = false;
        }

        if (!goods.description || goods.description.length < 11 || goods.description.length > 5000) {
            this.setState(() => ({validate: true}));
            isValid = false;
        }

        if (!goods.categoryAlias) {
            this.setState(() => ({validate: true}));
            isValid = false;
        }

        if (!goods.cost.cost || Number(goods.cost.cost) === 0 || isNaN(goods.cost.cost)) {
            this.setState(() => ({validate: true}));
            isValid = false;
        }

        if (goods.photos.length === 0) {
            this.setState(() => ({validate: true}));
            isValid = false;
        }
        if (goods.tags != null && goods.tags.length > 0){
            let tagStr = goods.tags.join(';');
            if (tagStr.length > 250) {
                this.setState(() => ({validate: true}));
                isValid = false;
            }
        }
        if (!isValid)
            return;

        (this.props.user.name && this.props.user.roles.filter((role) => (role.name === 'ROLE_ADMIN' || role.name === 'ROLE_MANAGER')).length > 0)
        ?
            editGoodsAdmin(goods).then(
                response => {
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Изменения сохранены. После модерации товар будет виден покупателям',
                            key: new Date().getTime(),
                            variant: 'success'
                        }
                    }));
                    this.props.history.goBack();

                },
                error => {
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Ошибка при сохранении данных',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
            )
        :
            editGoods(goods).then(
                response => {
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Изменения сохранены. После модерации товар будет виден покупателям',
                            key: new Date().getTime(),
                            variant: 'success'
                        }
                    }));
                    this.props.history.goBack();

                },
                error => {
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Ошибка при сохранении данных',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
            )

;
    };

    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };


    render() {
        const {classes, theme} = this.props;

        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };

        return (
            <div>
                {
                    this.state.close ? <Redirect to={'/'}/> : ''
                }
                <div className="row" style={{marginTop: '40px'}}>
                    <div className="col-11 col-sm-1 col-md-11 col-lg-11">
                        <div style={{marginLeft: '95px'}} className="d-flex justify-content-center">
                            <img src="/images/logo.png" width={87} height='48px'/>
                        </div>
                    </div>
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1" onClick={this.onClose}>
                        <div className="d-flex justify-content-end">
                            <img src="/images/close.svg"/>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop: '92px'}}>
                    <div className="col-lg-12 col-md-12">
                        <div className="d-flex justify-content-center">
                        </div>
                        <div>

                            <div className="row" style={{marginTop: '40px'}}>
                                <div className="col-lg-12 col-md-12">
                                    <div className="d-flex justify-content-center">
                                <span style={{
                                    color: '#283149',
                                    fontFamily: 'Tinos,sans-serif',
                                    fontSize: '30px',
                                    lineHeight: '40px'
                                }}>Редактировать товар </span>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop: '60px', marginLeft: '110px'}}>
                                <div className='inline store-setting-label'>
                                    <span className='store-setting-step'>1. Фотографии</span>
                                    <span className='error-message'
                                          ref="nameError"
                                          style={{display: (this.state.validate && (this.state.goods.photos.length < 1)) ? 'block' : 'none'}}
                                    >Должна быть хотя бы одна фотография</span>
                                </div>
                                <div style={{width: '306px'}}>
                                    <span className='description-create-goods-title'>Добавляйте фотографии своего товара, чтобы показать каждую деталь</span>
                                </div>
                            </div>
                            <div style={{marginTop: '29px', marginLeft: '110px'}}>
                                <div style={{
                                    width: '143px',
                                    display: 'inline-block',
                                    // marginLeft: '15px',
                                    marginTop: '27px'
                                }}>
                                    <LinearProgress style={{display: this.state.displayProgress1 ? 'block' : 'none'}}
                                                    ref='progress' variant="determinate" value={this.state.progress}/>
                                    <div ref='mainPhoto1' className="create-product-page-photo"
                                         style={{
                                             backgroundImage: 'url("' +
                                                 (this.state.goods.photos.length > 0
                                                 && this.state.goods.photos.filter((photo) => (photo.orderPlace === 1)).length > 0
                                                     ? this.state.goods.photos.filter((photo) => (photo.orderPlace === 1))[0].photoMin
                                                     : '')
                                                 + '")',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPositionX: 'center',
                                             backgroundPositionY: 'center',
                                             borderRadius: '5%',
                                             border: '1px solid #CFD6D8'
                                         }}
                                    >

                                        <button type='file'
                                                className='btn btn-secondary create-btn-min product-photo-actions'
                                                onClick={() => {
                                                    this.selectPhoto(1);
                                                }}>Загрузить
                                        </button>

                                    </div>

                                    <div className='d-flex flex-row-reverse justify-content-center'
                                         style={{marginTop: '13px'}}
                                    >
                                        <button
                                            disabled={!this.state.goods.photos.filter(photo => photo.orderPlace === 1).length > 0 || this.state.uploadingPhoto}
                                            className='delete-photo-button'
                                            onClick={() => {
                                                this.dialogOnOpen(1);
                                            }}>Удалить
                                        </button>
                                        <div onClick={() => {
                                                 if (this.state.goods.photos.filter(photo => photo.orderPlace === 1).length > 0 && !this.state.uploadingPhoto)
                                                    this.dialogOnOpen(2);
                                             }} style={{marginRight: '5px'}}
                                             className='remove-icon'></div>
                                    </div>

                                    <div style={{
                                        marginTop: '10px',
                                        marginLeft: '70px'
                                    }}>

                                        <input ref='uploadPhoto1' type='file' name='file' style={{display: 'none'}}
                                               onChange={() => {
                                                   this.uploadPhoto(1);
                                               }}/>
                                    </div>
                                </div>

                                <div style={{
                                    width: '143px',
                                    display: 'inline-block',
                                    marginLeft: '20px',
                                    marginTop: '27px'
                                }}>
                                    <LinearProgress style={{display: this.state.displayProgress2 ? 'block' : 'none'}}
                                                    ref='progress' variant="determinate" value={this.state.progress}/>
                                    <div ref='mainPhoto2' className="create-product-page-photo"
                                         style={{
                                             backgroundImage: 'url("' +
                                                 (this.state.goods.photos.length > 0
                                                 && this.state.goods.photos.filter((photo) => (photo.orderPlace === 2)).length > 0
                                                     ? this.state.goods.photos.filter((photo) => (photo.orderPlace === 2))[0].photoMin
                                                     : '')
                                                 + '")',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPositionX: 'center',
                                             backgroundPositionY: 'center',
                                             borderRadius: '5%',
                                             border: '1px solid #CFD6D8'
                                         }}
                                    >

                                        <button disabled={this.state.goods.photos.length < 1} type='file'
                                                className='btn btn-secondary create-btn-min product-photo-actions'
                                                onClick={() => {
                                                    this.selectPhoto(2);
                                                }}>{(this.state.goods.photos.length < 1) ? '' : 'Загрузить'}</button>

                                    </div>

                                    <div className='d-flex flex-row-reverse justify-content-center'
                                         style={{marginTop: '13px'}}
                                    >
                                        <button
                                            disabled={!this.state.goods.photos.filter(photo => photo.orderPlace === 2).length > 0 || this.state.uploadingPhoto}
                                            className='delete-photo-button'
                                            onClick={() => {
                                                this.dialogOnOpen(2);
                                            }}>Удалить
                                        </button>
                                        <div onClick={() => {
                                            if (this.state.goods.photos.filter(photo => photo.orderPlace === 2).length > 0 && !this.state.uploadingPhoto)
                                                this.dialogOnOpen(2);
                                        }} style={{marginRight: '5px'}}
                                             className='remove-icon'></div>
                                    </div>

                                    <div style={{
                                        marginTop: '10px',
                                        marginLeft: '70px'
                                    }}>

                                        <input ref='uploadPhoto2' type='file' name='file' style={{display: 'none'}}
                                               onChange={() => {
                                                   this.uploadPhoto(2);
                                               }}/>
                                    </div>
                                </div>

                                <div style={{
                                    width: '143px',
                                    display: 'inline-block',
                                    marginLeft: '20px',
                                    marginTop: '27px'
                                }}>
                                    <LinearProgress style={{display: this.state.displayProgress3 ? 'block' : 'none'}}
                                                    ref='progress' variant="determinate" value={this.state.progress}/>
                                    <div ref='mainPhoto3' className="create-product-page-photo"
                                         style={{
                                             backgroundImage: 'url("' +
                                                 (this.state.goods.photos.length > 0
                                                 && this.state.goods.photos.filter((photo) => (photo.orderPlace === 3)).length > 0
                                                     ? this.state.goods.photos.filter((photo) => (photo.orderPlace === 3))[0].photoMin
                                                     : '')
                                                 + '")',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPositionX: 'center',
                                             backgroundPositionY: 'center',
                                             borderRadius: '5%',
                                             border: '1px solid #CFD6D8'
                                         }}
                                    >

                                        <button disabled={this.state.goods.photos.length < 2} type='file'
                                                className='btn btn-secondary create-btn-min product-photo-actions'
                                                onClick={() => {
                                                    this.selectPhoto(3);
                                                }}>{(this.state.goods.photos.length < 2) ? '' : 'Загрузить'}</button>

                                    </div>

                                    <div className='d-flex flex-row-reverse justify-content-center'
                                         style={{marginTop: '13px'}}
                                    >
                                        <button
                                            disabled={!this.state.goods.photos.filter(photo => photo.orderPlace === 3).length > 0  || this.state.uploadingPhoto}
                                            className='delete-photo-button'
                                            onClick={() => {
                                                this.dialogOnOpen(3);
                                            }}>Удалить
                                        </button>
                                        <div onClick={() => {
                                            if (this.state.goods.photos.filter(photo => photo.orderPlace === 3).length > 0 && !this.state.uploadingPhoto)
                                                this.dialogOnOpen(3);
                                        }} style={{marginRight: '5px'}}
                                             className='remove-icon'></div>
                                    </div>

                                    <div style={{
                                        marginTop: '10px',
                                        marginLeft: '70px'
                                    }}>

                                        <input ref='uploadPhoto3' type='file' name='file' style={{display: 'none'}}
                                               onChange={() => {
                                                   this.uploadPhoto(3);
                                               }}/>
                                    </div>
                                </div>

                                <div style={{
                                    width: '143px',
                                    display: 'inline-block',
                                    marginLeft: '20px',
                                    marginTop: '27px'
                                }}>
                                    <LinearProgress style={{display: this.state.displayProgress4 ? 'block' : 'none'}}
                                                    ref='progress' variant="determinate" value={this.state.progress}/>
                                    <div ref='mainPhoto4' className="create-product-page-photo"
                                         style={{
                                             backgroundImage: 'url("' +
                                                 (this.state.goods.photos.length > 0
                                                 && this.state.goods.photos.filter((photo) => (photo.orderPlace === 4)).length > 0
                                                     ? this.state.goods.photos.filter((photo) => (photo.orderPlace === 4))[0].photoMin
                                                     : '')
                                                 + '")',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPositionX: 'center',
                                             backgroundPositionY: 'center',
                                             borderRadius: '5%',
                                             border: '1px solid #CFD6D8'
                                         }}
                                    >

                                        <button disabled={this.state.goods.photos.length < 3} type='file'
                                                className='btn btn-secondary create-btn-min product-photo-actions'
                                                onClick={() => {
                                                    this.selectPhoto(4);
                                                }}>{(this.state.goods.photos.length < 3) ? '' : 'Загрузить'}</button>

                                    </div>

                                    <div className='d-flex flex-row-reverse justify-content-center'
                                         style={{marginTop: '13px'}}
                                    >
                                        <button
                                            disabled={!this.state.goods.photos.filter(photo => photo.orderPlace === 4).length > 0  || this.state.uploadingPhoto}
                                            className='delete-photo-button'
                                            onClick={() => {
                                                this.dialogOnOpen(4);
                                            }}>Удалить
                                        </button>
                                        <div onClick={() => {
                                            if (this.state.goods.photos.filter(photo => photo.orderPlace === 4).length > 0 && !this.state.uploadingPhoto)
                                                this.dialogOnOpen(4);
                                        }} style={{marginRight: '5px'}}
                                             className='remove-icon'></div>
                                    </div>

                                    <div style={{
                                        marginTop: '10px',
                                        marginLeft: '70px'
                                    }}>

                                        <input ref='uploadPhoto4' type='file' name='file' style={{display: 'none'}}
                                               onChange={() => {
                                                   this.uploadPhoto(4);
                                               }}/>
                                    </div>
                                </div>

                                <div style={{
                                    width: '143px',
                                    display: 'inline-block',
                                    marginLeft: '20px',
                                    marginTop: '27px'
                                }}>
                                    <LinearProgress style={{display: this.state.displayProgress5 ? 'block' : 'none'}}
                                                    ref='progress' variant="determinate" value={this.state.progress}/>
                                    <div ref='mainPhoto5' className="create-product-page-photo"
                                         style={{
                                             backgroundImage: 'url("' +
                                                 (this.state.goods.photos.length > 0
                                                 && this.state.goods.photos.filter((photo) => (photo.orderPlace === 5)).length > 0
                                                     ? this.state.goods.photos.filter((photo) => (photo.orderPlace === 5))[0].photoMin
                                                     : '')
                                                 + '")',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPositionX: 'center',
                                             backgroundPositionY: 'center',
                                             borderRadius: '5%',
                                             border: '1px solid #CFD6D8'
                                         }}
                                    >

                                        <button disabled={this.state.goods.photos.length < 4} type='file'
                                                className='btn btn-secondary create-btn-min product-photo-actions'
                                                onClick={() => {
                                                    this.selectPhoto(5);
                                                }}>{(this.state.goods.photos.length < 4) ? '' : 'Загрузить'}</button>

                                    </div>

                                    <div className='d-flex flex-row-reverse justify-content-center'
                                         style={{marginTop: '13px'}}
                                    >
                                        <button
                                            disabled={!this.state.goods.photos.filter(photo => photo.orderPlace === 5).length > 0  || this.state.uploadingPhoto}
                                            className='delete-photo-button'
                                            onClick={() => {
                                                this.dialogOnOpen(5);
                                            }}>Удалить
                                        </button>
                                        <div onClick={() => {
                                            if (this.state.goods.photos.filter(photo => photo.orderPlace === 5).length > 0 && !this.state.uploadingPhoto)
                                                this.dialogOnOpen(5);
                                        }} style={{marginRight: '5px'}}
                                             className='remove-icon'></div>
                                    </div>

                                    <div style={{
                                        marginTop: '10px',
                                        marginLeft: '70px'
                                    }}>

                                        <input ref='uploadPhoto5' type='file' name='file' style={{display: 'none'}}
                                               onChange={() => {
                                                   this.uploadPhoto(5);
                                               }}/>
                                    </div>
                                </div>

                                <div style={{
                                    width: '143px',
                                    display: 'inline-block',
                                    marginLeft: '20px',
                                    marginTop: '27px'
                                }}>
                                    <LinearProgress style={{display: this.state.displayProgress6 ? 'block' : 'none'}}
                                                    ref='progress' variant="determinate" value={this.state.progress}/>
                                    <div ref='mainPhoto6' className="create-product-page-photo"
                                         style={{
                                             backgroundImage: 'url("' +
                                                 (this.state.goods.photos.length > 0
                                                 && this.state.goods.photos.filter((photo) => (photo.orderPlace === 6)).length > 0
                                                     ? this.state.goods.photos.filter((photo) => (photo.orderPlace === 6))[0].photoMin
                                                     : '')
                                                 + '")',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPositionX: 'center',
                                             backgroundPositionY: 'center',
                                             borderRadius: '5%',
                                             border: '1px solid #CFD6D8'
                                         }}
                                    >

                                        <button disabled={this.state.goods.photos.length < 5} type='file'
                                                className='btn btn-secondary create-btn-min product-photo-actions'
                                                onClick={() => {
                                                    this.selectPhoto(6);
                                                }}>{(this.state.goods.photos.length < 5) ? '' : 'Загрузить'}</button>

                                    </div>

                                    <div className='d-flex flex-row-reverse justify-content-center'
                                         style={{marginTop: '13px'}}
                                    >
                                        <button
                                            disabled={!this.state.goods.photos.filter(photo => photo.orderPlace === 6).length > 0 || this.state.uploadingPhoto}
                                            className='delete-photo-button'
                                            onClick={() => {
                                                this.dialogOnOpen(6);
                                            }}>Удалить
                                        </button>
                                        <div onClick={() => {
                                            if (this.state.goods.photos.filter(photo => photo.orderPlace === 6).length > 0 && !this.state.uploadingPhoto)
                                                this.dialogOnOpen(6);
                                        }} style={{marginRight: '5px'}}
                                             className='remove-icon'></div>
                                    </div>

                                    <div style={{
                                        marginTop: '10px',
                                        marginLeft: '70px'
                                    }}>

                                        <input ref='uploadPhoto6' type='file' name='file' style={{display: 'none'}}
                                               onClick={(event) => {
                                                   event.target.value = null;
                                               }}
                                               onChange={() => {
                                                   this.uploadPhoto(6);
                                               }}/>
                                    </div>
                                </div>
                            </div>

                            <div style={{marginTop: '60px', marginLeft: '110px'}}>

                                <div className='inline store-setting-label'>
                                    <span className='store-setting-step'>2. Обзор товара</span>
                                </div>
                                <div style={{width: '306px'}}>
                                    <span className='description-create-goods-title'>Дайте максимально полное описание своему продукту</span>
                                </div>
                                <div style={{marginTop: '37px'}}>
                                    <div className='inline store-setting-label'>
                                        <span className='goods-input-label'>Название*</span>
                                    </div>
                                    <div className='inline'>
                                        <input type='text'
                                               className='input-text-element-716'
                                               value={this.state.goods.name != null ? this.state.goods.name : ''}
                                               onChange={this.nameOnChange}
                                               placeholder='Введите название'
                                               style={(this.state.validate && (!this.state.goods.name || this.state.goods.name.length < 4 || this.state.goods.name.length > 250))
                                                   ? this.state.errorStyle
                                                   : {
                                                       paddingLeft: '20px',
                                                       marginTop: '10px'
                                                   }}/>
                                        <div className="d-flex justify-content-center">
                                            <span className='error-message'
                                                  ref="nameError"
                                                  style={{display: (this.state.validate && (!this.state.goods.name || this.state.goods.name.length < 4 || this.state.goods.name.length > 250)) ? 'block' : 'none'}}
                                            >{!this.state.goods.name ? 'Укажите название' : this.state.goods.name.length < 4 ? 'Название слишком короткое' : 'Название слишком длинное'}</span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{marginTop: '37px'}}>
                                    <div className='inline store-setting-label'>
                                        <span className='goods-input-label'>Категория*</span>
                                        <div style={{width: '188px'}}>
                                            <span className='description-create-goods-title'>Введите несколько слов характеризующих товар, чтобы подобрать категорию</span>
                                        </div>
                                    </div>

                                    <div className={classes.root}>
                                        <NoSsr>
                                            <Select
                                                classes={classes}
                                                styles={selectStyles}
                                                options={this.state.suggestion}
                                                components={components}
                                                value={this.state.selectedCategory}
                                                onChange={(e) => {
                                                    this.categoryChange(e);
                                                }}
                                                placeholder="Выберите категорию"
                                            />

                                        </NoSsr>
                                        <div className="d-flex justify-content-center">
                                            <span className='error-message' ref="nameError"
                                                  style={{display: (this.state.validate && !this.state.goods.categoryAlias) ? 'block' : 'none'}}>Укажите категорию</span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{marginTop: '37px'}}>
                                    <div className='store-setting-label' style={{display: 'inline-block'}}>
                                        <span className='goods-input-label'>Описание*</span>
                                        <div style={{width: '188px'}}>
                                            <span className='description-create-goods-title'>Опишите ключевые свойства вашего товара</span>
                                        </div>
                                        <div style={{width: '188px'}}>
                                            <span className='description-create-goods-title'>Поделитесь историей его создания</span>
                                        </div>
                                    </div>
                                    <div className={classes.textArea}>
                                            <textarea
                                                className='textarea-element-716'
                                                value={this.state.goods.description != null ? this.state.goods.description : ''}
                                                onChange={this.descriptionOnChange}
                                                placeholder='Опишите товар'
                                                rows={'10'}
                                                style={(this.state.validate && (!this.state.goods.description || this.state.goods.description.length < 11 || this.state.goods.description.length > 5000))
                                                    ? this.state.errorStyle
                                                    : {
                                                        paddingLeft: '20px',
                                                        paddingTop: '20px',
                                                        marginTop: '10px'
                                                    }}/>
                                        <div className="d-flex justify-content-center">
                                            <span className='error-message'
                                                  ref="nameError"
                                                  style={{display: (this.state.validate && (!this.state.goods.description || this.state.goods.description.length < 11 || this.state.goods.description.length > 5000)) ? 'block' : 'none'}}
                                            >{!this.state.goods.description
                                                ? 'Опишите товар'
                                                : this.state.goods.description.length < 11
                                                    ? 'Описание слишком короткое'
                                                    : 'Описание слишком длинное'}</span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{marginTop: '37px'}}>
                                    <div className='store-setting-label' style={{display: 'inline-block'}}>
                                        <span className='goods-input-label'>Цвет</span>
                                    </div>
                                    <div className='inline'>
                                        <div>
                                            <span className='color-help'>Основной</span>
                                        </div>
                                        <div style={{width: '330px'}}>
                                            {
                                                this.props.dict.color.map((color) => {
                                                    return (
                                                        <div key={color.color}
                                                             className={this.state.goods.color === color.color
                                                                 ? 'color-circle-set-active'
                                                                 : 'color-circle-set'}
                                                             style={{
                                                                 border: '1px solid black',
                                                                 backgroundColor: color.color,
                                                                 boxShadow: '1px 2px 4px 0 rgba(' + this.hexToRGB(color.color)[0] + ',' + this.hexToRGB(color.color)[1] + ',' + this.hexToRGB(color.color)[2] + ',0.19)',
                                                                 display: 'inline-block'
                                                             }} onClick={() => {
                                                            this.colorOnChange(color.color);
                                                        }}>

                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='inline' style={{marginLeft: '72px'}}>
                                        <div>
                                            <span className='color-help'>Дополнительный</span>
                                        </div>
                                        <div style={{width: '330px'}}>
                                            {
                                                this.props.dict.color.map((color) => {
                                                    return (
                                                        <div key={color.color}
                                                             className={this.state.goods.color2 === color.color
                                                                 ? 'color-circle-set-active'
                                                                 : 'color-circle-set'}
                                                             style={{
                                                                 border: '1px solid black',
                                                                 backgroundColor: color.color,
                                                                 boxShadow: '1px 2px 4px 0 rgba(' + this.hexToRGB(color.color)[0] + ',' + this.hexToRGB(color.color)[1] + ',' + this.hexToRGB(color.color)[2] + ',0.19)',
                                                                 display: 'inline-block'
                                                             }} onClick={() => {
                                                            this.color2OnChange(color.color);
                                                        }}>

                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div style={{marginTop: '37px'}}>
                                    <div className='store-setting-label' style={{display: 'inline-block'}}>
                                        <span className='goods-input-label'>Материалы</span>
                                    </div>
                                    <div className='inline'>
                                        <input type='text'
                                               className='input-text-element-716'
                                               value={this.state.goods.materials != null ? this.state.goods.materials : ''}
                                               onChange={this.materialsOnChange}
                                               placeholder='Ингредиенты, компоненты'
                                               style={{
                                                   paddingLeft: '20px',
                                                   marginTop: '10px'
                                               }}/>
                                    </div>
                                </div>

                                <div style={{marginTop: '37px'}}>
                                    <div className='store-setting-label' style={{display: 'inline-block'}}>
                                        <span className='goods-input-label'>Наличие</span>
                                    </div>
                                    <div className='inline'>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        icon={<div style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            backgroundImage: 'url(/images/default.png)',
                                                            backgroundSize: 'cover',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPositionX: 'center',
                                                            backgroundPositionY: 'center',
                                                        }}>

                                                        </div>}
                                                        checkedIcon={<div style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            backgroundImage: 'url(/images/filled.png)',
                                                            backgroundSize: 'cover',
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPositionX: 'center',
                                                            backgroundPositionY: 'center',
                                                        }}>

                                                        </div>}
                                                        value={true}
                                                        checked={this.state.goods.isAvailable}
                                                        onChange={(e, ch) => {
                                                            let goods = {...this.state.goods};
                                                            goods.isAvailable = ch;
                                                            this.setState({goods: goods});
                                                        }}
                                                    />
                                                }
                                                label='Есть в наличии'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div style={{marginTop: '37px'}}>
                                    <div className='store-setting-label' style={{display: 'inline-block'}}>
                                        <span className='goods-input-label'>Тэги</span>
                                        <div style={{width: '188px'}}>
                                            <span className='description-create-goods-title'>Введите слова, которые помогут найти ваш товар</span>
                                        </div>
                                    </div>
                                    <div className='inline'>

                                        <ChipInput
                                            // classes={classes.placeholder}
                                            value={(this.state.goods.tags != null ? this.state.goods.tags : [])}
                                            // onChange={(chips) => this.tagsOnChange(chips)}
                                            onAdd={this.tagsOnAdd}
                                            onDelete={this.tagsOnDelete}
                                            style={{width: '716px'}}
                                            onBlur={(chips) => {
                                                this.tagsOnBlur(chips.target.value);
                                            }}

                                            // placeholder={'Техника, форма, стиль, цвет'}

                                        />
                                        <div className="d-flex justify-content-center">
                                            <span className='error-message'
                                                  ref="nameError"
                                                  style={{display:
                                                          (this.state.validate
                                                              && this.state.goods.tags != null
                                                              && this.state.goods.tags.length > 0
                                                              && ( this.state.goods.tags.join(';').length > 250 )) ? 'block' : 'none'}}
                                            >Слишком много тэгов</span>
                                        </div>

                                    </div>
                                </div>


                            </div>


                            <div style={{marginTop: '60px', marginLeft: '110px'}}>
                                <div className='inline store-setting-label'>
                                    <span className='store-setting-step'>3. Стоимость</span>
                                </div>
                                <div style={{marginTop: '37px'}}>
                                    <div className='store-setting-label' style={{display: 'inline-block'}}>
                                        <span className='goods-input-label'>Цена*</span>
                                    </div>
                                    <div className='inline'>
                                        <input type='text'
                                               className='input-text-element-225'
                                               value={(this.state.goods.cost != null && this.state.goods.cost.cost != null) ? this.state.goods.cost.cost : ''}
                                               onChange={this.costOnChange}
                                               placeholder='Цена BYN'
                                               style={(this.state.validate && (!this.state.goods.cost.cost
                                                   || Number(this.state.goods.cost.cost) === 0
                                                   || isNaN(this.state.goods.cost.cost)))
                                                   ? this.state.errorStyle
                                                   : {
                                                       paddingLeft: '20px',
                                                       marginTop: '10px'
                                                   }}/>
                                    </div>
                                </div>
                            </div>

                            <div style={{marginTop: '60px', marginBottom: '60px'}}
                                 className='d-flex justify-content-center'>
                                <button className='create-btn-max'
                                        onClick={this.editProduct}
                                        style={{opacity: '1'}}
                                >Сохранить
                                </button>
                            </div>

                        </div>


                    </div>
                </div>
                <div>
                    <Dialog
                        open={this.state.deleteOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.dialogOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Вы точно хотите удалить эту фотографию?"}
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <p>Это действие нельзя будет отменить.</p>
                            <CircularProgress
                                style={{display: this.state.deleteInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.dialogOnClose} color="secondary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.deleteInProgress} onClick={this.onDeletePhoto} color="primary">
                                Удалить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

EditGoodsPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(EditGoodsPage));
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../styles/custom.css';

const SearchBlock = (props) => {
    const [searchString, setSearchString] = useState('');
    return (

        <div style={{minWidth: '1230px'}}>
            <div id="search-block">
                <div className="row" style={{marginTop: '49px'}}>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                        <div className="search-input input-group ">
                            <div className="input-group-prepend" onClick={() => {
                                if (searchString.trim().length > 0){
                                    document.getElementById('searchLink').click();
                                }
                            }}>
                          <span className="input-group-text" style={{background: '#FFF', border: 0}}
                                id="inputGroup-sizing-lg"><img src="/images/search.png" /></span>
                            </div>
                            <input id="search-main" type="text" className="form-control" placeholder="Поиск"
                                   onChange={(event) => {
                                       setSearchString(event.target.value);
                                   }}
                                   value={searchString}
                                   onKeyPressCapture={(event) => {
                                       if (event.charCode === 13) {
                                           if (searchString.trim().length > 0){
                                               document.getElementById('searchLink').click();
                                           }
                                       }
                                   }}/>
                            <Link id='searchLink' to={'/search?q='+searchString} styly={{display: 'none'}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SearchBlock;

import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {deleteBasket, getBookmark, orderGoods, removeBookmark} from "../api/GoodsAPI";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {loadBasket, saveBasket} from "../actions/basket";
import Select from "react-select";
import {emphasize} from "@material-ui/core/styles/colorManipulator";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import classNames from "classnames";
import CancelIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {sendNotification} from "../actions/notifications";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {RadioCrafty, RadioCraftyChecked} from "./Radio";
import * as ReactGA from "react-ga";

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '450px',
        height: 50,
        display: 'inline-grid'
    },
    rootRadio: {
        color: '#000000',
        marginBottom: 0
    },
    select: {
        color: 'red',
        width: '450px'
    },
    titleRoot: {
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '24px',
        paddingBottom: '24px',
        // fontFamily: 'Tinos',
        // fontSize: '25px',
        // fontWeight: 'normal',
        // fontStyle: 'normal',
        // fontStretch: 'normal',
        // lineHeight: '1.4',
        // letterSpacing: 'normal',
        // color: '#283149',
    },
    contentRoot: {
        paddingLeft: '40px',
        paddingRight: '40px',
        // paddingTop: '24px',
        paddingBottom: '24px',
        // fontFamily: 'Tinos',
        // fontSize: '25px',
        // fontWeight: 'normal',
        // fontStyle: 'normal',
        // fontStretch: 'normal',
        // lineHeight: '1.4',
        // letterSpacing: 'normal',
        // color: '#283149',
    },
    paperWidthXl: {
        width: '795px'
    },
    textArea: {
        flexGrow: 1,
        width: 450,
        height: 110,
        display: 'inline-block'
    },
    input: {
        display: 'flex',
        padding: 0,
        paddingLeft: '20px'
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
        paddingLeft: '20px'
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: '14px',
        opacity: 0.5,
        color: '#283149',
        fontFamily: "Open Sans",
        paddingLeft: '20px'
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    textSecondary: {
        opacity: '0.5',
        color: '#283149',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '19px'
    },
    textPrimary: {
        color: '#4A90E2', fontFamily: 'Open Sans', fontSize: '14px', lineHeight: '19px'
    }
});

const NoOptionsMessage = (props) => {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
};

const inputComponent = ({inputRef, ...props}) => {
    return <div ref={inputRef} {...props} />;
};

const Control = (props) => {
    return (
        <TextField
            // fullWidth

            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
};

const Option = (props) => {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
};

const Placeholder = (props) => {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
};


const SingleValue = (props) => {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
};

const ValueContainer = (props) => {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
};

const Menu = (props) => {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
};

const MultiValue = (props) => {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
};

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

class BasketPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            basket: {},
            deleteOpen: false,
            goodsDeleteId: 0,
            deleteInProgress: false,
            sendOpen: false,
            goodsSendId: 0,
            sendingInProgress: false,
            commentSendText: '',
            phone: '',
            address: '',
            delivery: {},
            suggestion: [],
            validate: false,
            paymentType: ''
        };

        this.dialogName = React.createRef();
    }


    componentWillMount() {
        this.props.dispatch(loadBasket());
    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        this.setState({phone: this.props.user.phone});
        ReactGA.event({
            category: 'Basket',
            action: 'OpenBasket',
            value: 0
        });
    }

    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };

    dialogOnOpen = (goodsId) => {
        this.setState(() => ({deleteOpen: true, goodsDeleteId: goodsId}));


    };
    dialogOnClose = () => {
        this.setState(() => ({goodsDeleteId: 0, deleteOpen: false}));
    };

    sendOnOpen = (goodsId) => {

        let store = this.props.basket.items.filter(b => b.item.id === goodsId)[0].store;
        let deliveryArr = [];
        store.deliveries.forEach((delivery) => {
            let line = delivery.deliveryType.name + ': ' + delivery.city
                + '.' + ' Срок: ' + delivery.period + '. Стоимость: ' + (delivery.cost ? (delivery.cost + ' ' + delivery.currency) : 'Бесплатно');
            deliveryArr.push({label: line, value: delivery.deliveryType.alias, cost: delivery.cost});
        });

        this.setState({suggestion: [...deliveryArr]});


        this.setState(() => ({sendOpen: true, goodsSendId: goodsId}), () => {
            let paymentType = this.props.basket.items.filter(b => b.item.id === this.state.goodsSendId)[0].store.payment.filter(p => p.isEnable)[0].name;
            this.setState({paymentType: paymentType});
        });



    };
    sendOnClose = () => {
        this.setState(() => ({goodsSendId: 0, sendOpen: false, commentSendText: '', delivery: {}, paymentType: ''}));
    };

    clearFields = () => {
        this.setState({sendingInProgress: false, sendOpen: false, commentSendText: '', delivery: {}});
    };

    onSendOrder = () => {
        let order = this.props.basket.items.filter(value => value.item.id === this.state.goodsSendId)[0];
        let hasError = false;
        if (!this.state.phone || this.state.phone.trim().length === 0) {
            this.setState({validate: true, phone: ''});
            hasError = true;
        }
        if (!this.state.delivery || Object.keys(this.state.delivery).length === 0) {
            this.setState({validate: true});
            hasError = true;
        } else {
            if (this.state.delivery.value !== 'delivery_self') {
                if (!this.state.address || this.state.address.trim().length === 0) {
                    this.setState({validate: true});
                    hasError = true;
                }
            } else {
                this.setState({address: ''});
            }
        }

        if (hasError) {
            return;
        }

        orderGoods({
            goodsId: order.item.id,
            comment: order.comment,
            amount: order.amount,
            phone: this.state.phone,
            address: this.state.address,
            delivery: this.state.delivery.label,
            payment: this.state.paymentType
        }).then(
            response => {
                this.props.dispatch(saveBasket(JSON.parse(response)));
                this.clearFields();
                ReactGA.event({
                    category: 'Basket',
                    action: 'OrderGoods',
                    value: 10
                });
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Заказ отправлен',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.clearFields();
                console.log(error.message);
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Произошла ошибка, попробуйте позже',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    onDeleteGoods = () => {
        this.setState(() => ({deleteInProgress: true}));
        deleteBasket(this.state.goodsDeleteId).then(
            response => {
                this.setState(() => ({deleteInProgress: false, deleteOpen: false}));
                this.props.dispatch(saveBasket(JSON.parse(response)));
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Товар удален из корзины',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState(() => ({deleteInProgress: false, deleteOpen: false}));
                console.log(error.message);
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при удалении',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    onChangeAmount = (goodsId, ev) => {
        let value = ev.target.value.match(/(\d{1,10})/g);
        if (value === null) {
            value = '';
        } else {
            value = value[0];
        }
        let basket = {...this.props.basket};
        let cur = basket.items.filter(i => {
            return i.item.id === goodsId;
        });
        cur[0].amount = value;
        this.props.dispatch(saveBasket(basket));
    };

    onChangeComment = (ev) => {
        let goodsId = this.state.goodsSendId;
        let value = ev.target.value;
        this.setState({commentSendText: value});
        let basket = {...this.props.basket};
        let cur = basket.items.filter(i => {
            return i.item.id === goodsId;
        });
        cur[0].comment = value;
        this.props.dispatch(saveBasket(basket));
    };

    onMinusAmount = (id) => {
        let basket = {...this.props.basket};
        let cur = basket.items.filter(i => {
            return i.item.id === id;
        });
        if (cur[0].amount === 1) {
            return;
        }
        cur[0].amount -= 1;
        this.props.dispatch(saveBasket(basket));
    };
    onPlusAmount = (id) => {
        let basket = {...this.props.basket};
        let cur = basket.items.filter(i => {
            return i.item.id === id;
        });
        cur[0].amount += 1;
        this.props.dispatch(saveBasket(basket));
    };


    onBlurAmount = (goodsId, ev) => {
        let value = ev.target.value.match(/(\d{1,10})/g);
        if (value === null) {
            value = ''
        } else {
            value = value[0];
        }
        if (value === '') {
            value = 1;
        }
        let basket = {...this.props.basket};
        let cur = basket.items.filter(i => {
            return i.item.id === goodsId;
        });
        cur[0].amount = value;
        this.props.dispatch(saveBasket(basket));
    };

    phoneOnChange = (ev) => {
        let phone = ev.target.value;


        this.setState({phone});
    };

    addressOnChange = (ev) => {
        let address = ev.target.value;

        this.setState({address});
    };

    deliveryChange = (ev) => {
        let delivery = ev;

        this.setState({delivery});
    };

    paymentOnChange = (value) => {
        this.setState({paymentType: value.target.value});
    };

    render() {
        const {classes, theme} = this.props;

        const selectStyles = {
            input: base => ({
                ...base,
                width: '250px',
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
            control: (provided) => {
                return {
                    ...provided,
                    width: 470,
                    borderRadius: 0,
                    height: 50,
                    ':active': {
                        ...provided[':active'],
                        outlineColor: '#000000',
                        borderColor: '#000000',
                        outlineFocused: '#000000'
                    },
                    ':focus': {
                        ...provided[':focus'],
                        outlineColor: '#000000',
                        borderColor: '#000000'
                    },
                    ':target': {
                        ...provided[':target'],
                        outlineColor: '#000000',
                        borderColor: '#000000'
                    },
                    fontFamily: 'Open Sans',
                    fontSize: 14,
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    fontStretch: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: '#283149'
                };
            },
            indicatorSeparator: () => {

            },
            option: (provided) => {
                return {
                    ...provided,
                    borderRadius: 0,
                    backgroundColor: '#FFFFFF',
                    color: '#000000',
                    cursor: 'default',
                    ':active': {
                        ...provided[':active'],
                        backgroundColor: 'rgba(207, 214, 216, 0.2)',
                    },
                    ':hover': {
                        ...provided[':hover'],
                        backgroundColor: 'rgba(207, 214, 216, 0.2)',
                    },
                    width: 470
                };
            },
            container: (provided) => {
                return {
                    ...provided,
                    width: 470,
                    borderRadius: 0
                };
            },
            valueContainer: (provided) => {
                return {
                    ...provided,
                    borderRadius: 0
                };
            },
            menu: (provided) => {
                return {
                    ...provided,
                    borderRadius: 0
                };
            },
        };

        return (
            <div>
                <div id="path-category">
                    <span>Главная  / Корзина  </span>
                </div>
                <div style={{width: '960px', marginLeft: '160px'}}>
                    <div className='section-title-1 inline-flex' style={{marginTop: '16px', marginBottom: '22px'}}>
                        <span>Корзина</span>
                    </div>
                    <div className='inline-flex' style={{marginTop: '31px', marginBottom: '32px', float: 'right'}}>
                        <Link to={'/cabinet#orders'}>Мои покупки</Link>
                    </div>
                </div>

                <div className='basket-table-header'
                     style={{marginLeft: '160px', borderBottom: 'solid 1px #cfd6d8', width: '960px'}}>
                    <div className='inline-flex' style={{width: '578px', marginBottom: '5px'}}>
                        <span>Товар</span>
                    </div>
                    <div className='inline-flex' style={{width: '97px'}}>
                        <span>Цена</span>
                    </div>
                    <div className='inline-flex' style={{width: '247px'}}>
                        <span>Количество</span>
                    </div>
                    <div className='inline-flex'>
                        <span>Сумма</span>
                    </div>
                </div>

                <div style={{marginLeft: '161px', marginTop: '33px'}}>
                    <div style={{marginTop: '20px', minHeight: '900px', width: '960px'}}>
                        {/*<div style={{*/}
                        {/*    height: '1px',*/}
                        {/*    width: '1010px',*/}
                        {/*    borderRadius: '1px',*/}
                        {/*    backgroundColor: '#CFD6D8',*/}
                        {/*    marginTop: '52px',*/}
                        {/*    display: 'block'*/}
                        {/*}}>*/}

                        {/*</div>*/}
                        {
                            this.props.basket.count > 0
                                ? this.props.basket.items.map((item) => {
                                    return (
                                        <div style={{marginTop: '20px', position: 'relative'}} key={item.item.id}>
                                            <div style={{display: 'inline-flex'}}>
                                                <div className="goods-image-small-basket"
                                                     style={{
                                                         backgroundImage: 'url("' + (item.item.photos.filter((ph) => {
                                                             return ph.orderPlace === 1
                                                         }).length > 0 ? item.item.photos.filter((ph) => {
                                                             return ph.orderPlace === 1
                                                         })[0].photoMin : '') + '")',
                                                         backgroundSize: 'cover',
                                                         backgroundRepeat: 'no-repeat',
                                                         backgroundPositionX: 'center',
                                                         backgroundPositionY: 'center',
                                                         position: 'relative'
                                                     }}
                                                >
                                                    {!item.item.isAvailable ? '' :
                                                        <div style={
                                                            {
                                                                width: '83px',
                                                                height: '25px',
                                                                backgroundColor: '#79CA1F',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                bottom: '0',
                                                                borderRadius: '0 5px 0 0'
                                                            }
                                                        }>
                                                        <span style={
                                                            {
                                                                color: '#FFFFFF',
                                                                fontFamily: 'Open Sans',
                                                                fontSize: '12px',
                                                                lineHeight: '20px',
                                                                marginLeft: '10px'
                                                            }
                                                        }>В наличии</span>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                            <div style={{marginLeft: '144px', position: 'absolute', top: '0px'}}>
                                                <div className='row' style={{minWidth: '845px'}}>
                                                    <div className='col-md-5'>
                                                        <div className="main-card-goods-desc-basket">
                                                            <div className="main-card-goods-name-basket"
                                                                 style={{paddingTop: '0px'}}>
                                                                <Link className="main-card-goods-name-text-basket"
                                                                      to={'/product/' + item.item.id}>{item.item.name}</Link>
                                                            </div>
                                                            <div className="main-card-goods-store">
                                                                <Link to={'/store/' + item.item.store.id}
                                                                      className="main-card-goods-store-link-basket">{item.item.store.name}</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2'
                                                         style={{textAlign: 'right',}}
                                                    >
                                                        <span
                                                            className='main-card-goods-cost'>{item.item.cost.cost + ' '}BYN</span>
                                                    </div>
                                                    <div className='col-md-2'
                                                         style={{textAlign: 'right',}}>
                                                        {/*<div style={{display: 'inline-flex'}}>*/}
                                                        <button style={{marginRight: '10px'}}
                                                                className='amount-btn basket-count'
                                                                onClick={() => {
                                                                    this.onMinusAmount(item.item.id);
                                                                }}><img style={{marginBottom: '5px'}}
                                                                        src="/images/minus.svg"
                                                                        className="minus"/>
                                                        </button>
                                                        {/*</div>*/}
                                                        {/*<div style={{paddingLeft: '10px', paddingRight: '10px', display: 'inline-flex'}}>*/}
                                                        <span className='basket-count'
                                                              style={{
                                                                  marginTop: '10px'
                                                              }}>{item.amount}</span>
                                                        {/*</div>*/}
                                                        {/*<div style={{display: 'inline-flex'}}>*/}
                                                        <button style={{marginLeft: '10px'}}
                                                                className='amount-btn basket-count'
                                                                onClick={() => {
                                                                    this.onPlusAmount(item.item.id);
                                                                }}><img style={{marginBottom: '5px'}}
                                                                        src="/images/plus_2.svg"
                                                                        className="plus"/>
                                                        </button>
                                                        {/*</div>*/}
                                                    </div>

                                                    <div className='col-md-3' style={{textAlign: 'right',}}>
                                                        <div
                                                        >
                                                            <span
                                                                className='main-card-goods-cost'>{(item.item.cost.cost * item.amount) + ' BYN'}</span>
                                                        </div>
                                                        <button className='basket-order-button'
                                                                style={{marginTop: '16px'}}
                                                                onClick={() => {
                                                                    this.sendOnOpen(item.item.id);
                                                                }}>Заказать
                                                        </button>
                                                        <button className='basket-remove-button'
                                                                style={{marginTop: '18px', paddingRight: '0px'}}
                                                                onClick={() => {
                                                                    this.dialogOnOpen(item.item.id);
                                                                }}>Убрать из корзины
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{
                                                height: '1px',
                                                width: '960px',
                                                borderRadius: '1px',
                                                backgroundColor: '#CFD6D8',
                                                marginTop: '32px',
                                                display: 'block'
                                            }}>

                                            </div>
                                        </div>
                                    );
                                })
                                :
                                <div className='d-flex justify-content-center'>
                                    <span>Корзина пуста</span>
                                </div>
                        }
                    </div>
                </div>

                <div>
                    <Dialog
                        open={this.state.deleteOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.dialogOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Вы точно хотите удалить этот товар из корзины?"}
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <CircularProgress
                                style={{display: this.state.deleteInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.dialogOnClose} color="secondary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.deleteInProgress} onClick={this.onDeleteGoods} color="primary">
                                Удалить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

                <div>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xl'}
                        classes={{paperWidthXl: classes.paperWidthXl}}
                        open={this.state.sendOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle
                            id="alert-dialog-slide-title"
                            classes={{root: classes.titleRoot}}
                        >
                            <div className='row'>
                                <div className='col-md-10'>
                                    <span className='alert-dialog-slide-title'>Оформление заказа</span>
                                </div>
                                <div className='col-md-2'>
                                    <img onClick={this.sendOnClose} src="/images/close.svg"
                                         className="close"/>
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent classes={{root: classes.contentRoot}}>

                            <div style={{display: 'block',}}>
                                <div>
                                    {
                                        this.props.basket.count > 0
                                            ? this.props.basket.items.filter(item => item.item.id === this.state.goodsSendId).map(item => {
                                                return (
                                                    <div key={item.item.id} className='row'>
                                                        <div className='col' style={{maxWidth: '78px'}}>
                                                            <div className="goods-image-dialog-basket"
                                                                 style={{
                                                                     backgroundImage: 'url("' + (item.item.photos.filter((ph) => {
                                                                         return ph.orderPlace === 1
                                                                     }).length > 0 ? item.item.photos.filter((ph) => {
                                                                         return ph.orderPlace === 1
                                                                     })[0].photoMin : '') + '")',
                                                                     backgroundSize: 'cover',
                                                                     backgroundRepeat: 'no-repeat',
                                                                     backgroundPositionX: 'center',
                                                                     backgroundPositionY: 'center',
                                                                     position: 'relative'
                                                                 }}
                                                            >
                                                            </div>
                                                        </div>
                                                        <div className='col'
                                                            // style={{width: (!!this.dialogName.current) ? this.dialogName.current.offsetWidth : '100px'}}
                                                             style={{
                                                                 maxWidth: '150px',
                                                                 paddingRight: '0px',
                                                                 paddingLeft: '0px',
                                                                 marginLeft: '16px'
                                                             }}
                                                        >
                                                            <div className="main-card-goods-name-basket"
                                                                 style={{paddingTop: '0px'}}>
                                                                <Link ref={this.dialogName}
                                                                      className="main-card-goods-name-text-basket-dialog"
                                                                      to={'/product/' + item.item.id}>{item.item.name}</Link>
                                                            </div>
                                                            <div className="main-card-goods-store">
                                                                <Link to={'/store/' + item.item.store.id}
                                                                      className="main-card-goods-store-link-basket-dialog">{item.item.store.name}</Link>
                                                            </div>
                                                        </div>
                                                        <div className='col' style={{
                                                            maxWidth: '120px',
                                                            textAlign: 'left',
                                                            paddingRight: '0px'
                                                        }}>
                                                            <span
                                                                className='basket-dialog-cost'>{item.amount + ' x ' + item.item.cost.cost + ' BYN'}</span>
                                                        </div>
                                                        <div className='col' style={{maxWidth: '120px', textAlign: 'left'}}>
                                                            <span
                                                                className='basket-full-cost-dialog'>{(item.amount * item.item.cost.cost) + ' BYN'}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : ''
                                    }

                                </div>

                                <div style={{
                                    height: '1px',
                                    width: '715px',
                                    borderRadius: '1px',
                                    backgroundColor: '#CFD6D8',
                                    marginTop: '20px',
                                    display: 'block'
                                }}>

                                </div>

                                <div style={{marginTop: '24px'}}>
                                    <span className='basket-dialog-header-delivery'>Доставка</span>
                                </div>
                                <div style={{display: 'block'}}>
                                    <div className="store-header-title" style={{marginTop: '20px'}}>
                                        <span>Тип доставки</span>
                                    </div>
                                    <div style={{marginTop: '10px'}}>
                                        <Select
                                            classes={classes}
                                            styles={selectStyles}
                                            options={this.state.suggestion}
                                            style={{width: '450px'}}
                                            // components={components}
                                            value={this.state.delivery}
                                            onChange={(e) => {
                                                this.deliveryChange(e);
                                            }}
                                            theme={theme => ({
                                                ...theme,
                                                width: '450px'
                                            })}
                                            placeholder="Выберите тип доставки"
                                        />

                                        <div className="d-flex justify-content-center" style={{width: '450px'}}>
                                            <span className='error-message' ref="nameError"
                                                  style={{display: (this.state.validate && (!this.state.delivery || Object.keys(this.state.delivery).length === 0)) ? 'block' : 'none'}}>Укажите тип доставки</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    {
                                        (!!this.state.delivery && Object.keys(this.state.delivery).length > 0 && this.state.delivery.value === 'delivery_self')
                                            ?
                                            ''
                                            :
                                            <div className='col' style={{maxWidth: '470px', marginRight: '20px'}}>

                                                <div style={{display: 'block'}}>
                                                    <div className="store-header-title" style={{marginTop: '20px'}}>
                                                        <span>Адрес</span>
                                                    </div>
                                                    <div>
                                                        <input type='text'
                                                               className='input-text-element-470'
                                                               value={this.state.address}
                                                               onChange={this.addressOnChange}
                                                               placeholder='Адрес'
                                                               style={{
                                                                   paddingLeft: '20px',
                                                                   marginTop: '10px'
                                                               }}/>
                                                        <div className="d-flex justify-content-center">
                                            <span className='error-message' ref="nameError"
                                                  style={{display: (this.state.validate && (!this.state.address || this.state.address.trim().length === 0)) ? 'block' : 'none'}}>Укажите адрес</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    }

                                    <div className='col' style={{maxWidth: '225px'}}>
                                        <div className="store-header-title" style={{marginTop: '20px'}}>
                                            <span>Телефон</span>
                                        </div>
                                        <div>
                                            <input type='text'
                                                   className='input-text-element-225'
                                                   value={this.state.phone}
                                                   onChange={this.phoneOnChange}
                                                   placeholder='Телефон'
                                                   style={{
                                                       paddingLeft: '20px',
                                                       marginTop: '10px'
                                                   }}/>
                                            <div className="d-flex justify-content-center">
                                            <span className='error-message' ref="nameError"
                                                  style={{display: (this.state.validate && (!this.state.phone || this.state.phone.trim().length === 0)) ? 'block' : 'none'}}>Укажите номер телефона</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='basket-dialog-header-delivery' style={{marginTop: '24px', marginBottom: '12px'}}>
                                <span>Оплата</span>
                            </div>

                            {
                                (this.props.basket.count > 0 && this.props.basket.items.filter(b => b.item.id === this.state.goodsSendId)[0])
                                    ?
                                    <RadioGroup
                                        aria-label="Оплата"
                                        name="gender1"
                                        className={classes.group}
                                        // value={this.state.paymentType}
                                        onChange={this.paymentOnChange}
                                    >
                                        {
                                            (this.props.basket.items.filter(b => b.item.id === this.state.goodsSendId)[0]).store.payment.filter(p => p.isEnable).map((item, idx) => {
                                                return (
                                                    <FormControlLabel key={item.alias}
                                                                      value={item.name}
                                                                      control={
                                                                          <Radio
                                                                              checked={this.state.paymentType === item.name}
                                                                              color="default"
                                                                              icon={RadioCrafty() }
                                                                              checkedIcon={RadioCraftyChecked()}
                                                                          />
                                                                      }
                                                                      label={item.name}
                                                                      classes={{root: classes.rootRadio}}
                                                    />

                                                );
                                            })
                                        }

                                    </RadioGroup>

                                    :
                                        'no'
                            }

                            <div style={{display: 'block', marginTop: '10px'}}>
                                <input
                                    type='text'
                                    className='input-text-element-716'
                                    value={this.state.commentSendText}
                                    onChange={this.onChangeComment}
                                    placeholder='Комментарий к заказу'
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px'
                                    }}/>
                            </div>
                            {
                                this.props.basket.count > 0
                                    ? this.props.basket.items.filter(item => item.item.id === this.state.goodsSendId).map(item => {
                                        return (
                                            <div style={{marginTop: '24px'}} key={item.item.id}>
                                                <div className='row'>
                                                    <div className='col' style={{maxWidth: '70px'}}>
                                                        <span className='basket-footer-cost-label'>Товар:</span>
                                                    </div>
                                                    <div className='col basket-footer-cost' style={{maxWidth: '120px'}}>
                                                        <span>{(item.item.cost.cost * item.amount) + ' BYN'}</span>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col ' style={{maxWidth: '70px'}}>
                                                        <span className='basket-footer-cost-label'>Доставка:</span>
                                                    </div>
                                                    <div className='col basket-footer-cost' style={{maxWidth: '120px'}}>
                                                        <span
                                                            className='basket-footer-cost-label'>{this.state.delivery.cost ? this.state.delivery.cost + ' BYN' : 'Бесплатно'}</span>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col' style={{maxWidth: '70px'}}>
                                                        <span className='basket-footer-cost-label'>Итого:</span>
                                                    </div>
                                                    <div className='col basket-footer-cost-b' style={{maxWidth: '120px'}}>
                                                        <span>{((item.item.cost.cost * item.amount) + (this.state.delivery.cost ? this.state.delivery.cost : 0)) + ' BYN'}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    : ''
                            }

                            <button style={{marginTop: '24px'}} disabled={this.state.sendingInProgress}
                                    onClick={this.onSendOrder} className='btn-basket-order-dialog'>
                                Заказать
                            </button>

                            <CircularProgress
                                style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>

                    </Dialog>
                </div>

            </div>

        );
    }

}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

BasketPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(BasketPage));
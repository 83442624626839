import React from 'react';

import {Link, withRouter} from 'react-router-dom';
import {getStore} from "../api/StoreAPI";

const checkStore = (storeId) => {
    if (storeId) {
    getStore(storeId).then(
        response => {
            let store = JSON.parse(response);
            return !!store.name;
        },
        error => {
            return false;
        }
    );
    } else {return false;}
};

const Header = (props) => (
        // !window.location.href.includes('agreement')
        // && !window.location.href.includes('login')
        // && !window.location.href.includes('registration')
            !props.location.pathname.includes('agreement')
            && !props.location.pathname.includes('login')
            && !props.location.pathname.includes('registration')
            && !props.location.pathname.includes('product/edit')
            && !props.location.pathname.includes('store/create')
            && !props.location.pathname.includes('about')
            && !props.location.pathname.includes('product/create')
            && !props.location.pathname.includes('manage/product/create')
            && !props.location.pathname.includes('manage/product/edit')
            && !props.location.pathname.includes('manage/store')
                ?
            <header>
                <div className="row" style={{marginTop: '40px', minWidth: '1230px'}}>
                    <div className='col' style={{width: '285px', display: 'grid-inline', paddingLeft: '0px'}}>
                        {
                            props.pr.user.storeIsFilled
                                ? <div >
                                    <div style={
                                        {
                                            marginRight: '10px',
                                            display: 'inline-grid',
                                            width: '20px',
                                            height: '20px',

                                            marginTop: '5px'
                                        }
                                    }>
                                        <img src="/images/shop.svg"
                                             className="shop-icon" />
                                    </div>
                                    <div style={{display: 'inline-grid', verticalAlign: 'text-top', marginTop: '-4px'}}>
                                        <Link id="store-label" to={'/manage/owner'}><span>Мой магазин</span></Link>
                                    </div>
                                    {/*<div  style={{display: 'inline-grid', verticalAlign: 'text-top', marginTop: '-4px', marginLeft: 15}}>*/}
                                    {/*    <Link id="sell-label" to={'/cabinet?tab=1'}>Хочу в подарок!</Link>*/}
                                    {/*</div>*/}
                                </div>
                                :
                                props.pr.user.name ?
                                    <div>
                                        <div  style={{display: 'inline-grid', verticalAlign: 'text-top'}}>
                                            <Link id="sell-label" to={'/manage/store'}>Продавай с нами</Link>
                                        </div>
                                        {/*<div  style={{display: 'inline-grid', verticalAlign: 'text-top', marginLeft: 15}}>*/}
                                        {/*    <Link id="sell-label" to={'/cabinet?tab=1'}>Хочу в подарок!</Link>*/}
                                        {/*</div>*/}


                                    </div>

                                    :
                                    <div>
                                        <div style={{display: 'inline-grid', verticalAlign: 'text-top'}}>
                                            <Link id="sell-label" to={'/login'}>Продавай с нами</Link>
                                        </div>
                                        {/*<div  style={{display: 'inline-grid', verticalAlign: 'text-top', marginLeft: 15}}>*/}
                                        {/*    <Link id="sell-label" to={'/cabinet?tab=1'}>Хочу в подарок!</Link>*/}
                                        {/*</div>*/}
                                    </div>


                        }

                    </div>
                    <div className='col' style={props.pr.user.name ? {display: 'grid-inline', width: '614px'} : {display: 'grid-inline', width: '695px'}}>
                        <div className="d-flex justify-content-center">
                            <Link style={props.pr.user.name ? {marginLeft: '0px', marginTop: '-8px'} : {marginLeft: '0px', marginTop: '-8px'}} to="/">
                                <img src='/images/logo.png' alt="" width='87px' height='48px' /></Link>
                        </div>
                    </div>
                    <div className='col d-flex justify-content-end'  style={{display: 'grid-inline', minWidth: '290px'}}>
                        <div style={{display: 'grid-inline', width: '20px'}}>
                            {
                                props.pr.user.name
                                    ?
                                    <a onClick={() => {
                                        document.getElementById('messengerBt').click();
                                    }
                                    }><img className='inline icon-20' src={props.pr.messages.unread > 0 ? "/images/messages-copy.svg" : "/images/messages.svg"}/></a>
                                    :
                                    <Link to={'/login'}><img className='inline icon-20' src={"/images/messages.svg"}/></Link>

                            }
                        </div>
                        <div style={{display: 'grid-inline', width: '20px', marginLeft: '20px'}}>
                            <Link to={props.pr.user.name ? '/bookmark' : '/login?redirectURL=/bookmark'}><img className='inline icon-20' src="/images/heart.svg"/></Link>
                        </div>
                        <div style={{display: 'grid-inline', width: '110px', marginLeft: '20px'}}>
                            <Link to={props.pr.user.name ? '/basket' : '/login?redirectURL=/basket'}>
                                {props.pr.basket.count > 0 ? <img className='inline icon-20' src="/images/cart-filled.svg"/> : <img className='inline icon-20' src="/images/cart.svg"/>}
                                <span className='top-menu-item' style={{marginLeft: '11px'}}>Корзина</span>
                            </Link>
                        </div>
                        <div style={props.pr.user.name ? {display: 'grid-inline', width: '150px'} : {display: 'grid-inline', width: '70px'}}>
                            {
                                props.pr.user.name ?
                                    <Link to="/cabinet?tab=1" className="row d-flex justify-content-end inline">
                                        <div style={{
                                            height: '30px',
                                            width: '30px',
                                            borderRadius: '50%',
                                            backgroundImage: 'url("' + (props.pr.user.photoMin ? props.pr.user.photoMin : '/images/avatar.png') + '")',
                                            backgroundSize: (props.pr.user.photoMin ? 'cover' : 'contain'),
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPositionX: 'center',
                                            backgroundPositionY: 'center'
                                        }}> </div>
                                        <span style={{maxWidth: '120px'}} id="login-header" className='hide-text'>{props.pr.user.name}</span>
                                    </Link>
                                    :
                                    <Link to={'/login?redirectURL=' + window.location.pathname} className="row d-flex justify-content-end inline">
                                        <div style={{
                                            height: '20px',
                                            width: '20px',
                                            // marginTop: '5px',
                                            // borderRadius: '50%',
                                            // backgroundImage: 'url("/images/user.png")',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPositionX: 'center',
                                            backgroundPositionY: 'center'
                                        }}><img src="/images/user.svg"
                                                className="icon-20" /></div>
                                        {/*<img style={{height: '20px', width: '20px'}} src="/images/user.png"/>*/}
                                        <span id="login-header">Войти</span>
                                    </Link>

                            }

                        </div>
                    </div>


                </div>
                {/*<NavLink to="/" activeClassName="is-active" exact={true}>Dashboard</NavLink>*/}
                {/*<NavLink to="/create" activeClassName="is-active">Create Expense</NavLink>*/}
            </header>
            : ''

);

export default withRouter(Header);

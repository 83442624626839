import React from 'react';
import {connect} from "react-redux";
import {getStore} from "../api/StoreAPI";
import moment from 'moment';
import {getUserById} from '../api/UserAPI';
import {Link, Redirect} from "react-router-dom";
import {refreshSession} from "../actions/authorization";
import * as ReactGA from "react-ga";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import {deleteMessageFile, sendMessageToUser, uploadMessageFile} from "../api/MessageAPI";
import {sendNotification} from "../actions/notifications";
import LinearProgress from "@material-ui/core/LinearProgress";
import {reportUser} from "../api/ReportAPI";
import {setUserPage} from "../actions/messages";
import Carousel, {Modal, ModalGateway} from "react-images";
import {Helmet} from "react-helmet";
import {getGifts} from "../api/GiftAPI";
import LoadingPage from "./LoadingPage";
import {linkLogger} from "../api/StatisticAPI";
import cookie from "react-cookies";


const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
        width: '960px'
    },
    tabsIndicator: {
        backgroundColor: '#E60936',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        fontSize: '20px',
        lineHeight: '30px',
        fontFamily: [
            'Tanos'
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#283149',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#283149',
            outline: 0
        },
    },
    tabSelected: {},
    paperWidthXl: {
        width: '795px'
    },
    titleRoot: {
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '24px',
        paddingBottom: '0px',
    },
    contentRoot: {
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingBottom: '24px',
        paddingTop: 0
    },
    typography: {
        padding: theme.spacing.unit * 3,
    },
});

class UserPage extends React.Component {

    constructor(props) {
        super(props);
        moment.locale('ru');
        this.state = {
            userId: this.props.match.params.userId,
            store: {
                name: '',
                owner: {
                    name: '',
                    photoMid: ''
                },
                createdDate: moment(),
                city: ''
            },
            filter: {
                page: 0,
                size: 4,
                storeId: 0,
                sort: 'createdDate,desc'
            },
            products: {},
            user: {
                name: '',
                createdDate: moment(),
                updatedDate: moment(),
                id: this.props.match.params.userId,
                email: '',
                city: '',
                country: '',
                description: '',
                phone: '',
                photoMin: '',
                photoMid: '',
                photoMax: '',
                confirmEmail: '',
                storeId: 0
            },
            gifts: {
                content: []
            },
            messageIsOpen: false,
            sendingInProgress: false,
            message: '',
            files: [],
            uploadingInProgress: false,
            progress: 0,
            messageReport: '',
            reportOpen: false,
            sendingInProgressReport: false,
            redirectLogin: false,
            photoIsOpen: false,
            giftIsOpen: false,
            giftId: 0,
            gift: {
                url: "",
                title: "",
                siteName: "",
                image: "",
                description: "",
            }
        };
        this.fileInput = new React.createRef();
    }

    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };

    componentWillMount() {
        this.loadUser();
    }

    toggleViewer = () => {
        this.setState(() => ({photoIsOpen: !this.state.photoIsOpen}));
    };

    componentWillUnmount() {
        if (!this.props.messages.isOpen)
            this.props.dispatch(setUserPage(null));
    }


    // loadProduct(storeId) {
    //     getFilteredGoods({...this.state.filter, storeId: storeId}).then(
    //         response => {
    //             this.setState(() => ({products: JSON.parse(response)}));
    //         },
    //         error => {
    //             console.log(error);
    //         }
    //     );
    // };

    loadStore() {
        getStore(this.state.user.storeId).then(
            response => {
                let store = JSON.parse(response);
                this.setState(() => ({
                    store: store
                }));
                // this.loadProduct(store.id);
            },
            error => {
                console.log(error);
            }
        );
    }

    onProgress = (event) => {

        if (event.lengthComputable) {
            let complete = (event.loaded / event.total * 100 | 0);
            this.setState({progress: complete});
        }
    };

    loadUser() {
        getUserById(this.props.match.params.userId).then(
            response => {
                let user = JSON.parse(response);
                this.setState(() => ({user: user}), () => {
                    this.props.dispatch(setUserPage(this.state.user));
                });
                if (user.storeIsFilled) {
                    this.loadStore();
                }
            },
            error => {
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403) {
                    this.props.dispatch(refreshSession()).then(() => {
                        this.loadUser();
                    });
                }
            }
        );
    }



    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        ReactGA.event({
            category: 'User',
            action: 'Open user page',
            label: 'user',
            value: this.state.userId
        });
        ReactGA.pageview(this.props.history.location.pathname);
        getGifts(this.state.userId).then(
            response => {
                this.setState({gifts: JSON.parse(response)});
            },
            error => {
                console.log(error);
            }
        );
    }

    sendOnClose = () => {
        this.setState({messageIsOpen: false})
    };

    giftIsOpen = (id) => {
        let gift = {};
        let filteredGift = this.state.gifts.content.filter(value => value.id === id);
        if (filteredGift){
            gift = filteredGift[0];
        }
        this.setState({giftIsOpen: true, gift: gift});
    };

    giftOnClose = () => {
        this.setState({
            giftIsOpen: false,
            loadingPage: false,
            titleError: false,
            descriptionError: false,
            imageError: false,
            urlError: false,
            urlLoaded: false,
            gift: {
                url: '',
                title: '',
                description: '',
                image: ''
            }});
    };


    sendMessage = () => {
        if (this.state.message.trim().length === 0 && this.state.files.length === 0){
            this.props.dispatch(sendNotification({
                open: true,
                messageInfo: {
                    message: 'Сообщение пустое',
                    key: new Date().getTime(),
                    variant: 'error'
                }
            }));
            return;
        }
        sendMessageToUser({message: this.state.message, userId: this.state.userId, fileIds: this.state.files.map(file => file.fileId)}).then(
            response => {
                this.setState({messageIsOpen: false, message: '', files: []});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Сообщение отправлено',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({messageIsOpen: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Сообщение не было доставлено',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    openMessage = () => {
        if (!this.props.user.name) {
            this.setState({redirectLogin: true});
            return;
        }
      this.setState({messageIsOpen: true});
    };

    onChangeMessage = (ev) => {
        this.setState({message: ev.target.value});
    };

    fileOnChange = (ev) => {
        if (ev.target.files[0] == null){
            return;
        }
        ev.persist();
        if ((ev.target.files[0].size / 1000 / 1000) > 100){

            this.props.dispatch(sendNotification({
                open: true,
                messageInfo: {
                    message: 'Файл слишком большой',
                    key: new Date().getTime(),
                    variant: 'error'
                }
            }));
            ev.target.value = "";
            return;
        }
        let formData = new FormData();
        formData.append('file', ev.target.files[0]);
        this.setState({uploadingInProgress: true});
        uploadMessageFile(formData, this.onProgress).then(
            response => {
                let file = JSON.parse(response);
                let state = {...this.state};
                state.files.push(file);
                this.setState({...state});
                ev.target.value = "";
                this.setState({uploadingInProgress: false, progress: 0});
            },
            error => {
                console.log(error);
            }
        );
    };

    removeMessageFile = (fileId) => {
        deleteMessageFile(fileId).then(
            response => {
                let state = {...this.state};
                state.files = state.files.filter(file => file.fileId !== fileId);
                this.setState({...state});
            },
            error => {
                console.log(error);
            }
        );
    };

    sendOnCloseReport = () => {
        this.setState({reportOpen: false});
    };

    reportOnOpen = () => {
        this.setState({reportOpen: true});
    };

    sendReport = () => {
        this.setState({sendingInProgressReport: true});
        reportUser(this.props.match.params.userId, this.state.messageReport).then(
            response => {
                this.setState({messageReport: '', reportOpen: false, sendingInProgressReport: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Жалоба отправлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({messageReport: '', reportOpen: false, sendingInProgressReport: false});
            }
        );
    };

    clickLink = (id) => {
        let gift = {};
        let filteredGift = this.state.gifts.content.filter(value => value.id === id);
        if (filteredGift){
            gift = filteredGift[0];
        }
        linkLogger({userId: this.state.userId, giftId: gift.id, url: gift.url, token: cookie.load('_web')});
    };

    onChangeReport = (ev) => {

        let messageReport = ev.target.value;
        this.setState({messageReport});
    };

    render() {

        const {classes} = this.props;

        return (
            <div>
                {
                    this.state.user.name ?
                        <Helmet>
                            <title>{this.state.user.name ? (this.state.user.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + (this.state.user.city ? (' | ' + this.state.user.city) : '') + ' | ' + this.state.userId ) : ''}</title>
                            <meta name="description" content={this.state.user.description ? (this.state.user.description.substring(0, 150).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + '...') : ''} />
                            <meta property="og:description" content={this.state.user.description ? (this.state.user.description.substring(0, 150).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + '...') : ''} />
                            <meta property="og:title" content={(this.state.user.name ? (this.state.user.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + (this.state.user.city ? (' | ' + this.state.user.city) : '') + ' | ' + this.state.userId ) : '')} />
                            <meta property="og:image" content={(this.state.user.photoMid ? this.state.user.photoMid : 'https://crafty.by/images/avatar.png')} />
                            <meta name='keywords' content={this.state.user.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + ', ремесленник, купить, заказать, цена, в интернет магазине, ' + this.state.user.city} />
                            <meta property="og:url" content={'https://crafty.by/user/'+this.state.userId}/>
                            <link rel="canonical" href={'https://crafty.by/user/'+this.state.userId} />
                        </Helmet>
                        : ''
                }
                {this.state.redirectLogin ? <Redirect to={'/login?redirectURL=/user/'+this.state.user.id}/> : ''}
                <div id="path-category">
                    <span>Главная  / {this.state.user.name}  </span>
                </div>
                <div style={{minHeight: '800px'}}>
                    <div className="row store-box">
                        <div style={{
                            width: '150px',
                            display: 'inline-block',
                            marginLeft: '15px'
                        }}>
                            <a href="javascript:void(0)">
                                <div className="user-page-photo"
                                     onClick={() => {
                                         if(this.state.user.photoMax){
                                             this.toggleViewer();
                                         }
                                     }}
                                     style={{
                                         backgroundImage: 'url("' + (this.state.user.photoMid ? this.state.user.photoMid : '/images/avatar.png') + '")',
                                         backgroundSize: (this.state.user.photoMid ? 'cover' : 'contain'),
                                         backgroundRepeat: 'no-repeat',
                                         backgroundPositionX: 'center',
                                         backgroundPositionY: 'center',
                                         borderRadius: '50%'
                                     }}
                                ></div>
                            </a>
                            <div style={{marginTop: '20px'}}>
                                <img style={{width: '21px', height: '21px', display: 'inline', marginRight: '10px', marginBottom: '2px'}} src="/images/attention.png" alt=""/><a onClick={this.reportOnOpen} className='report-link' href="javascript:void(0)">Пожаловаться</a>
                            </div>
                        </div>

                        <div className="user-page-info">
                            <div className="store-page-name">
                                <h1 className='store-page-name-h1'>{this.state.user.name}</h1>
                            </div>
                            <div style={{marginTop: '8px'}} className="main-card-store-pin">
                                <div className="main-card-store-pin-icon"></div>
                                <span
                                    className="main-card-store-location">{this.state.user.city} | {moment(this.state.user.createdDate).fromNow(true)} на Crafty</span>
                            </div>
                            <div>
                                <button className='save-btn-40'
                                        onClick={this.openMessage}
                                        style={{
                                            marginTop: '10px',
                                            marginLeft: '5px'
                                        }}>Написать</button>
                            </div>
                            {
                                this.state.user.storeIsFilled
                                    ?
                                    <div className='row d-flex align-items-center' style={{
                                        marginTop: '15px',
                                        marginLeft: '0px'
                                    }}>
                                        <div className='user-page-store-photo'
                                             style={{
                                                 backgroundImage: 'url("' + this.state.store.photoMid + '")',
                                                 width: '50px',
                                                 height: '50px',
                                                 backgroundSize: 'cover',
                                                 backgroundRepeat: 'no-repeat',
                                                 backgroundPositionX: 'center',
                                                 backgroundPositionY: 'center'
                                             }}
                                        ></div>
                                        <Link to={'/store/' + this.state.store.id} className="name-owner align-middle"
                                              style={{
                                                  marginLeft: '15px',
                                                  marginTop: '0'
                                              }}>{this.state.store.name}</Link>
                                    </div>
                                    :
                                    ''
                            }

                        </div>
                        <div className="user-page-description" style={{maxWidth: '400px'}}>
                            <div className="store-header-title">
                                <span>Обо мне</span>
                            </div>
                            <div style={{marginTop: '6px'}}>
                                <p className="user-page-description-text">{this.state.user.description}</p>
                            </div>
                        </div>

                        <div style={{
                            height: '1px',
                            width: '960px',
                            borderRadius: '1px',
                            backgroundColor: '#CFD6D8',
                            marginTop: '52px',
                            marginLeft: '15px',
                            display: 'block'
                        }}>

                        </div>

                    </div>
                    {
                        this.state.gifts.content ?
                            <div style={{marginLeft: '161px', marginTop: '33px'}}>
                                <div style={{marginTop: 20, marginBottom: 20}} className="store-header-product">
                                    <h2 style={{fontSize: 25}}>Хочу в подарок</h2>
                                </div>
                                {
                                    this.state.gifts.content.map(gift => {
                                        return (
                                            <div className="category-product-card" key={gift.id} >
                                                <div className="search-card-goods-image"
                                                     onClick={() => {
                                                         this.giftIsOpen(gift.id);
                                                     }}
                                                     style={{
                                                         backgroundImage: 'url("' + gift.image + '")',
                                                         backgroundSize: 'contain',
                                                         backgroundRepeat: 'no-repeat',
                                                         backgroundPositionX: 'center',
                                                         backgroundPositionY: 'center',
                                                         position: 'relative'

                                                     }}
                                                >
                                                </div>
                                                <div className="main-card-goods-desc">
                                                    <div className="main-card-goods-name">
                                                        <a className="main-card-goods-name-text"
                                                           onClick={() => {
                                                               this.clickLink(gift.id);
                                                           }}
                                                           rel="noopener noreferrer"
                                                           target='_blank'
                                                           href={gift.url}>{gift.title}</a>
                                                    </div>
                                                    <div className="main-card-goods-store">
                                                        <a href={gift.url}
                                                           onClick={() => {
                                                               this.clickLink(gift.id);
                                                           }}
                                                           rel="noopener noreferrer"
                                                           className="main-card-goods-store-link">{gift.siteName ? gift.siteName : ''}</a>
                                                    </div>
                                                </div>

                                            </div>
                                        );
                                    })
                                }
                            </div>
                            : ''
                    }

                </div>
                <div>
                    <Dialog
                        open={this.state.messageIsOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Сообщение
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                {
                                    this.state.files.length > 0 ?
                                        this.state.files.map(file => {
                                            return (
                                                <div key={file.fileId}>
                                                    <div style={{display: 'inline-flex', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '284px', wordBreak: 'break-all'}}>
                                                    <a href={file.link} target='_blank'><span style={{
                                                        maxWidth: '250px',
                                                        overflow: 'hide'
                                                    }}>{file.name}</span></a>
                                                    </div><a style={{marginLeft: '10px', color: 'black'}} href="javascript:void(0)" onClick={() => {
                                                    this.removeMessageFile(file.fileId);
                                                }}>X</a>
                                                </div>
                                            );

                                        })
                                        : ''
                                }
                                {
                                    this.state.uploadingInProgress ?
                                        <div >
                                            <LinearProgress variant="determinate" value={this.state.progress}/>
                                        </div>
                                        : ''
                                }
                                    <textarea
                                        className='textarea-element'
                                        value={this.state.message}
                                        onChange={this.onChangeMessage}
                                        placeholder={'Введите сообщение'}
                                        style={{
                                            padding: '20px',
                                            marginTop: '10px'
                                        }} />

                            </div>
                            <CircularProgress
                                style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <input ref={this.fileInput} style={{display: 'none'}} type="file" onChange={this.fileOnChange}/>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' style={{float: 'right', width: '40px', marginRight: '5px'}} onClick={() => {
                                this.fileInput.current.click();
                            }}>+</button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgress} onClick={this.sendMessage}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.reportOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnCloseReport}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Пожаловаться на пользователя
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                <div  className='d-flex justify-content-center'>
                                    <CircularProgress
                                        style={{display: this.state.sendingInProgressReport ? 'block' : 'none'}}
                                        color="secondary"/>
                                </div>
                                <textarea
                                    className='textarea-element'
                                    value={this.state.messageReport}
                                    onChange={this.onChangeReport}
                                    placeholder={'Введите сообщение'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px',
                                        height: '150px'
                                    }} />

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.sendOnCloseReport} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgressReport} onClick={this.sendReport}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xl'}
                        classes={{paperWidthXl: classes.paperWidthXl}}
                        open={this.state.giftIsOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.giftOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle
                            id="alert-dialog-slide-title"
                            classes={{root: classes.titleRoot}}
                        >
                            <div className='row'>
                                <div className='col-md-10'>
                                    <span className='alert-dialog-slide-title'>{this.state.gift.siteName}</span>
                                </div>
                                <div className='col-md-2'>
                                    <img onClick={this.giftOnClose} src="/images/close.svg"
                                         className="close"/>
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent classes={{root: classes.contentRoot}}>
                            {
                                this.state.loadingPage ? <LoadingPage opacity={0.5} /> : ''
                            }
                            <div style={{display: 'block',}}>

                                <div>
                                    <div style={{display: 'block'}}>
                                        <div className="store-header-title" style={{marginTop: '20px'}}>
                                            <span>Ссылка на подарок</span>
                                        </div>
                                        <div style={{width: '100%', wordBreak: 'break-word'}}>
                                            <a target='_blank'
                                               rel="noopener noreferrer"
                                               onClick={() => {
                                                   this.clickLink(this.state.gift.id);
                                               }}
                                               style={{
                                                marginTop: '10px',
                                                width: '78%'
                                            }} href={this.state.gift.url}>{this.state.gift.url}</a>
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    height: '1px',
                                    width: '715px',
                                    borderRadius: '1px',
                                    backgroundColor: '#CFD6D8',
                                    marginTop: '20px',
                                    display: 'block'
                                }}>

                                </div>
                                        <div>
                                            <div style={{display: 'block', marginTop: 20, width: '100%'}} className='d-flex justify-content-center'>
                                                <div style={{
                                                    height: 400,
                                                    width: 400,
                                                    backgroundImage: 'url("' + this.state.gift.image + '")',
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPositionX: 'center',
                                                    backgroundPositionY: 'center',
                                                    position: 'relative'
                                                }}>

                                                </div>
                                            </div>

                                            <div style={{display: 'block'}}>
                                                <div className="store-header-title" style={{marginTop: '20px'}}>
                                                    <span>Название</span>
                                                </div>
                                                <div>
                                                    <span
                                                           style={{
                                                               marginTop: '10px',
                                                               width: '100%'
                                                           }}>{this.state.gift.title}</span>
                                                </div>
                                            </div>
                                            <div style={{display: 'block'}}>
                                                <div className="store-header-title" style={{marginTop: '20px'}}>
                                                    <span>Описание</span>
                                                </div>
                                                <div>
                                                    <span
                                                        className='gift-description'
                                                        style={{
                                                            marginTop: '10px'
                                                        }}>{this.state.gift.description}</span>
                                                </div>
                                            </div>
                                        </div>

                            </div>

                            {/*<button style={{marginTop: '24px'}} disabled={this.state.sendingInProgress}*/}
                            {/*        onClick={this.saveGiftAfterEdit} className='btn-basket-order-dialog'>*/}
                            {/*    Готово*/}
                            {/*</button>*/}

                            <CircularProgress
                                style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>

                    </Dialog>
                </div>
                <ModalGateway>
                    {
                        this.state.photoIsOpen ?
                        <Modal onClose={this.toggleViewer}>
                            <Carousel views={[{source: this.state.user.photoMax}]} />
                        </Modal>
                     : ''
                    }
                </ModalGateway>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

UserPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps)(UserPage));
import {url} from '../settings/Settings';
import cookie from "react-cookies";

const reportGoods = (goodsId, message) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/report/goods/'+goodsId, true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };
        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify({message: message}));
    });
};


const reportStore = (storeId, message) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/report/store/'+storeId, true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };
        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify({message: message}));
    });
};


const reportUser = (userId, message) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/report/user/'+userId, true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };
        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify({message: message}));
    });
};


const reportOrder = (orderId, message) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/report/order/'+orderId, true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };
        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify({message: message}));
    });
};


export {reportGoods, reportStore, reportOrder, reportUser};
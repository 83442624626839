import React from 'react';
import {
    getGoodsById,
    getFilteredGoods,
    addBookmark,
    removeBookmark,
    addToBasket,
    deleteBasket,
    getComment, commentGoods, getMyGoodsById
} from '../api/GoodsAPI';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../styles/custom.css';
import {Link, Redirect} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {refreshSession} from "../actions/authorization";
import * as ReactGA from "react-ga";
import {saveBasket} from "../actions/basket";
import {sendNotification} from "../actions/notifications";
import {reportGoods} from "../api/ReportAPI";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import ym from 'react-yandex-metrika';
import Carousel, {Modal, ModalGateway} from "react-images";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import {selectByAliasChild} from "../selectors/category";
import * as VanillaSharing from 'vanilla-sharing';
import {logSocial} from "../api/LogAPI";
import {Helmet} from "react-helmet";

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
        width: '960px'
    },
    tabsIndicator: {
        backgroundColor: '#E60936',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        fontSize: '20px',
        lineHeight: '30px',
        fontFamily: [
            'Tanos'
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#283149',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#283149',
            outline: 0
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
});

export class ProductPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            product: {},
            photos: [],
            current: 0,
            otherProduct: [],
            redirect: false,
            redirectLogin: false,
            message: '',
            reportOpen: false,
            sendingInProgress: false,
            photoIsOpen: false,
            photoArray: [],
            commentFilter: {
                page: 0,
                size: 4,
                goodsId: this.props.match.params.id
            },
            comments: null,
            messageIsOpen: false,
            comment: '',
            commentInProgress: false,
            redirectPhone: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id){
            this.setState({commentFilter: {
                    page: 0,
                    size: 4,
                    goodsId: this.props.match.params.id
                }, current: 0, photoIsOpen: false }, () => {
                this.updateProduct();
            });
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.event({
            category: 'Product',
            action: 'Open product',
        });
        ym('product', this.props.history.location.pathname);
        ReactGA.pageview(this.props.history.location.pathname);
        this.updateProduct();

    }

    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };

    toggleViewer = () => {
        this.setState(() => ({photoIsOpen: !this.state.photoIsOpen}));
    };

    updateProduct = () => {
        window.scrollTo(0, 0);
        let product;
        if (window.location.href.includes('/manage/preview')) {
            getMyGoodsById(this.props.match.params.id).then(
                response => {
                    product = JSON.parse(response);
                    this.setState(() => {
                        return {currentIndex: 0, product: product};
                    }, () => {
                        getComment(this.state.commentFilter).then(
                            response => {
                                this.setState({comments: response.data});
                            }
                        ).catch(
                            error => {
                                // console.log(error);
                            }
                        );
                    });
                    this.loadOther(product.store.id);
                    let photos = product.photos;
                    photos.sort((a, b) => {
                        if (a.orderPlace < b.orderPlace) {
                            return -1;
                        }
                        if (a.orderPlace > b.orderPlace) {
                            return 1;
                        }
                        return 0;
                    });
                    let photoArray = [];
                    let photoArrayMid = [];
                    photos.forEach(photo => {
                        photoArray.push({source: photo.photoMax});
                        photoArrayMid.push({source: photo.photoMid});
                    });
                    this.setState(() => ({photos: photos, photoArray: photoArray, photoArrayMid: photoArrayMid}));
                },
                error => {
                    // this.setState({redirect: true});
                    let resp = JSON.parse(error.message);
                    if (error.code === 401 || error.code === 403) {
                        this.props.dispatch(refreshSession()).then(() => {
                            this.updateProduct();
                        });
                    }
                }
            );

        } else {
            getGoodsById(this.props.match.params.id).then(
                response => {
                    product = JSON.parse(response);
                    this.setState(() => {
                        return {product: product};
                    });
                    this.loadOther(product.store.id);
                    let photos = product.photos;
                    photos.sort((a, b) => {
                        if (a.orderPlace < b.orderPlace) {
                            return -1;
                        }
                        if (a.orderPlace > b.orderPlace) {
                            return 1;
                        }
                        return 0;
                    });
                    let photoArray = [];
                    photos.forEach(photo => {
                        photoArray.push({source: photo.photoMax});
                    });
                    this.setState(() => ({photos: photos, photoArray: photoArray}));
                },
                error => {
                    let resp = JSON.parse(error.message);
                    if (error.code === 401 || error.code === 403) {
                        this.props.dispatch(refreshSession()).then(() => {
                            this.updateProduct();
                        });
                    }

                    this.setState({redirect: true});
                }
            );
            getComment(this.state.commentFilter).then(
                response => {
                    this.setState({comments: response.data});
                }
            ).catch(
                error => {
                    console.log(error);
                }
            );

        }



    };

    getCommentLabel = (number) => {
        if (number > 4 && number < 20) {
            return 'отзывов';
        }
        let numStr = number.toString();
        let lastNumber  = 0;
        if (numStr.length > 1) {
            lastNumber = Number.parseInt(numStr.substr(numStr.length - 1));
        }else{
            lastNumber = Number.parseInt(numStr);
        }
        if (lastNumber === 0 || lastNumber > 4) {
            return 'отзывов';
        }
        if (lastNumber === 1) {
            return 'отзыв';
        }
        if (lastNumber > 1 && lastNumber < 5) {
            return 'отзыва';
        }
        return 'отзывов';
    };

    getViewsLabel = (number) => {
        if (number > 4 && number < 20) {
            return 'просмотров';
        }
        let numStr = number.toString();
        let lastNumber  = 0;
        if (numStr.length > 1) {
            lastNumber = Number.parseInt(numStr.substr(numStr.length - 1));
        }else{
            lastNumber = Number.parseInt(numStr);
        }
        if (lastNumber === 0 || lastNumber > 4) {
            return 'просмотров';
        }
        if (lastNumber === 1) {
            return 'просмотр';
        }
        if (lastNumber > 1 && lastNumber < 5) {
            return 'просмотра';
        }
        return 'просмотров';
    };


    commentOnChange = (ev) => {
        this.setState({comment: ev.target.value});
    };

    loadOther = (id) => {
        let otherProduct;
        const filter = {
            page: 0,
            size: 4,
            storeId: id
        };
        getFilteredGoods(filter).then(
            response => {
                otherProduct = JSON.parse(response);
                this.setState(() => ({otherProduct: otherProduct.content}));
            },
            error => {
                console.log(error);
            }
        );
    };

    hexToRGB = (hex) => {
        const RGB_HEX = /^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i;
        const [, short, long] = String(hex).match(RGB_HEX) || [];

        if (long) {
            const value = Number.parseInt(long, 16);
            return [value >> 16, value >> 8 & 0xFF, value & 0xFF];
        } else if (short) {
            return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
        }

    };

    bookmark = () => {
        if (!this.props.user.name){
            this.setState({redirectLogin: true});
            return;
        }
        addBookmark(this.state.product.id).then(
            response => {
                let product = {...this.state.product};
                product.booked = true;
                this.setState({product});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Добавлено в закладки',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                console.log(error.message);
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при добавлении',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );

    };

    removeBookmark = () => {

        removeBookmark(this.state.product.id).then(
            response => {
                let product = {...this.state.product};
                product.booked = false;
                this.setState({product});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Удалено из закладок',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                console.log(error.message);
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при удалении',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );

    };

    addOrderToBasket = () => {
        if (!this.props.user.name){
            this.setState({redirectLogin: true});
            return;
        }
        let checkInBasket = this.props.basket.items.filter(value => {
            return value.item.id === this.state.product.id;
        }).length > 0;
        if (checkInBasket){
            deleteBasket(this.state.product.id).then(
                response => {
                    this.props.dispatch(saveBasket(JSON.parse(response)));
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Товар удален из корзины',
                            key: new Date().getTime(),
                            variant: 'success'
                        }
                    }));
                },
                error => {
                    console.log(error.message);
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Ошибка при удалении',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
            );
        } else {
            addToBasket(this.state.product.id).then(
                response => {
                    this.props.dispatch(saveBasket(JSON.parse(response)));
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Товар добавлен в корзину',
                            key: new Date().getTime(),
                            variant: 'success'
                        }
                    }));
                },
                error => {
                    console.log(error.message);
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Ошибка при добавлении',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
            );
        }

    };

    sendComment = () => {

        let comment = this.state.comment.trim();
        if (comment.length === 0){
            this.sendOnClose();
            return;
        }

        commentGoods({goodsId: this.props.match.params.id, comment: this.state.comment}).then(
            response => {
                console.log('response');
                console.log(response);
                getComment(this.state.commentFilter).then(
                    response => {
                        this.setState({comments: response.data});
                        this.sendOnClose();

                    }
                ).catch(
                    error => {
                        console.log(error.message);
                    }
                );

            }
        ).catch(
            error => {
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при публикации отзыва. Обновите страницу.',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
                this.sendOnClose();
            }
        );
    };

    sendOnClose = () => {
        this.setState({reportOpen: false, messageIsOpen: false, commentInProgress: false, comment: ''});
    };

    reportOnOpen = () => {
        this.setState({reportOpen: true});
    };

    sendReport = () => {
        this.setState({sendingInProgress: true});
        reportGoods(this.props.match.params.id, this.state.message).then(
            response => {
                this.setState({message: '', reportOpen: false, sendingInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Жалоба отправлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({message: '', reportOpen: false, sendingInProgress: false});
            }
        );
    };

    onChangeReport = (ev) => {

        let message = ev.target.value;
        this.setState({message});
    };

    loadAllComments = () => {
        this.setState({commentFilter: {
                page: 0,
                size: 300,
                goodsId: this.props.match.params.id
            }}, () => {
                getComment(this.state.commentFilter).then(
                    response => {
                        this.setState({comments: response.data});
                        this.sendOnClose();

                    }
                ).catch(
                    error => {
                        console.log(error.message);
                    }
                );
        });
    };

    logSocialShared = (type) => {
        const userId = this.props.user.name ? this.props.user.id : null;
        logSocial({page: 'https://crafty.by/product/' + this.state.product.id, userId: userId, type: type});
    };



    sharePinterest= () => {
        VanillaSharing.pinterest({
            url: ('https://crafty.by/product/' + this.state.product.id),
            description: (this.state.product.name + '. ' + this.state.product.description),
            media: (this.state.product.photos.length > 0
                ? this.state.product.photos.filter((ph) => {
                    return ph.orderPlace === 1
                })[0].photoMax
                : ''),
        });
        this.logSocialShared('PINTEREST');

    };

    shareFacebook = () => {
        VanillaSharing.fbButton({
            url: ('https://crafty.by/product/' + this.state.product.id)
        });
        this.logSocialShared('FACEBOOK');

    };


    shareVk = () => {
        VanillaSharing.vk({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
            description: this.state.product.description,
            image: (this.state.product.photos.length > 0
                ? this.state.product.photos.filter((ph) => {
                    return ph.orderPlace === 1
                })[0].photoMax
                : ''),
            isVkParse: true,
        });
        this.logSocialShared('VK');

    };

    shareOk = () => {
        VanillaSharing.ok({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
            image: (this.state.product.photos.length > 0
                ? this.state.product.photos.filter((ph) => {
                    return ph.orderPlace === 1
                })[0].photoMax
                : ''),
        });
        this.logSocialShared('OK');

    };

    shareViber = () => {
        VanillaSharing.viber({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
        });
        this.logSocialShared('VIBER');

    };


    shareTelegram = () => {
        VanillaSharing.telegram({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
        });
        this.logSocialShared('TELEGRAM');

    };

    shareWhatsApp = () => {
        VanillaSharing.whatsapp({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
        });
        this.logSocialShared('WHATSAPP');
    };


    shareTwitter = () => {
        VanillaSharing.tw({
            url: ('https://crafty.by/product/' + this.state.product.id),
            title: this.state.product.name,
            hashtags: ['craftyby', 'handmade', 'ручнаяработа']
        });
        this.logSocialShared('TWITTER');
    };



    render() {


        const category = this.state.product.category ? selectByAliasChild(this.props.dict.categories, this.state.product.category.alias) : null;

        return (
            <div>
                {this.state.redirect ? <Redirect to={{pathname: '/', state: {status: 404}}} /> : ''}
                {this.state.redirectLogin ? <Redirect to={'/login?redirectURL=/product/'+this.state.product.id}/> : ''}
                {this.state.redirectPhone ? <Redirect to={'/cabinet/edit'}/> : ''}
                {this.state.product.name ?
                    <div>
                        <Helmet>
                            <title>{this.state.product.name ? (this.state.product.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + ' ручной работы - купить на Crafty.by | ' + this.state.product.store.city + ' | ' + this.state.product.id) : ''}</title>
                            <meta name="description" content={this.state.product.description ? (this.state.product.description.substring(0, 160).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ')) : ''} />
                            <meta property="og:description" content={this.state.product.description ? (this.state.product.description.substring(0, 160).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ')) : ''} />
                            <meta name="Description" content={this.state.product.description ? (this.state.product.description.substring(0, 160).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ')) : ''} />
                            <meta property="og:title" content={(this.state.product.name ? (this.state.product.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ')  + ' ручной работы - купить на Crafty.by | ' + this.state.product.store.city + ' | ' + this.state.product.id ) : '')} />
                            <meta property="og:image" content={(this.state.product.photos.length > 0
                                ? this.state.product.photos.filter((ph) => {
                                    return ph.orderPlace === 1
                                })[0].photoMid
                                : '')} />
                            <link rel="canonical" href={'https://crafty.by/product/'+this.state.product.id} />
                            <meta name='keywords' content={this.state.product.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + ',ручной работы, купить, заказать, цена, в интернет магазине, ' + this.state.product.store.city} />
                            <meta property="og:type" content="product" />
                            <meta property="og:url" content={'https://crafty.by/product/'+this.state.product.id}/>
                            <meta property="og:availability" content={this.state.product.isAvailable ? 'instock' : 'preorder'} />
                            <meta property="product:price:amount" content={this.state.product.cost.cost} />
                            <meta property="product:price:currency" content="BYN" />
                            <meta property="og:brand" content={this.state.product.store.name}/>
                        </Helmet>
                        {
                            category ?
                                <div className="path-category" style={{paddingTop: 44}}>
                                    <Link className='path-category' to={'/'}><span>Главная</span></Link>
                                    <span className='path-category'> / </span>
                                    <Link className='path-category' to={'/catalog/' + category.parent.alias}>{category ? category.parent.name : ''}</Link>
                                    <span className='path-category'> / </span>
                                    <Link className='path-category' to={'/catalog/' + category.parent.alias + '/' + category.alias}>{category ? category.name : ''}</Link>
                                </div>
                                : ''
                        }
                        {
                            (this.props.user.name && this.props.user.roles.filter((role) => (role.name === 'ROLE_ADMIN' || role.name === 'ROLE_MANAGER')).length > 0)
                            ?
                                <Link to={'/manage/product/edit/'+ this.state.product.id} >Редактировать</Link>
                            :  ''
                        }

                        <div id="main-product-block">
                            <div className="row" style={{marginLeft: '0px!important'}}>
                                <div style={{display: 'inline-block'}}>
                                    <a href="javascript:void(0)">
                                        <div ref="picture" className="product-image-lg"
                                             onClick={() => {
                                                 this.toggleViewer();
                                             }}
                                             style={{
                                            backgroundImage: 'url("'
                                                + (this.state.product.photos.length > 0
                                                    ? this.state.product.photos.filter((ph) => {
                                                        return ph.orderPlace === 1
                                                    })[0].photoMid
                                                    : '') + '")',
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center'
                                        }}>

                                        </div>
                                    </a>
                                    <div id="product-page-gallery" style={{marginTop: '20px'}}>

                                        {
                                            this.state.photos.map((photo, idx) => {
                                                if (idx !== this.state.current)
                                                    return (
                                                        <div key={idx} className="product-image-sm " style={{
                                                            backgroundImage: 'url("' + photo.photoMin + '")',
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center'
                                                        }}

                                                             onClick={(e) => {
                                                                 this.setState(() => ({current: idx}));
                                                                 this.refs.picture.style.backgroundImage = 'url(' + this.state.photos[idx].photoMid + ')';
                                                             }}
                                                        >

                                                        </div>

                                                    );
                                            })
                                        }
                                    </div>


                                </div>
                                <div style={{
                                    display: 'inline-block',
                                    marginLeft: '51px',
                                    maxWidth: '450px',
                                    wordBreak: 'break-word'
                                }}>
                                    <div id="product-store-link">
                                        <Link
                                            to={'/store/' + this.state.product.store.id}>{this.state.product.store.name}</Link>
                                    </div>
                                    <div className="product-page-name">
                                        <h1 className='product-page-name'>{this.state.product.name}</h1>
                                    </div>
                                    {
                                        !!this.state.product.color ?
                                            <div id="product-page-color">
                                                <span>Цвет</span>
                                                <div id="product-page-color-circle">
                                                    <div className="color-circle" style={{
                                                        border: '1px solid black',
                                                        backgroundColor: this.state.product.color,
                                                        boxShadow: '1px 2px 4px 0 rgba(' + this.hexToRGB(this.state.product.color)[0] + ',' + this.hexToRGB(this.state.product.color)[1] + ',' + this.hexToRGB(this.state.product.color)[2] + ',0.19)'
                                                    }}>

                                                    </div>
                                                    {
                                                        this.state.product.color2 ?
                                                            <div className="color-circle" style={{
                                                                border: '1px solid black',
                                                                backgroundColor: this.state.product.color2,
                                                                boxShadow: '1px 2px 4px 0 rgba(' + this.hexToRGB(this.state.product.color2)[0] + ',' + this.hexToRGB(this.state.product.color2)[1] + ',' + this.hexToRGB(this.state.product.color2)[2] + ',0.19)'
                                                            }}>

                                                            </div>
                                                            : ''
                                                    }

                                                </div>
                                            </div>
                                            : <div></div>
                                    }
                                    {
                                        this.state.product.materials ?
                                            <div id="product-page-materials">
                                                <span>Материалы</span>
                                                <div style={{marginTop: '10px'}}>
                                                    <p>{this.state.product.materials}</p>
                                                </div>
                                            </div>
                                            : <div></div>
                                    }

                                    <div id="product-page-cost">
                                        {
                                            this.state.product.oldCost ?
                                                <span className='crossed-red-cost' style={{fontSize: 16}}>{this.state.product.oldCost.cost} BYN  </span>
                                                : ''
                                        }
                                        <span>{(this.state.product.cost != null
                                            && this.state.product.cost.cost != null)
                                            ? this.state.product.cost.cost : 0} BYN</span>
                                    </div>
                                    <div style={{marginTop: '23px'}}>
                                        <div className='inline' style={{marginRight: '20px'}}>
                                            <button onClick={this.addOrderToBasket} className='basket-button'>
                                                {
                                                    this.props.user.name
                                                        ? (this.props.basket.items.filter(value => {
                                                            return value.item.id === this.state.product.id;
                                                        }).length > 0) ? 'Добавлено в корзину' : 'Добавить в корзину'
                                                        : 'Добавить в корзину'
                                                }
                                                </button>
                                        </div>
                                        <div className='inline'>
                                            <button onClick={this.state.product.booked ? this.removeBookmark : this.bookmark} className='bookmark-big-button'>
                                                {
                                                    this.state.product.booked
                                                        ?
                                                        <img style={{marginRight: '9px', paddingBottom: '2px'}} src="/images/heart-filled.png" />
                                                        :
                                                        <img style={{marginRight: '9px', paddingBottom: '2px'}} src="/images/heart.png" />
                                                }
                                                <span>{this.state.product.booked ? 'Сохранено' : 'Сохранить'}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{marginTop: '20px'}}>
                                        <img style={{width: '21px', height: '21px', display: 'inline', marginRight: '10px', marginBottom: '2px'}} src="/images/attention.png" alt=""/><a onClick={this.reportOnOpen} className='report-link' href="javascript:void(0)">Пожаловаться</a>
                                    </div>
                                    <div className="product-page-delimiter">

                                    </div>
                                    <div className="product-page-delivery">
                                        {
                                            this.state.product.store.deliveries.map((deliv, idx) => {
                                                return (
                                                    <p key={idx}>{deliv.deliveryType.name} : {deliv.city}.
                                                        Срок: {deliv.period}.
                                                        Стоимость: {deliv.cost ? (deliv.cost + ' ' + deliv.currency) : 'Бесплатно'} </p>
                                                );
                                            })
                                        }
                                    </div>

                                    {
                                        this.state.product.isAvailable
                                            ?
                                            <div>
                                                <div className="product-page-delimiter">

                                                </div>
                                                <div className="product-page-delivery">
                                                    <p>Товар в наличии</p>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className="product-page-delimiter">

                                                </div>
                                                <div className="product-page-delivery">
                                                    <p>Товар изготавливается под заказ</p>
                                                </div>
                                            </div>
                                    }




                                </div>

                            </div>

                            {
                                (this.state.product && this.state.product.approved) ?
                                    <div style={{width: 1010}} className='d-flex justify-content-start'>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none", paddingLeft: 0}} onClick={this.sharePinterest}>
                                            <img style={{width: 30, height: 30}} src="/images/pinterestshare.svg" alt="Поделиться в Pinterest"/>
                                        </button>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareFacebook}>
                                            <img style={{width: 30, height: 30}} src="/images/fbshare.svg" alt="Поделиться в Facebook"/>
                                        </button>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareVk}>
                                            <img style={{width: 30, height: 30}} src="/images/vkshare.svg" alt="Поделиться во Вконтакте"/>
                                        </button>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareOk}>
                                            <img style={{width: 30, height: 30}} src="/images/okshare.svg" alt="Поделиться в Одноклассниках"/>
                                        </button>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareTelegram}>
                                            <img style={{width: 30, height: 30}} src="/images/telegramshare.svg" alt="Поделиться в Telegram"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareWhatsApp}>
                                            <img style={{width: 30, height: 30}} src="/images/whatsappshare.svg" alt="Поделиться в WhatsApp"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareTwitter}>
                                            <img style={{width: 30, height: 30}} src="/images/twshare.svg" alt="Поделиться в Twitter"/>
                                        </button>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareViber}>
                                            <img style={{width: 30, height: 30}} src="/images/vibershare.svg" alt="Поделиться в Viber"/>
                                        </button>
                                    </div>

                                    : ''
                            }



                            <div style={{
                                height: '1px',
                                width: '1010px',
                                borderRadius: '1px',
                                backgroundColor: '#CFD6D8',
                                marginTop: '30px',
                                display: 'block'
                            }}>

                            </div>

                            <div id="product-page-description-h">
                                <span>Описание</span>
                            </div>
                            <div id="product-page-description" style={{wordBreak: 'break-word'}}>
                                <p style={{whiteSpace: 'pre-wrap'}}>
                                    {this.state.product.description}
                                </p>
                            </div>

                            <div style={{
                                height: '1px',
                                width: '1010px',
                                borderRadius: '1px',
                                backgroundColor: '#CFD6D8',
                                marginTop: '52px',
                                display: 'block'
                            }}>

                            </div>

                            <div id="product-page-description-h">
                                <div style={{float: 'left'}}>
                                    <span>Отзывы</span>
                                    {/*<div className='store-header-title' style={{marginTop: 30}}>*/}
                                    {/*    <span>{this.state.product.views} просмотров</span>*/}
                                    {/*</div>*/}
                                </div>
                                <div style={{float: 'right', marginRight: '50px'}}>
                                    <span style={{marginRight: '20px'}} className='text-16-grey'>Вы покупали этот товар?</span>
                                    <button onClick={() => {
                                        if (!this.props.user.name){
                                            this.setState({redirectLogin: true});
                                            return;
                                        }
                                        if (!this.props.user.phoneIsConfirmed){
                                            this.setState({redirectPhone: true});
                                            return;
                                        }

                                        this.setState({messageIsOpen: true});
                                    }} className='button-174' style={{paddingLeft: '0'}}>
                                        <img style={{marginBottom: '3px', marginRight: '5px'}}
                                                                        src="/images/plus_2.svg"
                                                                        className="plus"/> Добавить отзыв</button>
                                </div>
                            </div>

                            <div className='store-header-title'>
                                <span>{this.state.comments ? (this.state.comments.totalElements + ' ' + this.getCommentLabel(this.state.comments.totalElements))
                                    : (0 + ' ' + this.getCommentLabel(0))} {}</span> <span>|</span> <span >{this.state.product.views} {this.getViewsLabel(this.state.product.views)}</span>
                            </div>
                            <div>
                            {
                                this.state.comments ?
                                    this.state.comments.content.map(comment => {
                                        return (
                                            <div key={comment.id} style={{marginTop: 33}}>
                                                <div className='row'>
                                                    <div className='col' style={{width: 40, maxWidth: 40, marginTop: 37}}>
                                                        <div style={{
                                                            height: '30px',
                                                            width: '30px',
                                                            borderRadius: '50%',
                                                            backgroundImage: 'url("' + (comment.user.photoMin ? comment.user.photoMin : '/images/avatar.png') + '")',
                                                            backgroundSize: (comment.user.photoMin ? 'cover' : 'contain'),
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundPositionX: 'center',
                                                            backgroundPositionY: 'center'
                                                        }}> </div>
                                                    </div>
                                                    <div className="col" style={{width: 200, maxWidth: 200, marginTop: 40}}>

                                                        <div className='comment-user-link'>
                                                            <Link to={'/user/'+comment.user.id}>{comment.user.name}</Link>
                                                        </div>
                                                    </div>
                                                    <div className="col" style={{width: 552, maxWidth: 552, wordWrap: 'break-word'}}>
                                                        <div className='store-header-title' style={{marginBottom: 18}}>
                                                            <span>{moment.utc(comment.createdDate, 'YYYY-MM-DD').local(false).format('DD.MM.YYYY').toString()}</span>
                                                        </div>
                                                        <div>
                                                            <span className='comment-text'>{comment.comment}</span>
                                                        </div>
                                                        <div style={{
                                                            height: '1px',
                                                            width: '552px',
                                                            borderRadius: '1px',
                                                            backgroundColor: '#CFD6D8',
                                                            marginTop: '26px',
                                                            display: 'block'
                                                        }}>

                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        )
                                    })
                                    : ''
                            }

                                {
                                    (this.state.comments && this.state.comments.totalElements > 4) ?
                                        <div className='d-flex justify-content-center' style={{marginTop: 40}}>
                                            <button onClick={this.loadAllComments} className='button-link'>Показать все</button>
                                        </div>
                                        : ''
                                }

                            </div>
                            <div style={{
                                height: '1px',
                                width: '1010px',
                                borderRadius: '1px',
                                backgroundColor: '#CFD6D8',
                                marginTop: '52px',
                                display: 'block'
                            }}>

                            </div>

                            <div id="product-page-other-h">
                                <span>Другие товары этого продавца </span> <Link
                                to={'/store/' + this.state.product.store.id}>{this.state.product.store.name}</Link>
                            </div>
                            <div id="product-page-other">
                                {
                                    this.state.otherProduct.map((pr) => {
                                        return (
                                            <div key={pr.id} style={{display: 'inline-block', marginRight: '20px'}}>
                                                <Link to={"/product/" + pr.id} className='link-block'>
                                                    <div className="main-card-goods-image"
                                                         style={{
                                                             backgroundImage: 'url("' +
                                                                 (pr.photos.length > 0 ? pr.photos.filter((ph) => {
                                                                     return ph.orderPlace === 1
                                                                 })[0].photoMin : '')
                                                                 + '")',
                                                             backgroundSize: 'cover',
                                                             backgroundRepeat: 'no-repeat',
                                                             backgroundPositionX: 'center',
                                                             backgroundPositionY: 'center'
                                                         }}
                                                    >

                                                    </div>
                                                    <div className="main-card-goods-desc">
                                                        <div className="main-card-goods-name">
                                                            <Link className="main-card-goods-name-text"
                                                                  to={"/product/" + pr.id}>{pr.name}</Link>
                                                        </div>
                                                        <div className="main-card-goods-store">
                                                            <Link to={'/store/' + pr.store.id}
                                                                  className="main-card-goods-store-link">{pr.store.name}</Link>
                                                        </div>
                                                        <div className="main-card-goods-cost">
                                                            {
                                                                pr.oldCost ?
                                                                    <span
                                                                        className="main-card-goods-cost crossed-red-cost" style={{fontSize: 11}} >{pr.oldCost ? pr.oldCost.cost : 0} {pr.oldCost ? pr.oldCost.currency : 'BYN '}  </span>
                                                                    : ''
                                                            }
                                                            <span className="main-card-goods-cost">{(pr.cost != null
                                                                && pr.cost.cost != null)
                                                                ? pr.cost.cost : 0} BYN</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })
                                }


                            </div>

                        </div>
                    </div> : ''
                }
                <div>
                    <Dialog
                        open={this.state.messageIsOpen}
                        // TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Добавить отзыв
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                {
                                    this.state.uploadingInProgress ?
                                        <div >
                                            <LinearProgress variant="determinate" value={this.state.progress}/>
                                        </div>
                                        : ''
                                }
                                <textarea
                                    autoFocus={true}
                                    className='textarea-element'
                                    value={this.state.comment}
                                    onChange={this.commentOnChange}
                                    placeholder={'Введите комментарий'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px'
                                    }} />

                            </div>
                            <CircularProgress
                                style={{display: this.state.commentInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.commentInProgress} onClick={this.sendComment}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.reportOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Пожаловаться на товар
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                <div  className='d-flex justify-content-center'>
                                <CircularProgress
                                    style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                    color="secondary"/>
                                </div>
                                <textarea
                                    className='textarea-element'
                                    value={this.state.message}
                                    onChange={this.onChangeReport}
                                    placeholder={'Введите сообщение'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px',
                                        height: '150px'
                                    }} />

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <input ref={this.fileInput} style={{display: 'none'}} type="file" onChange={this.fileOnChange}/>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgress} onClick={this.sendReport}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <ModalGateway>
                    {
                        this.state.photoIsOpen ?
                            <Modal onClose={this.toggleViewer}>
                                <Carousel currentIndex={this.state.current}  views={this.state.photoArray} />
                            </Modal>
                            : ''
                    }
                </ModalGateway>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        basket: state.basket,
        notification: state.notification,
        messages: state.messages
    };
};

export default connect(mapStateToProps)(ProductPage);
import React from 'react';
import {connect, Provider} from "react-redux";
import {getStore} from "../../api/StoreAPI";
import moment from 'moment';
import {getFilteredGoods} from "../../api/GoodsAPI";
import {Link, Redirect} from "react-router-dom";
import {getAdminStoreById} from "../../api/AdminStoreAPI";
import {reportStore} from "../../api/ReportAPI";
import {sendNotification} from "../../actions/notifications";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {setUserPage} from "../../actions/messages";
import Carousel, {Modal, ModalGateway} from "react-images";
import LinearProgress from "@material-ui/core/LinearProgress";
import {deleteMessageFile, sendMessageToUser, uploadMessageFile} from "../../api/MessageAPI";
import {Helmet} from "react-helmet";
import {logSocial} from "../../api/LogAPI";
import * as VanillaSharing from 'vanilla-sharing';

class StorePage extends React.Component {

    constructor(props) {
        super(props);
        moment.locale('ru');
        this.state = {
            storeId: this.props.props ? this.props.props.match.params.storeId : this.props.match.params.storeId,
            store: {
                name: '',
                owner: {
                    name: '',
                    photoMid: ''
                },
                createdDate: moment(),
                city: ''
            },
            filter: {
                page: 0,
                size: 1000,
                storeId: this.props.props ? this.props.props.match.params.storeId : this.props.match.params.storeId
            },
            products: {},
            redirect: false,
            message: '',
            reportOpen: false,
            sendingInProgress: false,
            photoIsOpen: false,
            progress: 0,
            files: [],
            messageIsOpen: false,

        };
        this.fileInput = new React.createRef();

    }

    toggleViewer = () => {
        this.setState(() => ({photoIsOpen: !this.state.photoIsOpen}));
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        this.loadStore();
    }

    componentWillUnmount() {
        if (!this.props.messages.isOpen)
            this.props.dispatch(setUserPage(null));
    }

    loadProduct() {
        getFilteredGoods(this.state.filter).then(
            response => {
                this.setState(() => ({products: JSON.parse(response)}));
            },
            error => {
                console.log(error);
            }
        );
    };


    loadStore() {
        const loader = this.props.viewer === 'user' ? getStore : this.props.viewer === 'admin' ? getAdminStoreById : getStore;
        loader(this.props.props ? this.props.props.match.params.storeId : this.props.match.params.storeId).then(
            response => {
                this.setState(() => ({
                    store: JSON.parse(response)
                }), () => {
                    this.props.dispatch(setUserPage(this.state.store.owner));
                });
                this.loadProduct();
            },
            error => {
                this.setState(() => ({redirect: true}));
                let err = JSON.parse(error.message);
                if (err.code === 61) {
                    this.setState(() => ({redirect: true}));
                }
            }
        );
    }
    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };

    sendOnClose = () => {
        this.setState({reportOpen: false, messageIsOpen: false});
    };

    reportOnOpen = () => {
        this.setState({reportOpen: true});
    };

    removeMessageFile = (fileId) => {
        let state = {...this.state};
        state.files = state.files.filter(file => file.fileId !== fileId);
        this.setState({...state});

        // deleteMessageFile(fileId).then(
        //     response => {
        //         let state = {...this.state};
        //         state.files = state.files.filter(file => file.fileId !== fileId);
        //         this.setState({...state});
        //     },
        //     error => {
        //         console.log(error);
        //     }
        // );
    };

    onProgress = (event) => {

        if (event.lengthComputable) {
            let complete = (event.loaded / event.total * 100 | 0);
            this.setState({progress: complete});
        }
    };

    sendMessage = () => {
        if (this.state.message.trim().length === 0 && this.state.files.length === 0){
            this.props.dispatch(sendNotification({
                open: true,
                messageInfo: {
                    message: 'Сообщение пустое',
                    key: new Date().getTime(),
                    variant: 'error'
                }
            }));
            return;
        }
        sendMessageToUser({message: this.state.message, userId: this.props.userPage.id, fileIds: this.state.files.map(file => file.fileId)}).then(
            response => {
                this.setState({messageIsOpen: false, message: '', files: []});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Сообщение отправлено',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({messageIsOpen: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Сообщение не было доставлено',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };


    openMessage = () => {
        if (!this.props.user.name) {
            this.setState({redirectLogin: true});
            return;
        }
        this.setState({messageIsOpen: true});
    };

    onChangeMessage = (ev) => {
        this.setState({message: ev.target.value});
    };

    fileOnChange = (ev) => {
        if (ev.target.files[0] == null){
            return;
        }
        ev.persist();
        if ((ev.target.files[0].size / 1000 / 1000) > 100){

            this.props.dispatch(sendNotification({
                open: true,
                messageInfo: {
                    message: 'Файл слишком большой',
                    key: new Date().getTime(),
                    variant: 'error'
                }
            }));
            ev.target.value = "";
            return;
        }
        let formData = new FormData();
        formData.append('file', ev.target.files[0]);
        this.setState({uploadingInProgress: true});
        uploadMessageFile(formData, this.onProgress).then(
            response => {
                let file = JSON.parse(response);
                let state = {...this.state};
                state.files.push(file);
                this.setState({...state});
                ev.target.value = "";
                this.setState({uploadingInProgress: false, progress: 0});
            },
            error => {
                console.log(error);
            }
        );
    };


    sendReport = () => {
        this.setState({sendingInProgress: true});
        reportStore(this.props.match.params.storeId, this.state.message).then(
            response => {
                this.setState({message: '', reportOpen: false, sendingInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Жалоба отправлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({message: '', reportOpen: false, sendingInProgress: false});
            }
        );
    };

    onChangeReport = (ev) => {

        let message = ev.target.value;
        this.setState({message});
    };


    logSocialShared = (type) => {
        const userId = this.props.user.name ? this.props.user.id : null;
        logSocial({page: 'https://crafty.by/store/' + this.state.store.id, userId: userId, type: type});
    };



    sharePinterest= () => {
        VanillaSharing.pinterest({
            url: ('https://crafty.by/store/' + this.state.store.id),
            description: (this.state.store.name + '. ' + this.state.store.description),
            media: this.state.store.photoMax
        });
        this.logSocialShared('PINTEREST');

    };

    shareFacebook = () => {
        VanillaSharing.fbButton({
            url: ('https://crafty.by/store/' + this.state.store.id)
        });
        this.logSocialShared('FACEBOOK');

    };


    shareVk = () => {
        VanillaSharing.vk({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
            description: this.state.store.description,
            image: this.state.store.photoMax,
            isVkParse: true,
        });
        this.logSocialShared('VK');

    };

    shareOk = () => {
        VanillaSharing.ok({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
            image: this.state.store.photoMax
        });
        this.logSocialShared('OK');

    };

    shareViber = () => {
        VanillaSharing.viber({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
        });
        this.logSocialShared('VIBER');

    };


    shareTelegram = () => {
        VanillaSharing.telegram({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
        });
        this.logSocialShared('TELEGRAM');

    };

    shareWhatsApp = () => {
        VanillaSharing.whatsapp({
            url: ('https://crafty.by/store/' + this.state.store.id),
            title: this.state.store.name,
        });
        this.logSocialShared('WHATSAPP');
    };

    render() {
        return (
            <div>
                {this.state.redirect ? <Redirect to={{pathname: '/', state: {status: 404}}}/> : ''}
                <Helmet>
                    <title>{this.state.store.name ? ('Магазин мастера ' + this.state.store.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + ' (' + this.state.store.owner.name + ') | ' + this.state.store.city ) : ''}</title>
                    <meta name="description" content={this.state.store.description ? (this.state.store.description.substring(0, 150).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + '...') : ''} />
                    <meta property="og:description" content={this.state.store.description ? (this.state.store.description.substring(0, 150).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + '...') : ''} />
                    <meta name="Description" content={this.state.store.description ? (this.state.store.description.substring(0, 150).replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + '...') : ''} />
                    <meta property="og:title" content={(this.state.store.name ? ('Магазин мастера ' + this.state.store.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + ' (' + this.state.store.owner.name + ') | ' + this.state.store.city ) : '')} />
                    <meta property="og:image" content={(this.state.store.photoMid)} />
                    <link rel="canonical" href={'https://crafty.by/store/'+this.state.storeId} />
                    <meta name='keywords' content={this.state.store.name.replace(/[^0-9a-zA-ZА-Яа-яЁё .,?!:;_]/gi, ' ') + ', купить, заказать, цена, в интернет магазине, ' + this.state.store.city} />
                    <meta property="og:url" content={'https://crafty.by/store/'+this.state.storeId}/>

                </Helmet>
                <div id="path-category">
                    <span>Главная  / {this.state.store.name}  </span>
                </div>
                <div className="row store-box" style={{minWidth: '1000px'}}>
                    <div className="col-3">
                        <a href="javascript:void(0)">
                            <div className="store-page-photo"
                                 onClick={() => {
                                     if (this.state.store.photoMax){
                                         this.toggleViewer();
                                     }
                                 }}
                                 style={{
                                     backgroundImage: 'url("' + this.state.store.photoMid + '")',
                                     backgroundSize: 'contain',
                                     backgroundRepeat: 'no-repeat',
                                     backgroundPositionX: 'center',
                                     backgroundPositionY: 'center'

                                 }}
                            > </div>
                        </a>
                    </div>
                    <div className="col-5">
                        <div className="store-page-name">
                            <h1 className='store-page-name'>{this.state.store.name}</h1>
                        </div>
                        <div style={{marginTop: '48px'}} className="main-card-store-pin">
                            <div className="main-card-store-pin-icon"></div>
                            <span
                                className="main-card-store-location">{this.state.store.city} | {moment(this.state.store.createdDate).fromNow(true)} на Crafty</span>
                        </div>
                        {/*<div className="store-info-text" style={{marginLeft: '18px', marginTop: '20px'}}>*/}
                        {/*    <span>Телефон: </span>{!!this.props.user.name*/}
                        {/*    ? <span>{this.state.store.owner.phone}</span>*/}
                        {/*    : <Link to={'/login?redirectURL=' + this.props.history.location.pathname}>Войдите, чтобы посмотреть</Link>*/}
                        {/*    }*/}
                        {/*</div>*/}

                        {/*<div className="store-info-text" style={{marginLeft: '18px', marginTop: '20px'}}>*/}
                        {/*    <span>Email: </span>{!!this.props.user.email*/}
                        {/*    ? <span>{this.state.store.owner.email}</span>*/}
                        {/*    : <Link to={'/login?redirectURL=' + this.props.history.location.pathname}>Войдите, чтобы посмотреть</Link>*/}
                        {/*}*/}
                        {/*</div>*/}
                        <div>
                            <button className='save-btn-40'
                                    onClick={this.openMessage}
                                    style={{
                                        marginTop: '10px',
                                        marginLeft: '5px'
                                    }}>Написать</button>
                        </div>

                    </div>
                    <div className="col-4">
                        <div className="store-header-title">
                            <span>Владелец</span>
                        </div>
                        <div className="row" style={{marginTop: '6px'}}>
                            <div className="store-page-owner-image"
                                 style={{
                                     backgroundImage: 'url("' + (this.state.store.owner.photoMid ? this.state.store.owner.photoMid : '/images/avatar.png') + '")',
                                     backgroundSize: 'contain',
                                     backgroundRepeat: 'no-repeat',
                                     backgroundPositionX: 'center',
                                     backgroundPositionY: 'center'
                                 }}
                            ></div>
                            <Link to={'/user/'+this.state.store.owner.id} className="name-owner">{this.state.store.owner.name}</Link>
                        </div>

                    </div>
                    <div style={{marginTop: '20px', marginLeft: '15px'}}>
                        <img style={{width: '21px', height: '21px', display: 'inline', marginRight: '10px', marginBottom: '2px'}} src="/images/attention.png" alt=""/><a onClick={this.reportOnOpen} className='report-link' href="javascript:void(0)">Пожаловаться</a>
                    </div>

                    <div style={{width: 1010, marginTop: 20}} className='d-flex justify-content-start'>
                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none", paddingLeft: 16}} onClick={this.sharePinterest}>
                            <img style={{width: 30, height: 30}} src="/images/pinterestshare.svg" alt="Поделиться в Pinterest"/>
                        </button>
                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareFacebook}>
                            <img style={{width: 30, height: 30}} src="/images/fbshare.svg" alt="Поделиться в Facebook"/>
                        </button>
                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareVk}>
                            <img style={{width: 30, height: 30}} src="/images/vkshare.svg" alt="Поделиться во Вконтакте"/>
                        </button>
                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareOk}>
                            <img style={{width: 30, height: 30}} src="/images/okshare.svg" alt="Поделиться в Одноклассниках"/>
                        </button>
                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareTelegram}>
                            <img style={{width: 30, height: 30}} src="/images/telegramshare.svg" alt="Поделиться в Telegram"/>
                        </button>
                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareWhatsApp}>
                            <img style={{width: 30, height: 30}} src="/images/whatsappshare.svg" alt="Поделиться в WhatsApp"/>
                        </button>
                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareViber}>
                            <img style={{width: 30, height: 30}} src="/images/vibershare.svg" alt="Поделиться в Viber"/>
                        </button>
                    </div>

                    <div style={{
                        height: '1px',
                        width: '960px',
                        borderRadius: '1px',
                        backgroundColor: '#CFD6D8',
                        marginTop: '30px',
                        marginLeft: '15px',
                        display: 'block'
                    }}>

                    </div>

                </div>
                <div style={{marginLeft: '161px', marginTop: '33px'}}>
                    <div className="store-header-product">
                        <span>Описание</span>
                    </div>
                    <div style={{marginTop: '20px', maxWidth: '960px'}}>
                        <p className="user-page-description-text">{this.state.store.description}</p>
                    </div>
                    <div style={{
                        height: '1px',
                        width: '960px',
                        borderRadius: '1px',
                        backgroundColor: '#CFD6D8',
                        marginTop: '52px',
                        display: 'block'
                    }}>

                    </div>
                </div>
                <div style={{marginLeft: '161px', marginTop: '33px'}}>
                    <div className="store-header-product">
                        <span>Товары</span>
                    </div>
                    <div style={{marginTop: '20px', marginLeft: '-10px'}}>
                        {
                            this.state.products.content
                                ? this.state.products.content.map((product) => {
                                    return (
                                        <div key={product.id} className="category-product-card">
                                            <Link to={'/product/' + product.id} className='link-block'>
                                                <div className="search-card-goods-image"
                                                     style={{
                                                         backgroundImage: 'url("' + product.photos.filter((ph) => {
                                                             return ph.orderPlace === 1
                                                         })[0].photoMin + '")',
                                                         backgroundSize: 'cover',
                                                         backgroundRepeat: 'no-repeat',
                                                         backgroundPositionX: 'center',
                                                         backgroundPositionY: 'center',
                                                         position: 'relative'
                                                     }}
                                                >
                                                    {
                                                        product.isAvailable
                                                            ?
                                                            <div style={
                                                                {
                                                                    width: '83px',
                                                                    height: '25px',
                                                                    backgroundColor: '#79CA1F',
                                                                    display: 'block',
                                                                    position: 'absolute',
                                                                    bottom: '0',
                                                                    borderRadius: '0 5px 0 0'
                                                                }
                                                            }><span style={
                                                                {
                                                                    color: '#FFFFFF',
                                                                    fontFamily: 'Open Sans',
                                                                    fontSize: '12px',
                                                                    lineHeight: '20px',
                                                                    marginLeft: '10px'
                                                                }
                                                            }>В наличии</span> </div>
                                                            :
                                                            ''
                                                    }

                                                </div>
                                                <div className="main-card-goods-desc">
                                                    <div className="main-card-goods-name">
                                                        <Link className="main-card-goods-name-text"
                                                              to={'/product/' + product.id}>{product.name}</Link>
                                                    </div>
                                                    <div className="main-card-goods-store">
                                                        <Link to={'/store/' + product.store.id}
                                                              className="main-card-goods-store-link">{product.store.name}</Link>
                                                    </div>
                                                    <div className="main-card-goods-cost">
                                                        {
                                                            product.oldCost ?
                                                                <span
                                                                    className="main-card-goods-cost crossed-red-cost" style={{fontSize: 11}} >{product.oldCost ? product.oldCost.cost : 0} {product.oldCost ? product.oldCost.currency : 'BYN '}  </span>
                                                                : ''
                                                        }
                                                            <span
                                                                className="main-card-goods-cost">{product.cost ? product.cost.cost : 0} BYN</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })
                                : ''
                        }
                    </div>
                </div>
                <div>
                    <Dialog
                        open={this.state.reportOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Пожаловаться на магазин
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                <div  className='d-flex justify-content-center'>
                                    <CircularProgress
                                        style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                        color="secondary"/>
                                </div>
                                <textarea
                                    className='textarea-element'
                                    value={this.state.message}
                                    onChange={this.onChangeReport}
                                    placeholder={'Введите сообщение'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px',
                                        height: '150px'
                                    }} />

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgress} onClick={this.sendReport}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.messageIsOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Сообщение
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                {
                                    this.state.files.length > 0 ?
                                        this.state.files.map(file => {
                                            return (
                                                <div key={file.fileId}>
                                                    <div style={{display: 'inline-flex', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '284px', wordBreak: 'break-all'}}>
                                                        <a href={file.link} target='_blank'><span style={{
                                                            maxWidth: '250px',
                                                            overflow: 'hide'
                                                        }}>{file.name}</span></a>
                                                    </div><a style={{marginLeft: '10px', color: 'black'}} href="javascript:void(0)" onClick={() => {
                                                    this.removeMessageFile(file.fileId);
                                                }}>X</a>
                                                </div>
                                            );

                                        })
                                        : ''
                                }
                                {
                                    this.state.uploadingInProgress ?
                                        <div >
                                            <LinearProgress variant="determinate" value={this.state.progress}/>
                                        </div>
                                        : ''
                                }
                                <textarea
                                    className='textarea-element'
                                    value={this.state.message}
                                    onChange={this.onChangeMessage}
                                    placeholder={'Введите сообщение'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px'
                                    }} />

                            </div>
                            <CircularProgress
                                style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <input ref={this.fileInput} style={{display: 'none'}} type="file" onChange={this.fileOnChange}/>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' style={{float: 'right', width: '40px', marginRight: '5px'}} onClick={() => {
                                this.fileInput.current.click();
                            }}>+</button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgress} onClick={this.sendMessage}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <ModalGateway>
                    {
                        this.state.photoIsOpen ?
                            <Modal onClose={this.toggleViewer}>
                                <Carousel views={[{source: this.state.store.photoMax}]} />
                            </Modal>
                            : ''
                    }
                </ModalGateway>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages,
        userPage: state.messages.userPage

    };
};

export default connect(mapStateToProps)(StorePage);
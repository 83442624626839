import React from 'react';
import {connect} from "react-redux";
import {selectByAlias} from "../selectors/category";
import {getFilteredGoods} from "../api/GoodsAPI";
import {Link} from "react-router-dom";
import {refreshSession} from "../actions/authorization";
import {setChildCategory, setMainCategory} from "../actions/settings";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import TextField from "@material-ui/core/TextField/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Pagination from "material-ui-flat-pagination";
import * as ReactGA from "react-ga";
import {getFilteredCategories} from "../api/DictionaryAPI";
import * as queryString from "query-string";
import SearchPageSearchBlock from "./SearchPageSearchBlock";


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    textSecondary: {
        opacity: '0.5',
        color: '#283149',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '19px'
    },
    textPrimary: {
        color: '#4A90E2', fontFamily: 'Open Sans', fontSize: '14px', lineHeight: '19px'
    }
});

const ordering = [
    {
        value: 'createdDate,desc',
        label: 'Сначала новые',
    },
    {
        value: 'createdDate,asc',
        label: 'Сначала старые',
    },
    {
        value: 'cost,desc',
        label: 'Сначала дорогие',
    },
    {
        value: 'cost,asc',
        label: 'Сначала дешевые',
    }
];

class SearchPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentCategory: {},
            availableCategories: [],
            filter: {
                page: 0,
                size: 48,
                category: props.match.params.alias,
                costFrom: props.dict.limits.min,
                costTo: props.dict.limits.max,
                color: '',
                name: '',
                sort: 'createdDate,desc'

            },
            product: {
                content: []
            },
            value: {
                min: props.dict.limits.min,
                max: props.dict.limits.max
            },
            loading: true,
            offset: 0,
            limit: 1,
            total: 1
        }
    }

    onBackButtonEvent = ( ev ) => {
        if(ev.state && typeof ev.state.page !== 'undefined' ) {

            let filter = {...this.state.filter};
            filter.page = ev.state.page;
            filter.size = ev.state.size;
            filter.category = ev.state.category;
            filter.costFrom = ev.state.costFrom;
            filter.costTo = ev.state.costTo;
            filter.color = ev.state.color;
            filter.name = ev.state.name;
            filter.sort = ev.state.sort;

            this.setState({filter: filter, offset: (filter.page * filter.size)}, () => {
                this.searchProduct();
            });

        }
    };



    componentDidMount() {

        this.searchProduct();

        if (typeof window !== 'undefined'){
            window.scrollTo(0, 0);
            window.addEventListener('popstate', this.onBackButtonEvent);

        }

        ReactGA.event({
            category: 'Search',
            action: 'search'
        });
    }

    componentWillUnmount() {
        this.props.dispatch(setMainCategory(''));
        this.props.dispatch(setChildCategory(''));
        if (window) {
            window.removeEventListener('popstate', this.onBackButtonEvent);
        }

    }


    componentWillMount() {
        const parsed = queryString.parse(location.search);
        let filter = {...this.state.filter};
        filter.name = parsed.q;
        filter.size = parsed.size ? parsed.size : 48;
        filter.page = parsed.page ? parsed.page : 0;
        filter.sort = parsed.sort ? parsed.sort : filter.sort;
        history.pushState(filter, 'Поиск', '/search?' + queryString.stringify(filter));
        this.setState({filter: filter, offset: (filter.page * filter.size)});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.alias !== this.props.match.params.alias || prevProps.match.params.child !== this.props.match.params.child) {
            const category = selectByAlias(this.props.dict.categories, this.props.match.params.alias);
            getFilteredCategories(this.props.match.params.alias).then(
                response => {
                    let availableCategories = JSON.parse(response);
                    this.setState({availableCategories});
                },
                error => {
                    console.log(error);
                }
            );
            let filter = this.state.filter;
            filter.page = 0;
            this.setState(() => ({
                currentCategory: category,
                filter: {...filter, category: this.props.match.params.alias},
                offset: 0,
                limit: 1,
                total: 1
            }));
            this.props.dispatch(setMainCategory(this.props.match.params.alias));
            this.props.dispatch(setChildCategory(this.props.match.params.child));

            this.searchProduct();

            ReactGA.event({
                category: 'Categories',
                action: 'Change category'
            });
            ReactGA.pageview(this.props.history.location.pathname);
        }
    }

    searchProduct = () => {
        this.setState(()=>({loading: true}));
        let cat = typeof this.props.match.params.child === 'string' ? this.props.match.params.child : this.props.match.params.alias;
        getFilteredGoods({...this.state.filter, category: cat}).then(
            response => {
                let resp  = JSON.parse(response);
                    this.setState(() => ({
                        product: resp,
                        limit: resp.size,
                        total: resp.totalElements

                }));
                this.setState(()=>({loading: false}));
            },
            error => {
                this.setState(()=>({loading: false}));
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403) {
                    this.props.dispatch(refreshSession()).then(() => {
                        this.searchProduct();
                    });
                }
            }
        );
    };

    hexToRGB = (hex) => {
        const RGB_HEX = /^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i;

        const [, short, long] = String(hex).match(RGB_HEX) || [];

        if (long) {
            const value = Number.parseInt(long, 16);
            return [value >> 16, value >> 8 & 0xFF, value & 0xFF];
        } else if (short) {
            return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
        }

    };

    onSearchChange = (ev) => {
        let filter = {...this.state.filter};
        filter.name = ev.target.value;
        const params = queryString.parse(location.search);
        params.q = filter.name;
        params.page = filter.page;
        params.size = filter.size;
        history.pushState(params, 'Поиск', '/search?' + queryString.stringify(params));
        // location.search =  queryString.stringify(params);
        this.setState({filter: filter}, () => {
            this.searchProduct();
        });
    };

    onOrderChange = (ev) => {
        let filter = this.state.filter;
        filter.sort = ev.target.value;
        this.setState({filter: filter}, () => {
            const params = queryString.parse(window.location.search);
            params.q = filter.name;
            params.page = filter.page;
            params.size = filter.size;
            params.sort = filter.sort;
            window.history.pushState(params, 'Поиск', '?' + queryString.stringify(params));

            this.searchProduct();
        });
    };

    changePage = (e, offset, page) => {
        let filter = this.state.filter;
        filter.page = page - 1;
        this.setState(() => ({filter: filter, offset: offset}), () => {
            const params = queryString.parse(location.search);
            params.q = filter.name;
            params.page = filter.page;
            params.size = filter.size;
            params.sort = filter.sort;

            history.pushState(params, 'Поиск', '/search?' + queryString.stringify(params));
        });
        if (typeof window !== 'undefined'){
            window.scrollTo(0, 0);
        }
        this.searchProduct();
    };

    render() {

        const { classes, theme } = this.props;

        return (
            <div>

                <div id="path-category">
                    <span>Главная  / Поиск  </span>
                </div>
                <div className="row catalog-box">
                    <div className="col">
                        <div className="row">
                    <div className="col-lg-12">
                        <SearchPageSearchBlock onChange={this.onSearchChange} searchString={this.state.filter.name}/>

                        <div style={{marginTop: '15px', marginRight: '50px'}} className="category-sort d-flex justify-content-end">
                            <TextField
                                id="filled-select-currency"
                                select
                                className={classes.textField}
                                value={this.state.filter.sort}
                                onChange={this.onOrderChange}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.textField
                                }}
                                margin="normal"
                            >
                                {ordering.map(option => (
                                    <MenuItem style={{color: '#5B6273',
                                        fontFamily: "Open Sans",
                                        fontSize: '12px',
                                        lineHeight: '17px'}} key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                        </div>
                        <div className="category-product-box" style={{marginTop: '15px'}}>


                            {   this.state.loading ? <CircularProgress color="secondary" style={{
                                marginLeft: '380px'
                                }} /> :
                                this.state.product.content.length > 0 ?
                                this.state.product.content.map((product) => {
                                    return (
                                        <div key={product.id} className="category-product-card">
                                            <Link className='link-block' to={'/product/' + product.id}>
                                            <div className="search-card-goods-image"
                                                 style={{
                                                     backgroundImage: 'url("' + product.photos.filter((ph) => {
                                                         return ph.orderPlace === 1
                                                     })[0].photoMin + '")',
                                                     backgroundSize: 'cover',
                                                     backgroundRepeat: 'no-repeat',
                                                     backgroundPositionX: 'center',
                                                     backgroundPositionY: 'center',
                                                     position: 'relative'
                                                 }}
                                            >
                                                {/*<button onClick={() => {*/}

                                                {/*    const url = 'https://crafty.by/product/'+product.id;*/}
                                                {/*    const title = product.name;*/}
                                                {/*    const description = product.description;*/}
                                                {/*    const media = product.photos.filter((ph) => {*/}
                                                {/*        return ph.orderPlace === 1*/}
                                                {/*    })[0].photoMax;*/}

                                                {/*    this.shareBtn(url, description, title, media);*/}

                                                {/*}}>Share</button>*/}
                                                {
                                                    product.isAvailable
                                                        ?
                                                        <div style={
                                                            {
                                                                width: '83px',
                                                                height: '25px',
                                                                backgroundColor: '#79CA1F',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                bottom: '0',
                                                                borderRadius: '0 5px 0 0'
                                                            }
                                                        }><span style={
                                                            {
                                                                color: '#FFFFFF',
                                                                fontFamily: 'Open Sans',
                                                                fontSize: '12px',
                                                                lineHeight: '20px',
                                                                marginLeft: '10px'
                                                            }
                                                        }>В наличии</span> </div>
                                                        :
                                                        ''
                                                }

                                            </div>
                                            <div className="main-card-goods-desc">
                                                <div className="main-card-goods-name">
                                                    <Link className="main-card-goods-name-text"
                                                          to={'/product/' + product.id}><h3 className='goods-h3'>{product.name}</h3></Link>
                                                </div>
                                                <div className="main-card-goods-store">
                                                    <Link to={'/store/'+product.store.id}
                                                       className="main-card-goods-store-link"><h3 className='store-h3'>{product.store.name}</h3></Link>
                                                </div>
                                                <div className="main-card-goods-cost">
                                                    {
                                                        product.oldCost ?
                                                            <span
                                                                className="main-card-goods-cost crossed-red-cost" style={{fontSize: 11}} >{product.oldCost ? product.oldCost.cost : 0} {product.oldCost ? product.oldCost.currency : 'BYN '}  </span>
                                                            : ''
                                                    }
                                                    <span
                                                          className="main-card-goods-cost">{product.cost.cost} BYN</span>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    );
                                })
                                :
                                    <div className='d-flex justify-content-center'>
                                        <span>Ничего не найдено</span>
                                    </div>
                            }


                        </div>
                        <div className={'d-flex justify-content-center'}>
                                <Pagination
                                    limit={this.state.product.size}
                                    offset={this.state.offset}
                                    total={this.state.product.totalElements}
                                    currentPageColor={'primary'}
                                    otherPageColor={'secondary'}
                                    classes={{textSecondary: classes.textSecondary, textPrimary: classes.textPrimary}}
                                    onClick={(e, offset, page) => this.changePage(e, offset, page)}
                                />
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

SearchPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(SearchPage));
import React from 'react';
import { Link } from 'react-router-dom';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../styles/custom.css';

const CategorySearchBlock = (props) => (
  <div>
      <div id="search-block"  style={{width: '705px', marginLeft: '5px'}}>
          <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                  <div className="search-input-mid input-group ">
                      <div className="input-group-prepend">
                          <span className="input-group-text" style={{background: '#FFF', border: 0}}
                                id="inputGroup-sizing-lg"><img src="/images/search.png" /></span>
                      </div>
                      <input onChange={props.onChange} id="search-main" type="text" className="form-control" placeholder="Поиск" />
                  </div>
              </div>
          </div>
      </div>
  </div>
);

export default CategorySearchBlock;

import React from 'react';
import {forgotPassword, login} from '../api/AuthorizationApi';
import {connect} from "react-redux";
import {loadUser} from "../actions/user";
import {Link, Redirect} from "react-router-dom";
import {sendNotification} from "../actions/notifications";

class ForgotPassword extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            success: false,
            submitted: false,
            redirect: false
        }
    }

    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState(() => ({ email }));
    };

    onSubmit = (e) => {
        e.preventDefault();
        forgotPassword(this.state.email).then(
            response => {
                this.setState({success: true, submitted: true, email: ''});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Данные по восстановлению пароля отправлены на почту.',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({success: false, submitted: true});
                this.refs.emailError.style = {display: 'flex'};
                this.refs.emailError.className = "d-flex justify-content-center error-message";
            }
        );
    };

    onClose = () => {
        this.setState({redirect: true});
    };

    render() {
        return (
            <div>
                {this.state.redirect ? <Redirect to={"/"} /> : ''}
                <div className="row" style={{marginTop: '40px'}}>
                    <div className="col-11 col-sm-1 col-md-11 col-lg-11">
                        <div style={{marginLeft: '95px'}} className="d-flex justify-content-center">
                            <img src="/images/logo.png" width={87} height='48px'/>
                        </div>
                    </div>
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1" onClick={this.onClose}>
                        <div className="d-flex justify-content-end">
                            <img src="/images/close.svg" />
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop: '92px'}}>
                    <div className="col-lg-12 col-md-12">
                        <div className="d-flex justify-content-center">
                <span style={{
                	 // height: '40px',
                    // width: '81px',
                    color: '#283149',
                    fontFamily: 'Tinos,sans-serif',
                    fontSize: '30px',
                    lineHeight: '40px'}}>Восстановить пароль</span>
                        </div>
                    </div>
                </div>
                <div className="row" style={{marginTop: '20px'}}>
                    <div className="col-lg-12">
                        <div className="d-flex justify-content-center">
                <span style={{
                    opacity: '0.5',
                    color: '#283149',
                    fontFamily: 'Open Sans,sans-serif',
                    fontSize: '16px',
                    lineHeight: '22px'
                }}>{(this.state.submitted && this.state.success)
                    ? 'Инструкция по восстановлению пароля выслана на email'
                    : 'Введите Email для восстановления пароля'
                }</span>
                        </div>
                    </div>
                </div>
                <form onSubmit={this.onSubmit}>
                <div className="row" style={{marginTop: '5%'}}>
                    <div className="col-lg-12">
                        <div className="d-flex justify-content-center">
                            <input type="email" className="email-field form-control" id="email-login"
                                   aria-describedby="emailHelp" placeholder="Email"
                                   onChange={this.onEmailChange}
                                   autoFocus
                                   value={this.state.email}
                            />
                        </div>
                    </div>
                </div>

                <div className="row" style={{marginTop: '30px'}}>
                    <div className="col-lg-12">
                        <span ref="emailError"
                              style={(this.state.submitted && !this.state.success) ? {display: 'block'} : {display: 'none'}}>Такой email не зарегистрирован</span>
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn-login btn btn-danger"><span
                                className="btn-login-label">Восстановить</span></button>
                        </div>
                    </div>
                </div>
                </form>


            </div>
        );
    };

}

export default connect()(ForgotPassword);
import React from 'react';
import {getGoodsById, getFilteredGoods, getMyGoodsById} from '../api/GoodsAPI';

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../styles/custom.css';
import {Link, Redirect} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {refreshSession} from "../actions/authorization";
import {getAdminGoodsById} from "../api/AdminGoodsAPI";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Carousel, {Modal, ModalGateway} from "react-images";
import {selectByAliasChild} from "../selectors/category";

export class AdminPreviewProductPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            user: {},
            product: {},
            photos: [],
            current: 0,
            otherProduct: [],
            redirect: false,
            redirectLogin: false,
            message: '',
            reportOpen: false,
            sendingInProgress: false,
            photoIsOpen: false,
            photoArray: [],
            commentFilter: {
                page: 0,
                size: 4,
                goodsId: this.props.match.params.id
            },
            comments: null,
            messageIsOpen: false,
            comment: '',
            commentInProgress: false,
            redirectPhone: false
        };
    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        this.updateProduct();

    }

    updateProduct = () => {
        let product;
        getAdminGoodsById(this.props.match.params.id).then(
            response => {
                product = JSON.parse(response);
                this.setState(()=>{
                    return {product: product};
                });
                this.loadOther(product.store.id);
                let photos = product.photos;
                photos.sort((a,b)=>{
                    if (a.orderPlace < b.orderPlace){
                        return -1;
                    }
                    if (a.orderPlace > b.orderPlace){
                        return 1;
                    }
                    return 0;
                });
                let photoArray = [];
                photos.forEach(photo => {
                    photoArray.push({source: photo.photoMax});
                });
                this.setState(() => ({photos: photos, photoArray: photoArray}));
            },
            error => {
                console.log(JSON.stringify(error.message));
                let resp = JSON.parse(error.message);
                if (error.code === 401 || error.code === 403){
                    this.props.dispatch(refreshSession()).then(() => {
                        this.updateProduct();
                    });
                }
                if (error.code === 404) {
                    this.props.history.goBack();
                }
            }
        );
    };

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id){
            this.updateProduct();
        }
    }


    toggleViewer = () => {
        this.setState(() => ({photoIsOpen: !this.state.photoIsOpen}));
    };

    loadOther = (id) => {
        let otherProduct;
        const filter = {
            page: 0,
            size: 4,
            storeId: id
        };
        getFilteredGoods(filter).then(
            response => {
                otherProduct = JSON.parse(response);
                this.setState(()=>({otherProduct: otherProduct.content}));
            },
            error => {
                console.log(error);
            }
        );
    };

    hexToRGB = (hex) => {
        const RGB_HEX = /^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i;

            const [ , short, long ] = String(hex).match(RGB_HEX) || [];

            if (long) {
                const value = Number.parseInt(long, 16);
                return [ value >> 16, value >> 8 & 0xFF, value & 0xFF ];
            } else if (short) {
                return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
            }

    };

    render() {

        const category = this.state.product.category ? selectByAliasChild(this.props.dict.categories, this.state.product.category.alias) : null;


        return (
            <div>
                {this.state.redirect ? <Redirect to={'/'}/> : ''}
                {this.state.redirectLogin ? <Redirect to={'/login?redirectURL=/product/'+this.state.product.id}/> : ''}
                {this.state.redirectPhone ? <Redirect to={'/cabinet/edit'}/> : ''}
                {this.state.product.name ?
                    <div>

                        {
                            category ?
                                <div className="path-category" style={{paddingTop: 44}}>
                                    <Link className='path-category' to={'/'}><span>Главная</span></Link>
                                    <span className='path-category'> / </span>
                                    <Link className='path-category' to={'/catalog/' + category.parent.alias}>{category ? category.parent.name : ''}</Link>
                                    <span className='path-category'> / </span>
                                    <Link className='path-category' to={'/catalog/' + category.parent.alias + '/' + category.alias}>{category ? category.name : ''}</Link>
                                </div>
                                : ''
                        }
                        {
                            (this.props.user.name && this.props.user.roles.filter((role) => (role.name === 'ROLE_ADMIN' || role.name === 'ROLE_MANAGER')).length > 1)
                                ?
                                <Link to={'/manage/product/edit/'+ this.state.product.id} >Редактировать</Link>
                                : ''

                        }

                        <div id="main-product-block">
                            <div className="row" style={{marginLeft: '0px!important'}}>
                                <div style={{display: 'inline-block'}}>
                                    <a href="javascript:void(0)">
                                        <div ref="picture" className="product-image-lg"
                                             onClick={() => {
                                                 this.toggleViewer();
                                             }}
                                             style={{
                                                 backgroundImage: 'url("'
                                                     + (this.state.product.photos.length > 0
                                                         ? this.state.product.photos.filter((ph) => {
                                                             return ph.orderPlace === 1
                                                         })[0].photoMid
                                                         : '') + '")',
                                                 backgroundSize: 'contain',
                                                 backgroundRepeat: 'no-repeat',
                                                 backgroundPosition: 'center'
                                             }}>

                                        </div>
                                    </a>
                                    <div id="product-page-gallery" style={{marginTop: '20px'}}>

                                        {
                                            this.state.photos.map((photo, idx) => {
                                                if (idx !== this.state.current)
                                                    return (
                                                        <div key={idx} className="product-image-sm " style={{
                                                            backgroundImage: 'url("' + photo.photoMin + '")',
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center'
                                                        }}

                                                             onClick={(e) => {
                                                                 this.setState(() => ({current: idx}));
                                                                 this.refs.picture.style.backgroundImage = 'url(' + this.state.photos[idx].photoMid + ')';
                                                             }}
                                                        >

                                                        </div>

                                                    );
                                            })
                                        }
                                    </div>


                                </div>
                                <div style={{
                                    display: 'inline-block',
                                    marginLeft: '51px',
                                    maxWidth: '450px',
                                    wordBreak: 'break-word'
                                }}>
                                    <div id="product-store-link">
                                        <Link
                                            to={'/store/' + this.state.product.store.id}>{this.state.product.store.name}</Link>
                                    </div>
                                    <div className="product-page-name">
                                        <h1 className='product-page-name'>{this.state.product.name}</h1>
                                    </div>
                                    {
                                        !!this.state.product.color ?
                                            <div id="product-page-color">
                                                <span>Цвет</span>
                                                <div id="product-page-color-circle">
                                                    <div className="color-circle" style={{
                                                        border: '1px solid black',
                                                        backgroundColor: this.state.product.color,
                                                        boxShadow: '1px 2px 4px 0 rgba(' + this.hexToRGB(this.state.product.color)[0] + ',' + this.hexToRGB(this.state.product.color)[1] + ',' + this.hexToRGB(this.state.product.color)[2] + ',0.19)'
                                                    }}>

                                                    </div>
                                                    {
                                                        this.state.product.color2 ?
                                                            <div className="color-circle" style={{
                                                                border: '1px solid black',
                                                                backgroundColor: this.state.product.color2,
                                                                boxShadow: '1px 2px 4px 0 rgba(' + this.hexToRGB(this.state.product.color2)[0] + ',' + this.hexToRGB(this.state.product.color2)[1] + ',' + this.hexToRGB(this.state.product.color2)[2] + ',0.19)'
                                                            }}>

                                                            </div>
                                                            : ''
                                                    }

                                                </div>
                                            </div>
                                            : <div></div>
                                    }
                                    {
                                        this.state.product.materials ?
                                            <div id="product-page-materials">
                                                <span>Материалы</span>
                                                <div style={{marginTop: '10px'}}>
                                                    <p>{this.state.product.materials}</p>
                                                </div>
                                            </div>
                                            : <div></div>
                                    }

                                    <div id="product-page-cost">
                                        <span>{(this.state.product.cost != null
                                            && this.state.product.cost.cost != null)
                                            ? this.state.product.cost.cost : 0} BYN</span>
                                    </div>
                                    <div style={{marginTop: '23px'}}>
                                        <div className='inline' style={{marginRight: '20px'}}>
                                            <button onClick={this.addOrderToBasket} className='basket-button'>
                                                {
                                                    this.props.user.name
                                                        ? (this.props.basket.items.filter(value => {
                                                            return value.item.id === this.state.product.id;
                                                        }).length > 0) ? 'Добавлено в корзину' : 'Добавить в корзину'
                                                        : 'Добавить в корзину'
                                                }
                                            </button>
                                        </div>
                                        <div className='inline'>
                                            <button onClick={this.state.product.booked ? this.removeBookmark : this.bookmark} className='bookmark-big-button'>
                                                {
                                                    this.state.product.booked
                                                        ?
                                                        <img style={{marginRight: '9px', paddingBottom: '2px'}} src="/images/heart-filled.png" />
                                                        :
                                                        <img style={{marginRight: '9px', paddingBottom: '2px'}} src="/images/heart.png" />
                                                }
                                                <span>{this.state.product.booked ? 'Сохранено' : 'Сохранить'}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{marginTop: '20px'}}>
                                        <img style={{width: '21px', height: '21px', display: 'inline', marginRight: '10px', marginBottom: '2px'}} src="/images/attention.png" alt=""/><a onClick={this.reportOnOpen} className='report-link' href="javascript:void(0)">Пожаловаться</a>
                                    </div>
                                    <div className="product-page-delimiter">

                                    </div>
                                    <div className="product-page-delivery">
                                        {
                                            this.state.product.store.deliveries.map((deliv, idx) => {
                                                return (
                                                    <p key={idx}>{deliv.deliveryType.name} : {deliv.city}.
                                                        Срок: {deliv.period}.
                                                        Стоимость: {deliv.cost ? (deliv.cost + ' ' + deliv.currency) : 'Бесплатно'} </p>
                                                );
                                            })
                                        }
                                    </div>

                                    {
                                        this.state.product.isAvailable
                                            ?
                                            <div>
                                                <div className="product-page-delimiter">

                                                </div>
                                                <div className="product-page-delivery">
                                                    <p>Товар в наличии</p>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className="product-page-delimiter">

                                                </div>
                                                <div className="product-page-delivery">
                                                    <p>Товар изготавливается под заказ</p>
                                                </div>
                                            </div>
                                    }




                                </div>

                            </div>

                            <div style={{
                                height: '1px',
                                width: '1010px',
                                borderRadius: '1px',
                                backgroundColor: '#CFD6D8',
                                marginTop: '52px',
                                display: 'block'
                            }}>

                            </div>

                            <div id="product-page-description-h">
                                <span>Описание</span>
                            </div>
                            <div id="product-page-description" style={{wordBreak: 'break-word'}}>
                                <span style={{whiteSpace: 'pre-wrap'}}>
                                    {this.state.product.description}
                                </span>
                            </div>

                            <div style={{
                                height: '1px',
                                width: '1010px',
                                borderRadius: '1px',
                                backgroundColor: '#CFD6D8',
                                marginTop: '52px',
                                display: 'block'
                            }}>

                            </div>
                            <div style={{
                                height: '1px',
                                width: '1010px',
                                borderRadius: '1px',
                                backgroundColor: '#CFD6D8',
                                marginTop: '52px',
                                display: 'block'
                            }}>

                            </div>

                            <div id="product-page-other-h">
                                <span>Другие товары этого продавца </span> <Link
                                to={'/store/' + this.state.product.store.id}>{this.state.product.store.name}</Link>
                            </div>
                            <div id="product-page-other">
                                {
                                    this.state.otherProduct.map((pr) => {
                                        return (
                                            <div key={pr.id} style={{display: 'inline-block', marginRight: '20px'}}>
                                                <Link to={"/product/" + pr.id} className='link-block'>
                                                    <div className="main-card-goods-image"
                                                         style={{
                                                             backgroundImage: 'url("' +
                                                                 (pr.photos.length > 0 ? pr.photos.filter((ph) => {
                                                                     return ph.orderPlace === 1
                                                                 })[0].photoMin : '')
                                                                 + '")',
                                                             backgroundSize: 'cover',
                                                             backgroundRepeat: 'no-repeat',
                                                             backgroundPositionX: 'center',
                                                             backgroundPositionY: 'center'
                                                         }}
                                                    >

                                                    </div>
                                                    <div className="main-card-goods-desc">
                                                        <div className="main-card-goods-name">
                                                            <Link className="main-card-goods-name-text"
                                                                  to={"/product/" + pr.id}>{pr.name}</Link>
                                                        </div>
                                                        <div className="main-card-goods-store">
                                                            <Link to={'/store/' + pr.store.id}
                                                                  className="main-card-goods-store-link">{pr.store.name}</Link>
                                                        </div>
                                                        <div className="main-card-goods-cost">
                                                    <span className="main-card-goods-cost">{(pr.cost != null
                                                        && pr.cost.cost != null)
                                                        ? pr.cost.cost : 0} BYN</span>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    })
                                }


                            </div>

                        </div>
                    </div> : ''
                }
                <div>
                    <Dialog
                        open={this.state.messageIsOpen}
                        // TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Добавить отзыв
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                {
                                    this.state.uploadingInProgress ?
                                        <div >
                                            <LinearProgress variant="determinate" value={this.state.progress}/>
                                        </div>
                                        : ''
                                }
                                <textarea
                                    autoFocus={true}
                                    className='textarea-element'
                                    value={this.state.comment}
                                    onChange={this.commentOnChange}
                                    placeholder={'Введите комментарий'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px'
                                    }} />

                            </div>
                            <CircularProgress
                                style={{display: this.state.commentInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.commentInProgress} onClick={this.sendComment}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.reportOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Пожаловаться на товар
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div >
                                <div  className='d-flex justify-content-center'>
                                    <CircularProgress
                                        style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                        color="secondary"/>
                                </div>
                                <textarea
                                    className='textarea-element'
                                    value={this.state.message}
                                    onChange={this.onChangeReport}
                                    placeholder={'Введите сообщение'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px',
                                        height: '150px'
                                    }} />

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <input ref={this.fileInput} style={{display: 'none'}} type="file" onChange={this.fileOnChange}/>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgress} onClick={this.sendReport}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <ModalGateway>
                    {
                        this.state.photoIsOpen ?
                            <Modal onClose={this.toggleViewer}>
                                <Carousel views={this.state.photoArray} />
                            </Modal>
                            : ''
                    }
                </ModalGateway>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        basket: state.basket,
        messages: state.messages
    };
};

export default connect(mapStateToProps)(AdminPreviewProductPage);
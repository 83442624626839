import {url} from '../settings/Settings';

const getNotApprovedStore = (filter) => {
    return new Promise(function(resolve, reject) {
        // console.log(JSON.stringify(filter));
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url + '/v1.0/admin/store/filter?page='
            + (filter.page || 0) + '&size='+filter.size
            + (filter.storeId ? '&storeId='+filter.storeId : '')
            + (filter.name ? '&name='+filter.name : '')
            + (filter.category ? '&category=' + filter.category : '')
            + (filter.costFrom ? '&costFrom=' + filter.costFrom : '')
            + (filter.costTo ? '&costTo=' + filter.costTo : '')
            + (filter.sort ? '&sort='+filter.sort.field + ','+filter.sort.type : '')
            , true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};

const approveStore = (storeId) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/admin/store/'+storeId, true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};

const getAdminStoreById = (id) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('GET', url + '/v1.0/admin/store/'+id, true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};

export {approveStore, getAdminStoreById, getNotApprovedStore};
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import AppRouter from './routers/AppRouter';
import configureStore from './store/configureStore';
import 'normalize.css/normalize.css';
import './styles/styles.scss';
import 'react-dates/lib/css/_datepicker.css';
import {startLoadDict} from "./actions/dictionary";
import {loadUser} from "./actions/user";
import ReactGA from 'react-ga';
import {loadBasket} from "./actions/basket";
import moment from "moment";
import {refreshSession} from "./actions/authorization";
import Fingerprint2 from 'fingerprintjs2';
import {getConversations, getUnreadAction} from "./actions/messages";
import {postUser} from "./api/AnalyticAPI";
import cookie from 'react-cookies'
import ErrorBoundary from "./components/ErrorBoundary";
import {Helmet} from "react-helmet";
import {loadUserAgentParams} from "./actions/userAgentUtilActions";
import firebase from 'firebase/app';
import {connectSocketAction} from "./actions/socketActions";


firebase.initializeApp({
    apiKey: "AIzaSyDq90pXopytV2dq4m9XGNtbN1JgSKHBLwo",
    authDomain: "crafty-ff95e.firebaseapp.com",
    databaseURL: "https://crafty-ff95e.firebaseio.com",
    projectId: "crafty-ff95e",
    storageBucket: "crafty-ff95e.appspot.com",
    messagingSenderId: "382950124332",
    appId: "1:382950124332:web:04d6c759f1be83987a9a89",
    measurementId: "G-YT2K59QE5J"
});
ReactGA.initialize('UA-134620892-1');

const store = configureStore();

// const [cookies, setCookie] = useCookies(['_a__token']);

if (window.requestIdleCallback) {
    requestIdleCallback(function () {
        Fingerprint2.get((components) => {
            const token = JSON.parse(localStorage.getItem("session"));
            let webUser = {
                token: token ? token.token : '',
                data: JSON.stringify(components),
                uniq: Fingerprint2.x64hash128(components.map(function (pair) {
                    return pair.value
                }).join(), 31)
            };
            postUser(webUser);
            cookie.save('_web', webUser.uniq, {path: '/'});
            // console.log(components); // an array of components: {key: ..., value: ...}
            // console.log(Fingerprint2.x64hash128(components.map(function (pair) { return pair.value }).join(), 31)); // an array of components: {key: ..., value: ...}
        });
    })
} else {
    setTimeout(function () {
        Fingerprint2.get(function (components) {
            const token = JSON.parse(localStorage.getItem("session"));
            let webUser = {
                token: token ? token.token : '',
                data: JSON.stringify(components),
                uniq: Fingerprint2.x64hash128(components.map(function (pair) {
                    return pair.value
                }).join(), 31)
            };
            // setCookie('_a__token', webUser.uniq, '/');
            cookie.save('_web', webUser.uniq, {path: '/'});

            postUser(webUser);
        })
    }, 500)
}


const jsx = (
    <ErrorBoundary>
        <Provider store={store}>
            <Helmet>
                <title>Crafty</title>
                <meta name="Description" content="Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы." />
                <meta name="description" content="Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы." />
                <meta name="og:description" content='Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы.' />
                <meta name="og:title" content={'Crafty'} />
                <meta property="og:image" content={'https://crafty.by/images/logo.png'} />
                <link rel="canonical" href={'https://crafty.by'} />
                <meta name="keywords" content="crafty, ремесло, ручная работа, авторская работа, handmade" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Crafty.by" />

            </Helmet>
            <AppRouter/>
        </Provider>
    </ErrorBoundary>
);


store.dispatch(startLoadDict()).then(() => {
    store.dispatch(loadUserAgentParams());
    let session = JSON.parse(localStorage.getItem("session"));
    if (!!session) {
        if (moment(session.refreshExpDate).diff(moment(), "day") > 0) {
            store.dispatch(refreshSession()).then(() => {
                store.dispatch(loadUser()).then(() => {
                    store.dispatch(loadBasket()).then(() => {
                        store.dispatch(getConversations()).then(() => {
                            store.dispatch(getUnreadAction());
                            store.dispatch(connectSocketAction());
                            ReactDOM.render(jsx, document.getElementById('app'));
                        });
                    });
                });
            });
        } else {
            localStorage.removeItem('session');
            ReactDOM.render(jsx, document.getElementById('app'));
        }
    } else {
        ReactDOM.render(jsx, document.getElementById('app'));
    }

});
import React from 'react';
import {Link} from "react-router-dom";

const Footer = (props) => (

    <div id="footer-main">
        <div className="row">
            <div className="logo"> </div>
            <div className="col align-self-end" style={{textAlign: 'right'}}>
                <a target='_blank' style={{marginLeft: '20px'}} href="https://apps.apple.com/us/app/crafty/id1446305618">
                    <img style={{height: '35px'}} src="/images/apple_button.svg" />
                </a>
            </div>

        </div>
        <div className="footer-row row"
             style={{
                 marginTop: '5px',
                 borderBottom: '1px solid #CFD6D8',
                 paddingBottom: '11px'
             }}>
            <div className="col" style={{paddingLeft: '0px'}}>
                <span className="footer-location">Беларусь, Минск</span>
                <span className="footer-delimiter">|</span>
                {/*<a href="tel:+375 (44) 763-51-42"> <span className="footer-phone">+375 (44) 763-51-42</span></a>*/}
                {/*<span className="footer-delimiter">|</span>*/}
                <a href="mailto:support@crafty.by"><span className="footer-email">support@crafty.by</span></a>
            </div>
            <div className="col align-self-end" style={{textAlign: 'right'}}>
                <Link className="footer-links" to={'/about'}>О нас</Link>
                {/*<a className="footer-links" href="#">Часто задаваемые вопросы</a>*/}
                <Link className="footer-links" to={'/agreement'}>Ответственности сторон</Link>
            </div>
        </div>
        <div className="row" style={{marginTop: '22px'}}>
            <div className="col" style={{paddingLeft: '0px'}}>
                <div>
                    <a target='_blank' href="https://www.instagram.com/crafty.by/">
                        <img src="/images/insta.svg"
                             className='icon-16' />
                    </a>
                    <a target='_blank' style={{marginLeft: '7px'}} href="https://www.facebook.com/crafty.by/">
                        <img src="/images/facebook.svg"
                             className='icon-16' />
                    </a>
                    <a target='_blank' style={{marginLeft: '7px'}} href="https://www.pinterest.com/craftyby/">
                        <img  alt={'pinterest'} src="/images/pinterest.svg"
                              className='icon-16' />
                    </a>
                    <a target='_blank' style={{marginLeft: '7px'}} href="https://vk.com/craftyby">
                        <img  alt={'VK'} src="/images/vk.svg"
                              className='icon-16' />
                    </a>
                </div>
                <div>
                    <span className="copyright">© 2019-2020 Крафти, все права защищены</span>
                </div>
            </div>
            <div className="col align-self-end" style={{textAlign: 'right'}}>
                <a target='_blank' href='https://right.by/'><img src="/images/right.svg"
                                                 className="right-icon" /></a>
                <div>
                    <a target='_blank' className='slogan-right' href='https://right.by/'>Дизайн</a>
                </div>
            </div>
        </div>

    </div>
);

export default Footer;
import React from 'react';
import Header from "./Header";
import refreshToken from "../api/UserAPI";
import MainMenu from "./MainMenu";
import {getGoodsById, getFilteredGoods, getMyGoodsById} from '../api/GoodsAPI';

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../styles/custom.css';
import Footer from "./Footer";
import {Link} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {MainPage} from "./MainPage";
import {refreshSession} from "../actions/authorization";

export class ProductPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            user: {},
            product: {},
            photos: [],
            current: 0,
            otherProduct: []
        }
    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        this.updateProduct();

    }

    updateProduct = () => {
        let product;
        getMyGoodsById(this.props.match.params.id).then(
            response => {
                product = JSON.parse(response);
                this.setState(()=>{
                    return {product: product};
                });
                this.loadOther(product.store.id);
                let photos = product.photos;
                photos.sort((a,b)=>{
                    if (a.orderPlace < b.orderPlace){
                        return -1;
                    }
                    if (a.orderPlace > b.orderPlace){
                        return 1;
                    }
                    return 0;
                });

                this.setState(()=>({photos: photos}));
            },
            error => {
                console.log(JSON.stringify(error.message));
                let resp = JSON.parse(error.message);
                if (error.code === 401 || error.code === 403){
                    this.props.dispatch(refreshSession()).then(() => {
                        this.updateProduct();
                    });
                }
                if (error.code === 404) {
                    this.props.history.goBack();
                }
            }
        );
    };

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id){
            this.updateProduct();
        }
    }


    loadOther = (id) => {
        let otherProduct;
        const filter = {
            page: 0,
            size: 4,
            storeId: id
        };
        getFilteredGoods(filter).then(
            response => {
                otherProduct = JSON.parse(response);
                this.setState(()=>({otherProduct: otherProduct.content}));
            },
            error => {
                console.log(error);
            }
        );
    };

    hexToRGB = (hex) => {
        const RGB_HEX = /^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i;

            const [ , short, long ] = String(hex).match(RGB_HEX) || [];

            if (long) {
                const value = Number.parseInt(long, 16);
                return [ value >> 16, value >> 8 & 0xFF, value & 0xFF ];
            } else if (short) {
                return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
            }

    };

    render() {
        return (
            <div>
                { this.state.product.name ?
                    <div>
                <div id="path-category" >
                    <span>Главная  /  {this.state.product.category ? this.state.product.category.name : ''}</span>
                </div>
                <div id="main-product-block">
                    <div className="row" style={{marginLeft: '0px!important'}}>
                        <div style={{display: 'inline-block'}}>
                            <div ref="picture" className="product-image-lg" style={{
                                backgroundImage: 'url("'
                                    + ( this.state.product.photos.length > 0
                                    ? this.state.product.photos.filter((ph)=>{return ph.orderPlace === 1})[0].photoMax
                                    : '' ) + '")',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }}>

                            </div>
                            <div id="product-page-gallery" style={{marginTop: '20px'}} >

                            {
                                this.state.photos.map((photo, idx) => {
                                    if(idx !== this.state.current)
                                    return (
                                            <div key={idx} className="product-image-sm " style={{
                                                backgroundImage: 'url("' + photo.photoMin + '")',
                                                backgroundSize: 'cover'
                                            }}

                                            onClick={(e)=>{
                                                this.setState(()=> ({current: idx}));
                                                this.refs.picture.style.backgroundImage = 'url(' + this.state.photos[idx].photoMax + ')';
                                            }}
                                            >

                                            </div>

                                    );
                                })
                            }
                            </div>


                        </div>
                        <div style={{display: 'inline-block', marginLeft: '51px'}}>
                            <div id="product-store-link">
                                <Link to={'/store/'+this.state.product.store.id}>{this.state.product.store.name}</Link>
                            </div>
                            <div id="product-page-name">
                                <span>{this.state.product.name}</span>
                            </div>
                            {
                                this.state.product.color ?
                                    <div id="product-page-color">
                                        <span>Цвет</span>
                                        <div id="product-page-color-circle">
                                            <div className="color-circle" style={{
                                                border: '1px solid black',
                                                backgroundColor: this.state.product.color,
                                                boxShadow: '1px 2px 4px 0 rgba(' + this.hexToRGB(this.state.product.color)[0] + ',' + this.hexToRGB(this.state.product.color)[1] + ',' + this.hexToRGB(this.state.product.color)[2] + ',0.19)'
                                            }}>

                                            </div>
                                            {
                                                this.state.product.color2 ?
                                                    <div className="color-circle" style={{
                                                        border: '1px solid black',
                                                        backgroundColor: this.state.product.color2,
                                                        boxShadow: '1px 2px 4px 0 rgba(' + this.hexToRGB(this.state.product.color2)[0] + ',' + this.hexToRGB(this.state.product.color2)[1] + ',' + this.hexToRGB(this.state.product.color2)[2] + ',0.19)'
                                                    }}>

                                                    </div>
                                                    : ''
                                            }

                                        </div>
                                    </div>
                                    : <div> </div>
                            }
                            {
                                this.state.product.materials ?
                                    <div id="product-page-materials">
                                        <span>Материалы</span>
                                        <div style={{marginTop: '10px'}}>
                                            <p>{this.state.product.materials}</p>
                                        </div>
                                    </div>
                                    : <div></div>
                            }
                            <div id="product-page-cost">
                                <span>{(this.state.product.cost != null
                                    && this.state.product.cost.cost != null)
                                    ? this.state.product.cost.cost : 0} BYN</span>
                            </div>
                            <div className="product-page-delimiter">

                            </div>
                            <div id="product-page-delivery">
                                {
                                    this.state.product.store.deliveries.map((deliv, idx) => {
                                        return (
                                            <p key={idx}>{ deliv.deliveryType.name } : {deliv.city}. Срок: {deliv.period}. Стоимость: {deliv.cost ? (deliv.cost + ' ' + deliv.currency) : 'Бесплатно'} </p>
                                        );
                                    })
                                }
                            </div>
                        </div>

                    </div>

                    <div style={{
                        height: '1px',
                        width: '1010px',
                        borderRadius: '1px',
                        backgroundColor: '#CFD6D8',
                        marginTop: '52px',
                        display: 'block'
                    }}>

                    </div>

                    <div id="product-page-description-h">
                        <span>Описание</span>
                    </div>
                    <div id="product-page-description">
            <span style={{whiteSpace: 'pre-wrap'}}>
                {this.state.product.description}
            </span>
                    </div>
                    <div style={{
                        height: '1px',
                        width: '1010px',
                        borderRadius: '1px',
                        backgroundColor: '#CFD6D8',
                        marginTop: '52px',
                        display: 'block'
                    }}>

                    </div>

                    <div id="product-page-other-h">
                        <span>Другие товары этого продавца </span> <Link to={'/store/'+this.state.product.store.id}>{this.state.product.store.name}</Link>
                    </div>
                    <div id="product-page-other">
                        {
                            this.state.otherProduct.map((pr)=> {
                                return (
                                    <div key={pr.id} style={{display: 'inline-block', marginRight: '20px'}}>
                                        <div className="main-card-goods-image"
                                             style={{backgroundImage: 'url("' +
                                                     (pr.photos.length > 0 ? pr.photos.filter((ph)=>{return ph.orderPlace === 1})[0].photoMin : '')
                                                     + '")',
                                                 backgroundSize: 'cover',
                                                 backgroundRepeat: 'no-repeat',
                                                 backgroundPositionX: 'center',
                                                 backgroundPositionY: 'center'
                                             }}
                                        >

                                        </div>
                                        <div className="main-card-goods-desc">
                                            <div className="main-card-goods-name">
                                                <Link className="main-card-goods-name-text" to={"/product/" + pr.id}>{pr.name}</Link>
                                            </div>
                                            <div className="main-card-goods-store">
                                                <Link to={'/store/'+pr.store.id} className="main-card-goods-store-link">{pr.store.name}</Link>
                                            </div>
                                            <div className="main-card-goods-cost">
                                                <span className="main-card-goods-cost">{(pr.cost != null
                                                    && pr.cost.cost != null)
                                                    ? pr.cost.cost : 0} BYN</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }



                    </div>

                </div>
                    </div>  : ''
                }
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        basket: state.basket,
        messages: state.messages
    };
};

export default connect(mapStateToProps)(ProductPage);
import React from 'react';
import {connect} from "react-redux";
import {addDiscount, deleteDiscount, deleteStore, getStore} from "../../api/StoreAPI";
import moment from 'moment';
import {deleteGoods, getMyFilteredGoods} from "../../api/GoodsAPI";
import {Link, Redirect} from "react-router-dom";
import Slide from "@material-ui/core/Slide/Slide";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import {loadUser} from "../../actions/user";
import Tabs from '@material-ui/core/Tabs';
import Tab from "@material-ui/core/Tab";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import {changeStatus, getStoreOrderv2} from "../../api/OrderAPI";
import {sendNotification} from "../../actions/notifications";
import {promSetUserPage} from "../../actions/messages";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Helmet} from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';

registerLocale('ru', ru);

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3, paddingLeft: 0 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
        width: '960px'
    },
    tabsIndicator: {
        backgroundColor: '#E60936',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        fontSize: '20px',
        lineHeight: '30px',
        fontFamily: [
            'Tanos'
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#283149',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#283149',
            outline: 0
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
});

const DateCustomInput = ({ value, onClick }) => (
    <div>

        <input type='text'
               className='input-text-element-225'
               style={{
                   paddingLeft: '20px',
                   marginTop: '10px'
               }}
               disabled={true}
               value={value}

        />
        <button className="card-goods-action-btn" style={{paddingLeft: 20, color: '#007bff'}} onClick={onClick}>
            Выбрать дату
        </button>

    </div>

);

class OwnerStorePage extends React.Component {

    constructor(props) {
        super(props);
        moment.locale('ru');
        this.state = {
            storeId: this.props.user.storeId,
            store: {
                name: '',
                owner: {
                    name: '',
                    photoMid: ''
                },
                createdDate: moment(),
                city: '',
                deliveries: [],
                approved: false
            },
            filter: {
                page: 0,
                size: 1000,
                storeId: this.props.user.storeId
            },
            filterOrder: {
                page: 0,
                size: 100,
                sort: 'createdDate,desc'
            },
            products: {},
            deleteInProgress: false,
            deleteOpen: false,
            goodsDeleteId: 0,
            deleteStoreInProgress: false,
            deleteStoreOpen: false,
            redirect: false,
            value: 0,
            orders: null,
            orderIdToChange: 0,
            orderChangeDialog: false,
            statusText: '',
            statusPlaceholder: '',
            sendingInProgress: false,
            statusAlias: '',
            commentSendText: '',
            anchorEl: null,
            orderStatusMenu: 0,
            orderId: 0,
            userId: 0,
            rejectIsOpen: false,
            messageText: '',
            copied: false,
            deleteStoreDiscountOpen: false,
            deleteStoreDiscountInProgress: false,
            createStoreDiscountOpen: false,
            createStoreDiscountInProgress: false,
            discount: 0,
            startDiscount: null,
            endDiscount: null,

        }
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    dialogOnOpen = (goodsId) => {
        this.setState(() => ({deleteOpen: true, goodsDeleteId: goodsId}))
    };

    dialogOnClose = () => {
        this.setState(() => ({goodsDeleteId: 0, deleteOpen: false, rejectIsOpen: false, messageText: ''}));
    };

    dialogStoreOnOpen = () => {
        this.setState(() => ({deleteStoreOpen: true}))
    };

    dialogStoreOnClose = () => {
        this.setState(() => ({deleteStoreOpen: false}));
    };


    onDeleteGoods = () => {
        this.setState(() => ({deleteInProgress: true}));
        deleteGoods(this.state.goodsDeleteId).then(
            response => {
                let products = {...this.state.products};
                products.content = products.content.filter(p => p.id !== this.state.goodsDeleteId);
                this.setState(()=>({products: products, deleteInProgress: false}));
                this.dialogOnClose();
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Товар удален',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));

            },
            error => {
                this.setState(() => ({deleteInProgress: false}));
                this.dialogOnClose();
                console.log(error);
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при удалении товара',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    onDeleteStore = () => {
        this.setState(() => ({deleteStoreInProgress: true}));
        deleteStore(this.props.user.storeId).then(
            response => {
                this.dialogStoreOnClose();
                this.props.dispatch(loadUser()).then(() => {
                    this.setState({redirect: true})
                });
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Магазин удален',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));

            },
            error => {
                this.setState(() => ({deleteStoreInProgress: false}));
                this.dialogStoreOnClose();
                console.log(error);
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при удалении магазина',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
    }

    componentWillMount() {
        this.loadStore();
        this.loadProduct();
        this.loadStoreOrders();
    }

    getOrderStatus = (status) => {
        switch (status) {
            case 2:
                return 'В процессе';
            case 1:
                return 'Новый заказ';
            case 3:
                return 'Отменен';
            case 4:
                return 'Выполнен';
            case 5:
                return 'Доставлено';
        }
    };

    loadProduct() {
        getMyFilteredGoods(this.state.filter).then(
            response => {
                this.setState(() => ({products: JSON.parse(response)}));
            },
            error => {
                console.log(error);
            }
        );
    };

    loadStoreOrders = () => {
        getStoreOrderv2(this.state.filterOrder).then(
            response => {
                let orders = JSON.parse(response);
                this.setState({orders});
            },
            error => {
                this.setState({redirect: true});
                console.log(error.message);
            }
        );
    };

    loadStore = () => {
        getStore(this.props.user.storeId).then(
            response => {
                this.setState(() => ({
                    store: JSON.parse(response)
                }));
            },
            error => {
                console.log(error);
            }
        );
    };

    sendOnClose = () => {
        this.setState({orderIdToChange: 0, orderChangeDialog: false, sendingInProgress: false});
    };

    sendOnOpen = (orderId, status = 2) => {
        let statusText = '';
        let statusPlaceholder = '';
        switch (status) {
            case 2:
                statusText = 'Подтвердить';
                statusPlaceholder = 'Напишите ответ';
                break;
            case 3:
                statusText = 'Отказать';
                statusPlaceholder = 'Напишите причину отказа';
                break;
            case 4:
                statusText = 'Выполнено';
                statusPlaceholder = '';
                break;
        }
        console.log(orderId);
        this.setState({orderIdToChange: orderId, orderChangeDialog: true, statusText: statusText, statusPlaceholder: statusPlaceholder, statusAlias: status});
    };

    sendStatusToChange = () => {
        changeStatus({orderId: this.state.orderIdToChange, status: this.state.statusAlias, response: this.state.commentSendText}).then(
            response => {
                this.loadStoreOrders();
                this.setState({orderIdToChange: 0, orderChangeDialog: false, statusText: '', statusPlaceholder: '', statusAlias: '', sendingInProgress: false, commentSendText: ''});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Статус товара изменен',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                console.log(error.message);
                this.setState({orderIdToChange: 0, orderChangeDialog: false, statusText: '', statusPlaceholder: '', statusAlias: '', sendingInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при изменении статуса заказа',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    onChangeComment = (ev) => {
        this.setState({commentSendText: ev.target.value});
    };

    handleCloseMenu = () => {
        this.setState({anchorEl: null, orderStatusMenu: 0, orderId: 0}, () => {
            document.removeEventListener('click', this.handleCloseMenu);
        });
    };

    handleClickMenu = (event, orderId, orderStatus) => {
        this.setState({anchorEl: event.currentTarget, orderId: orderId, orderStatusMenu: orderStatus}, () => {

            document.addEventListener('click', this.handleCloseMenu);
        });

    };

    sendMessage = (orderId) => {
        let order = this.state.orders.content.filter(o => o.orderId === orderId)[0];

        this.props.dispatch(promSetUserPage(order.user)).then(() => {
                document.getElementById('messengerBt').click()
            }
        );
        this.setState({orderId: 0});
    };

    onDeleteStoreDiscount = () => {
        this.setState({deleteStoreDiscountOpen: true});
    };

    onCreateStoreDiscount = () => {
        this.setState({createStoreDiscountOpen: true});
    };

    deleteStoreDiscount = () => {
        this.setState({deleteStoreDiscountInProgress: true});
        deleteDiscount().then(
            response => {
                this.setState({store: JSON.parse(response), deleteStoreDiscountInProgress: false, deleteStoreDiscountOpen: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Скидка удалена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({deleteStoreDiscountInProgress: false, deleteStoreDiscountOpen: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при удалении скидки',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
                console.log(error);
            }
        );
    };

    discountOnChange = (e) => {
        let discount = e.target.value.match(/(\d{1,2}$)/g);
        this.setState(() => ({discount: discount ? discount[0] : discount}));
    };

    createStoreDiscount = () => {
        this.setState({createStoreDiscountInProgress: true});
        addDiscount({
            discount: this.state.discount,
            startDiscount: moment(this.state.startDiscount).format("yyyy-MM-DD"),
            endDiscount: moment(this.state.endDiscount).format("yyyy-MM-DD")
        }).then(
            response => {
                this.setState({store: JSON.parse(response), createStoreDiscountOpen: false, createStoreDiscountInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Скидки на все товары добавлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                console.log(error);
                this.setState({createStoreDiscountInProgress: false, createStoreDiscountOpen: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при добавлении скидки',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    }

    onStartDiscountChanged = (date) => {
        this.setState({startDiscount: date});
    };
    onEndDiscountChanged = (date) => {
        this.setState({endDiscount: date});
    };

    dialogStoreDiscountOnClose = () => {
        this.setState({deleteStoreDiscountOpen: false});
    };

    dialogStoreAddDiscountOnClose = () => {
        this.setState({createStoreDiscountOpen: false});
    };


    render() {
        const { classes } = this.props;
        const { value } = this.state;
        return (
            <div>
                {this.state.redirect ? <Redirect to={'/'}/> : ''}
                <Helmet>
                    <title>{this.state.store.name}</title>
                </Helmet>
                <div id="path-category">
                    <span>Главная  / {this.state.store.name}  </span>
                </div>
                <div className="row store-box">
                    <div className="col-3">
                        <div className="store-page-photo"
                             style={{
                                 backgroundImage: 'url("' + this.state.store.photoMid + '")',
                                 backgroundSize: 'cover',
                                 backgroundRepeat: 'no-repeat',
                                 backgroundPositionX: 'center',
                                 backgroundPositionY: 'center'

                             }}
                        > </div>
                        <div className='d-flex justify-content-center' style={{
                            marginTop: '10px'
                        }}>

                            <button className='basket-remove-button' onClick={this.dialogStoreOnOpen}>Удалить</button>
                        </div>
                    </div>
                    <div className="col-xl-3" style={{wordBreak: 'break-all'}}>
                        <div className="store-page-name">
                            <span>{this.state.store.name}</span>
                        </div>
                        <div style={{marginTop: '18px'}} className="main-card-store-pin">
                            <div className="main-card-store-pin-icon"></div>
                            <span
                                className="main-card-store-location">{this.state.store.country}, {this.state.store.city} | {moment(this.state.store.createdDate).fromNow(true)} на Crafty</span>
                        </div>
                        {
                            this.state.store.address
                                ?
                                <div style={{marginTop: '18px'}}>
                                    <div className="main-card-store-pin-icon"></div>
                                    <span className="main-card-store-location">Адрес: {this.state.store.address}</span>
                                </div>
                                : ''
                        }
                        <div style={{marginTop: '18px'}} className='user-cabinet-link'>
                            <Link to={'/manage/store'}  >Редактировать</Link>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="store-header-title">
                            <span>Способы доставки</span>
                        </div>
                        <div style={{marginTop: '6px'}}>
                            <div id="product-page-delivery">
                                {
                                    this.state.store.deliveries.map((deliv, idx) => {
                                        return (
                                            <p key={idx}>{ deliv.deliveryType.name } : {deliv.city}. Срок: {deliv.period}. Стоимость: {deliv.cost ? (deliv.cost + ' ' + deliv.currency) : 'Бесплатно'} </p>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{marginLeft: 30, marginTop: 50}}>
                        <div>
                            <span>Ссылка на мой магазин</span>
                        </div>
                        {
                            this.state.store.approved ?
                                <div style={{width: 1010, marginTop: 20}} className='d-flex justify-content-start'>
                                    <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none", paddingLeft: 0}} onClick={this.sharePinterest}>
                                        <img style={{width: 30, height: 30}} src="/images/pinterestshare.svg" alt="Поделиться в Pinterest"/>
                                    </button>
                                    <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareFacebook}>
                                        <img style={{width: 30, height: 30}} src="/images/fbshare.svg" alt="Поделиться в Facebook"/>
                                    </button>
                                    <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareVk}>
                                        <img style={{width: 30, height: 30}} src="/images/vkshare.svg" alt="Поделиться во Вконтакте"/>
                                    </button>
                                    <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareOk}>
                                        <img style={{width: 30, height: 30}} src="/images/okshare.svg" alt="Поделиться в Одноклассниках"/>
                                    </button>
                                    <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareTelegram}>
                                        <img style={{width: 30, height: 30}} src="/images/telegramshare.svg" alt="Поделиться в Telegram"/>
                                    </button>
                                    <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareWhatsApp}>
                                        <img style={{width: 30, height: 30}} src="/images/whatsappshare.svg" alt="Поделиться в WhatsApp"/>
                                    </button>
                                    <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareViber}>
                                        <img style={{width: 30, height: 30}} src="/images/vibershare.svg" alt="Поделиться в Viber"/>
                                    </button>
                                </div>
                                :''
                        }

                        <div style={{marginTop: 20}}>
                            <a target='_blank' href={'https://crafty.by/store/'+this.state.storeId}>{'https://crafty.by/store/'+this.state.storeId}</a>

                            <CopyToClipboard text={'https://crafty.by/store/'+this.state.storeId}
                                             onCopy={() => {

                                                 this.props.dispatch(sendNotification({
                                                     open: true,
                                                     messageInfo: {
                                                         message: 'Ссылка скопирована',
                                                         key: new Date().getTime(),
                                                         variant: 'success'
                                                     }
                                                 }));

                                             }}>
                                <button
                                    style={{
                                        color: '#000000',
                                        fontFamily: "Open Sans",
                                        background: '#FFFFFF',
                                        outline: 'none',
                                        border: 'none',
                                        marginLeft: 20,
                                        fontSize: 14,
                                        textDecoration: 'underline'
                                    }}>Копировать</button>
                            </CopyToClipboard>

                        </div>
                    </div>
                    <div style={{
                        height: '1px',
                        width: '960px',
                        borderRadius: '1px',
                        backgroundColor: '#CFD6D8',
                        marginTop: '52px',
                        marginLeft: '15px',
                        display: 'block'
                    }}>

                    </div>

                </div>

                <div style={{marginLeft: '161px', marginTop: '33px'}}>
                    <div className="store-header-product">
                        <span>Описание</span>
                    </div>
                    <div style={{marginTop: '20px'}}>
                        <span className="user-page-description-text">{this.state.store.description}</span>
                    </div>
                    <div style={{
                        height: '1px',
                        width: '960px',
                        borderRadius: '1px',
                        backgroundColor: '#CFD6D8',
                        marginTop: '52px',
                        display: 'block'
                    }}>

                    </div>
                </div>

                <div style={{marginLeft: '161px', marginTop: '33px'}}>
                    <div className="store-header-product">
                        <span>Скидка на все товары</span>
                    </div>
                    <div style={{marginTop: '20px'}}>
                        {
                            this.state.store.discount ?
                                <div>
                                    <span className='user-page-description-text'>Скидка на все товары составляет </span><span className='user-page-description-text-b'>{this.state.store.storeDiscount.discount}%</span>
                                    <br/>
                                    <span className={'user-page-description-text'}>с </span>
                                    <span className='user-page-description-text-b'>{moment(this.state.store.storeDiscount.startDiscount).format("DD.MM.yyyy")}</span>
                                    <span> по </span>
                                    <span className='user-page-description-text-b'>{moment(this.state.store.storeDiscount.endDiscount).format("DD.MM.yyyy")}</span>
                                </div>
                                :
                                <span className='empty-section-text'>Вы не назначили скидок</span>
                        }
                    </div>
                    <div>
                        {
                            this.state.store.discount ?
                                <div>
                                    <button onClick={this.onDeleteStoreDiscount} className="card-goods-action-btn" style={{paddingLeft: 0, color: 'rgb(230, 9, 54)'}}>Удалить скидку</button>
                                </div>
                                :
                                <div>
                                    <button onClick={this.onCreateStoreDiscount} className="card-goods-action-btn" style={{paddingLeft: 0, color: '#007bff'}}>Сделать скидку</button>
                                </div>
                        }
                    </div>

                </div>

                <div style={{marginLeft: '161px', marginTop: '33px'}}>

                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                    >
                        <Tab
                            disableRipple
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label="Мои товары"
                        />
                        <Tab
                            disableRipple
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label="Мои заказы"
                        />
                    </Tabs>

                    {value === 0 && <TabContainer>
                        <div className="store-header-product">
                            <span>Товары</span>
                            <div className='inline' style={{marginLeft: '2px', float: 'right', marginRight: '30px'}}>
                                <Link to={'/manage/product/create'} className='btn-link-danger-mid' >Добавить</Link>
                            </div>
                        </div>
                        <div style={{marginTop: '20px', marginLeft: '-10px', minHeight: '1000px'}}>
                            {
                                this.state.products.content
                                    ? this.state.products.content.map((product) => {
                                        return (
                                            <div key={product.id} className="category-product-card">
                                                {
                                                    product.rejected ?
                                                        <div>
                                                            <a className='goods-status' onClick={()=>{
                                                                this.setState({messageText: product.reject.comment, rejectIsOpen: true});
                                                            }
                                                            } style={{color: 'red', opacity: '1'}} href="javascript:void(0)">Отказано (посмотреть причину)</a>
                                                            <br/>
                                                            {/*<span  className='goods-status' style={{color: 'darkgray'}}>Нажмите на "Отказано", что бы узнать причину</span>*/}
                                                        </div>
                                                        :
                                                        <span className='goods-status' style={product.approved ? {color: 'green'} : {color: 'darkgray'}}>{product.approved ? 'Одобрен' : 'На рассмотрении'}</span>
                                                }
                                                <div className="search-card-goods-image"
                                                     style={{
                                                         backgroundImage: 'url("' + (product.photos.filter((ph) => {
                                                             return ph.orderPlace === 1
                                                         }).length > 0 ? product.photos.filter((ph) => {
                                                             return ph.orderPlace === 1
                                                         })[0].photoMin : '') + '")',
                                                         backgroundSize: 'cover',
                                                         backgroundRepeat: 'no-repeat',
                                                         backgroundPositionX: 'center',
                                                         backgroundPositionY: 'center',
                                                         position: 'relative',
                                                         marginTop: 10
                                                     }}
                                                >
                                                    {
                                                        product.isAvailable
                                                            ?
                                                            <div style={
                                                                {
                                                                    width: '83px',
                                                                    height: '25px',
                                                                    backgroundColor: '#79CA1F',
                                                                    display: 'block',
                                                                    position: 'absolute',
                                                                    bottom: '0',
                                                                    borderRadius: '0 5px 0 0'
                                                                }
                                                            }><span style={
                                                                {
                                                                    color: '#FFFFFF',
                                                                    fontFamily: 'Open Sans',
                                                                    fontSize: '12px',
                                                                    lineHeight: '20px',
                                                                    marginLeft: '10px'
                                                                }
                                                            }>В наличии</span> </div>
                                                            :
                                                            ''
                                                    }
                                                </div>
                                                <div className="main-card-goods-desc">
                                                    <div className="main-card-goods-name">
                                                        <Link className="main-card-goods-name-text"
                                                              to={product.approved
                                                                  ? '/product/' + product.id
                                                                  : '/manage/preview/' + product.id}>{product.name ? product.name : 'Без имени'}</Link>
                                                    </div>
                                                    <div className="main-card-goods-action d-flex justify-content-around">
                                                        <button onClick={() => {
                                                            this.dialogOnOpen(product.id);
                                                        }}
                                                                className={'card-goods-action-btn'}>Удалить</button>
                                                        <Link to={'/manage/product/edit/'+product.id} className={'card-goods-action-btn'}>Редактировать</Link>
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })
                                    : ''
                            }
                        </div>
                    </TabContainer>}
                    {value === 1 && <TabContainer style={{padding: '0px'}}>
                        <div style={{marginTop: '0px', width: '960px', marginLeft: '0px', padding: 0}}>
                            {
                                !!this.state.orders ? this.state.orders.content.map((order, idx) => {
                                        return (
                                            <div style={{marginTop: idx === 0 ? '32px' : '48px'}} key={order.orderId}>

                                                <div className='order-header-div'>
                                                    <div className='inline-block' style={{marginLeft: '16px', marginTop: '14px'}}>
                                                        <Link to={'/user/' + order.user.id}
                                                              className="order-header-store">{order.user.name}</Link>
                                                    </div>
                                                    <div className="inline-block" style={{marginLeft: '12px', marginTop: '16px'}}>
                                                        <span className='order-header-number'>Заказ № {order.orderId} от {moment(order.createdDate).format('DD MMMM, YYYY').toString()}</span>
                                                    </div>
                                                    <div className='inline-block' style={{float: 'right', marginRight: '16px', marginTop: '16px'}}>
                                                        <span className=''>{this.getOrderStatus(order.status)}</span>
                                                    </div>
                                                </div>
                                                <div className='row' style={{marginTop: '30px', marginLeft: '0px'}}>
                                                    <div className="order-goods-image col"
                                                         style={order.item ?{
                                                             backgroundImage: 'url("' + (order.item.photos.filter((ph) => {
                                                                 return ph.orderPlace === 1
                                                             }).length > 0 ? order.item.photos.filter((ph) => {
                                                                 return ph.orderPlace === 1
                                                             })[0].photoMin : '') + '")',
                                                             backgroundSize: 'cover',
                                                             backgroundRepeat: 'no-repeat',
                                                             backgroundPositionX: 'center',
                                                             backgroundPositionY: 'center',
                                                             maxWidth: '123px'
                                                         } : {
                                                             maxWidth: '123px',
                                                             backgroundColor: '#F5F7F7'
                                                         }}
                                                    >
                                                        {
                                                            order.item ?
                                                            order.status === 1
                                                                ?  <img
                                                                    style={{marginTop: '38px',
                                                                        marginLeft: '23px'}}
                                                                    src="/images/waiting.svg"
                                                                    className="status-icon" />
                                                                :  order.status === 4 ?
                                                                <img
                                                                    style={{marginTop: '38px',
                                                                        marginLeft: '23px'}}
                                                                    src="/images/sent.svg"
                                                                    className="status-icon" />
                                                                : ''
                                                            :
                                                                <div className='basket-remove-button' style={{
                                                                    marginTop: '52px',
                                                                    marginLeft: '8px'
                                                                }}>товар удален</div>
                                                        }

                                                    </div>
                                                    <div className='col' style={{minWidth: '500px'}}>
                                                        <div style={{marginTop: '-6px', marginBottom: '8px'}}>
                                                            {
                                                                order.item ?
                                                                    <Link className="order-goods-name"
                                                                          to={'/product/' + order.item.id}>{order.item.name}</Link>
                                                                    :
                                                                    <div>
                                                                        <span className='order-goods-name'>{order.goodsName}</span>
                                                                        {/*<span className='basket-remove-button'> (товар удален)</span>*/}
                                                                    </div>
                                                            }

                                                        </div>
                                                        {   order.address ?
                                                            <div style={{marginBottom: '6px'}}>
                                                                <span className='order-info-label'>Адрес: </span> <span className='order-info-text'>{order.address}</span>
                                                            </div>
                                                            : ''
                                                        }
                                                        {   order.phone ?
                                                            <div style={{marginBottom: '6px'}}>
                                                                <span className='order-info-label'>Телефон: </span> <span className='order-info-text'>{order.phone}</span>
                                                            </div>
                                                            : ''
                                                        }
                                                        {   order.delivery ?
                                                            <div style={{marginBottom: '6px'}}>
                                                                <span className='order-info-label'>Тип доставки: </span> <span className='order-info-text'>{order.delivery}</span>
                                                            </div>
                                                            : ''
                                                        }
                                                        {   order.comment ?
                                                            <div style={{marginBottom: '6px'}}>
                                                                <span className='order-info-label'>Комментарий: </span> <span className='order-info-text'>{order.comment}</span>
                                                            </div>
                                                            : ''
                                                        }
                                                        {   order.response ?
                                                            <div style={{marginBottom: '6px'}}>
                                                                <span className='order-info-label'>Ответ магазина: </span> <span className='order-info-text'>{order.response}</span>
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                    <div className='col' style={{textAlign: 'right', paddingRight: '20px'}}>
                                                        <span className='order-amount-cost'>{order.amount} X {order.cost} BYN</span>
                                                    </div>
                                                    <div className="col" style={{textAlign: 'right', paddingRight: '20px'}}>
                                                        <div>
                                                            <span className='order-cost-sum'>{order.cost * order.amount} BYN</span>
                                                        </div>
                                                        <div className='order-action-menu' style={{marginTop: '12px'}}>
                                                            <button
                                                                className='order-action-menu'
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                style={{backgroundColor: '#FFFFFF', border: 0}}
                                                                id="dropdownMenuButton"
                                                                onClick={(e) => {
                                                                    this.handleClickMenu(e, order.orderId, order.status);
                                                                }}


                                                            >
                                                                Выберите

                                                                <img id={'img-' + order.orderId} style={{marginLeft: '6px'}}
                                                                     src={order.orderId === this.state.orderId ? "/images/arrow-up.svg" : "/images/arrow-down.svg"}
                                                                     className="arrow-icon" />

                                                            </button>
                                                            <div id={'menu' + order.orderId} className={this.state.orderId === order.orderId ? "dropdown-menu show" : 'dropdown-menu'}
                                                                 style={{top: '4rem'}}>
                                                                {
                                                                    this.state.orderStatusMenu === 1 ?
                                                                        <div>
                                                                            <button
                                                                                className="dropdown-item dropdown-item-add"
                                                                                onClick={(e) => {
                                                                                    this.sendOnOpen(this.state.orderId, 2);
                                                                                    this.handleCloseMenu();
                                                                                }}>
                                                                                Принять заказ
                                                                            </button>
                                                                            <button
                                                                                className="dropdown-item dropdown-item-add"
                                                                                onClick={() => {
                                                                                    this.handleCloseMenu();
                                                                                    this.sendOnOpen(this.state.orderId, 3);
                                                                                }}>
                                                                                Отклонить заказ
                                                                            </button>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {
                                                                    this.state.orderStatusMenu === 2 ?
                                                                        <button
                                                                            className="dropdown-item dropdown-item-add"
                                                                            onClick={() => {
                                                                                this.handleCloseMenu();
                                                                                this.sendOnOpen(this.state.orderId, 4);
                                                                            }}>
                                                                            Готово
                                                                        </button>
                                                                        : ''
                                                                }
                                                                <button
                                                                    className="dropdown-item dropdown-item-add"
                                                                    onClick={() => {
                                                                    this.sendMessage(order.orderId);
                                                                    this.handleCloseMenu();
                                                                    console.log('close')
                                                                    }}>
                                                                    Написать покупателю
                                                                </button>

                                                            </div>
                                                            <div >


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        );
                                    })
                                    : ''
                            }

                        </div>
                    </TabContainer>}


                </div>
                <div>
                    <Dialog
                        open={this.state.rejectIsOpen}
                        // TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.dialogOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Причина отказа"}
                        </DialogTitle>
                        <DialogContent>
                            <div style={{maxWidth: '500px', wordWrap: 'break-word'}}>
                                <span className='crafty-text-16'>{this.state.messageText}</span>
                            </div>
                            <br/>
                            <div>
                                <span className='crafty-text-14'>Вы можете устранить замечания и после модерации товар может быть одобрен</span>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <a onClick={this.dialogOnClose} href="javascript:void(0)" className='create-btn-40' style={{padding: '7px'}}>
                                Закрыть
                            </a>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.deleteOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.dialogOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Вы точно хотите удалить этот товар?"}
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <CircularProgress
                                style={{display: this.state.deleteInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.dialogOnClose} color="secondary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.deleteInProgress} onClick={this.onDeleteGoods} color="primary">
                                Удалить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.orderChangeDialog}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            {this.state.statusText}
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div >
                                {this.state.statusAlias !== 4 ?
                                    <textarea
                                        className='textarea-element'
                                        value={this.state.commentSendText}
                                        onChange={this.onChangeComment}
                                        placeholder={this.state.statusPlaceholder}
                                        style={{
                                            padding: '20px',
                                            marginTop: '10px'
                                        }} />
                                        : ''

                                }

                            </div>
                            <CircularProgress
                                style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.sendingInProgress} onClick={this.sendStatusToChange} color="primary">
                                Отправить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.deleteStoreOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.dialogStoreOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Вы точно хотите удалить магазин?"}
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <CircularProgress
                                style={{display: this.state.deleteStoreInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                            <span>Все ваши товары будут тоже удалены</span>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.dialogStoreOnClose} color="secondary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.deleteStoreInProgress} onClick={this.onDeleteStore} color="primary">
                                Удалить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.deleteStoreDiscountOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.dialogStoreDiscountOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent className='d-flex justify-content-center'>
                            <CircularProgress
                                style={{display: this.state.deleteStoreDiscountInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                            Скидка будет удалена, и цены вернутся в прежнее состояние
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.dialogStoreDiscountOnClose} color="primary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.deleteStoreDiscountInProgress} onClick={this.deleteStoreDiscount} color="secondary">
                                Удалить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.createStoreDiscountOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.dialogStoreAddDiscountOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        fullWidth={true}
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            {"Добавление скидки на все товары"}
                        </DialogTitle>
                        <DialogContent >
                            <CircularProgress
                                style={{display: this.state.createStoreDiscountInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                            <div style={{height: 400}}>
                                <div style={{marginTop: '27px'}}>
                                    <div className='store-setting-label'>
                                        <span className='goods-input-label'>Скидка в %</span><span className='goods-input-label' style={{color: '#E60936'}}>*</span>
                                    </div>
                                    <div >
                                        <input type='text'
                                               className='input-text-element-225'
                                               value={this.state.discount != null ? this.state.discount : ''}
                                               onChange={this.discountOnChange}
                                               placeholder='Введите % скидки'
                                               style={{
                                                   paddingLeft: '20px',
                                                   marginTop: '10px'
                                               }}/>
                                    </div>
                                    <br/>
                                    <div className='store-setting-label'>
                                        <span className='goods-input-label'>Начало действия скидки</span><span className='goods-input-label' style={{color: '#E60936'}}>*</span>
                                    </div>
                                    <DatePicker
                                        dateFormat="dd.MM.yyyy"
                                        locale="ru"
                                        popperPlacement="bottom-start"
                                        selected={this.state.startDiscount}
                                        onChange={date => this.onStartDiscountChanged(date)}
                                        customInput={<DateCustomInput />}
                                    />
                                    <br/>
                                    <div className='store-setting-label' style={{marginTop: 25}}>
                                        <span className='goods-input-label'>Конец действия скидки</span><span className='goods-input-label' style={{color: '#E60936'}}>*</span>
                                    </div>
                                    <DatePicker
                                        dateFormat="dd.MM.yyyy"
                                        locale="ru"
                                        popperPlacement="top-end"
                                        selected={this.state.endDiscount}
                                        onChange={date => this.onEndDiscountChanged(date)}
                                        customInput={<DateCustomInput />}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.dialogStoreAddDiscountOnClose} color="primary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.createStoreDiscountInProgress} onClick={this.createStoreDiscount} color="secondary">
                                Применить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>

            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

OwnerStorePage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps)(OwnerStorePage));
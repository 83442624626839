import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../styles/custom.css';
import {connect} from "react-redux";


class MainMenu extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            currentCategory: {},
            isEnable: false
        }
    }

    render() {
        return (
            // !window.location.href.includes('agreement')
            // && !window.location.href.includes('login')
            // && !window.location.href.includes('registration')
            !this.props.location.pathname.includes('agreement')
            && !this.props.location.pathname.includes('login')
            && !this.props.location.pathname.includes('registration')
            && !this.props.location.pathname.includes('product/edit')
            && !this.props.location.pathname.includes('store/create')
            && !this.props.location.pathname.includes('about')
            && !this.props.location.pathname.includes('product/create')
            && !this.props.location.pathname.includes('manage/product/create')
            && !this.props.location.pathname.includes('manage/product/edit')
            && !this.props.location.pathname.includes('manage/store')
                ?
            <div>
                <div id="menu-top" className="row" style={{borderBottom: '1px solid #CFD6D8', minWidth: '1230px'}}>
                    <ul className="nav" style={{paddingBottom: '10px'}}>

                        {
                            this.props.categories.map((category, idx) => {
                                return (
                                        <li key={idx} className="nav-item">
                                            <Link className="header-menu-label nav-link"
                                                  to={'/catalog/'+category.alias}
                                                  style={{
                                                      opacity: this.props.settings.mainCategory === category.alias
                                                          ? '1'
                                                          : this.props.settings.mainCategory === ''
                                                              ? '1' : '0.5'
                                                  }}
                                            >{category.name}</Link>
                                        </li>
                                );
                            })
                        }


                    </ul>
                    <div style={{position: 'absolute', height: '1px', width: '1px'}}>
                        <div id="menu-category" style={{
                            zIndex: 900,
                            display: this.state.isEnable ? 'block' : 'none'
                        }}>
                            <a id="category-head" className="menu-category-margin dropdown-item"
                               href="#">Смотреть все</a>
                            <div style={{marginBottom: '30px'}}></div>
                            {
                                this.state.currentCategory.name ?
                                    (this.state.currentCategory.child.map((category) => {
                                        return (
                                            <a className="menu-category-margin dropdown-item"
                                               href="#">{category.name}</a>
                                        );
                                    }))
                                    : ('')
                            }
                        </div>
                    </div>
                </div>
            </div>

            : ''
        )
    };
}

const mapStateToProps = (state) => {
    return {
        settings: state.settings,
        messages: state.messages
    };
};

export default withRouter(connect(mapStateToProps)(MainMenu));



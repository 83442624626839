import React from 'react';
import {connect} from "react-redux";
import {selectByAlias} from "../../selectors/category";
import {getFilteredGoods} from "../../api/GoodsAPI";
import {Link} from "react-router-dom";
import {refreshSession} from "../../actions/authorization";
import {setChildCategory, setMainCategory} from "../../actions/settings";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import TextField from "@material-ui/core/TextField/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Pagination from "material-ui-flat-pagination";
import * as ReactGA from "react-ga";
import {getFilteredCategories} from "../../api/DictionaryAPI";
import * as queryString from "query-string";
import SearchPageSearchBlock from "../SearchPageSearchBlock";
import {getAllStores} from "../../api/StoreAPI";


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    textSecondary: {
        opacity: '0.5',
        color: '#283149',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '19px'
    },
    textPrimary: {
        color: '#4A90E2', fontFamily: 'Open Sans', fontSize: '14px', lineHeight: '19px'
    }
});

const ordering = [
    {
        value: 'createdDate,desc',
        label: 'Сначала новые',
    },
    {
        value: 'createdDate,asc',
        label: 'Сначала старые',
    }
];

class StoreListPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentCategory: {},
            availableCategories: [],
            filter: {
                page: 0,
                size: 20,
                sort: 'createdDate,desc'

            },
            stores: {
                content: []
            },
            value: {
                min: props.dict.limits.min,
                max: props.dict.limits.max
            },
            loading: true,
            offset: 0,
            limit: 1,
            total: 1
        }
    }


    onBackButtonEvent = ( ev ) => {
        if(ev.state && typeof ev.state.page !== 'undefined' ) {

            let filter = {...this.state.filter};
            filter.page = ev.state.page;
            filter.size = ev.state.size;
            filter.sort = ev.state.sort;

            this.setState({filter: filter, offset: (filter.page * filter.size)}, () => {
                this.searchStore();
            });

        }
    };



    componentDidMount() {

        const parsed = queryString.parse(location.search);
        let filter = {...this.state.filter};
        filter.size = parsed.size ? parsed.size : 40;
        filter.page = parsed.page ? parsed.page : 0;
        filter.sort = parsed.sort ? parsed.sort : filter.sort;
        window.history.pushState(filter, 'Ремесленники', '?' + queryString.stringify(filter));
        this.setState({filter: filter, offset: (filter.page * filter.size)}, () => {
            this.searchStore();
        });

        if (typeof window !== 'undefined'){
            window.scrollTo(0, 0);
            window.addEventListener('popstate', this.onBackButtonEvent);
        }
        ReactGA.event({
            category: 'Search',
            action: 'search store'
        });
    }

    componentWillUnmount() {
        if (window)
            window.removeEventListener('popstate', this.onBackButtonEvent);
    }

    searchStore = () => {
        if (typeof window !== 'undefined'){
            window.scrollTo(0, 0);
        }
        this.setState(()=>({loading: true}));
        getAllStores({...this.state.filter}).then(
            response => {
                let resp  = JSON.parse(response);
                    this.setState(() => ({
                        stores: resp,
                        limit: resp.size,
                        total: resp.totalElements

                }));
                this.setState(()=>({loading: false}));
            },
            error => {
                this.setState(()=>({loading: false}));
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403) {
                    this.props.dispatch(refreshSession()).then(() => {
                        this.searchStore();
                    });
                }
            }
        );
    };

    hexToRGB = (hex) => {
        const RGB_HEX = /^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i;

        const [, short, long] = String(hex).match(RGB_HEX) || [];

        if (long) {
            const value = Number.parseInt(long, 16);
            return [value >> 16, value >> 8 & 0xFF, value & 0xFF];
        } else if (short) {
            return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
        }

    };

    onOrderChange = (ev) => {
        let filter = this.state.filter;
        filter.sort = ev.target.value;
        this.setState({filter: filter}, () => {
            const params = queryString.parse(location.search);
            params.page = filter.page;
            params.size = filter.size;
            params.sort = filter.sort;
            history.pushState(params, 'Ремесленники', '?' + queryString.stringify(params));

            this.searchStore();
        });
    };

    changePage = (e, offset, page) => {
        let filter = this.state.filter;
        filter.page = page - 1;
        this.setState(() => ({filter: filter, offset: offset}), () => {
            const params = queryString.parse(location.search);
            params.page = filter.page;
            params.size = filter.size;
            params.sort = filter.sort;
            history.pushState(params, 'Ремесленники', '?' + queryString.stringify(params));

            this.searchStore();
        });
    };

    render() {

        const { classes, theme } = this.props;

        return (
            <div>

                <div id="path-category">
                    <span>Главная  / Новые магазины  </span>
                </div>
                <div className="row catalog-box">
                    <div className="col">
                        <div className="row">
                    <div className="col-lg-12">

                        <div style={{marginTop: '15px', marginRight: '50px'}} className="category-sort d-flex justify-content-end">
                            <TextField
                                id="filled-select-currency"
                                select
                                className={classes.textField}
                                value={this.state.filter.sort}
                                onChange={this.onOrderChange}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.textField
                                }}
                                margin="normal"
                            >
                                {ordering.map(option => (
                                    <MenuItem style={{color: '#5B6273',
                                        fontFamily: "Open Sans",
                                        fontSize: '12px',
                                        lineHeight: '17px'}} key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                        </div>
                        <div className="category-product-box" style={{marginTop: '15px'}}>


                            {   this.state.loading ? <CircularProgress color="secondary" style={{
                                marginLeft: '380px'
                                }} /> :
                                this.state.stores.content.length > 0 ?
                                this.state.stores.content.map((store) => {
                                    return (
                                        <div key={store.id} className="main-card-store-block">
                                            <Link to={'/store/' + store.id} className='link-block'>
                                                <div className="main-card-store-image"
                                                     style={{
                                                         backgroundImage: 'url("' + store.photoMin + '")',
                                                         backgroundSize: 'cover',
                                                         backgroundRepeat: 'no-repeat',
                                                         backgroundPositionX: 'center',
                                                         backgroundPositionY: 'center'
                                                     }}
                                                ></div>
                                                <div className="main-card-goods-desc">
                                                    <div className="main-card-goods-name">
                                                        <Link className="main-card-store-name" to={'/store/' + store.id}>{store.name}</Link>
                                                    </div>

                                                    <div className="main-card-store-pin">
                                                        <div className="main-card-store-pin-icon"></div>
                                                        <span className="main-card-store-location">{store.city}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })
                                :
                                    <div className='d-flex justify-content-center'>
                                        <span>Ничего не найдено</span>
                                    </div>
                            }


                        </div>
                        <div className={'d-flex justify-content-center'}>
                                <Pagination
                                    limit={this.state.stores.size}
                                    offset={this.state.offset}
                                    total={this.state.stores.totalElements}
                                    currentPageColor={'primary'}
                                    otherPageColor={'secondary'}
                                    classes={{textSecondary: classes.textSecondary, textPrimary: classes.textPrimary}}
                                    onClick={(e, offset, page) => this.changePage(e, offset, page)}
                                />
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

StoreListPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(StoreListPage));
import React, {Component} from 'react';
import {BrowserRouter, Route, Switch, Link, NavLink} from 'react-router-dom';
import NotFoundPage from '../components/NotFoundPage';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../styles/custom.css';
import LoginPage from "../components/LoginPage";
import MainPage from "../components/MainPage";
import ProductPage from "../components/ProductPage";
import CatalogPage from "../components/CatalogPage";
import StorePage from "../components/store/StorePage";
import UserPage from "../components/UserPage";
import UserCabinetPage from "../components/UserCabinetPage";
import RegistrationPage from "../components/RegistrationPage";
import CreateStorePage from "../components/CreateStorePage";
import OwnerStorePage from "../components/store/OwnerStorePage";
import CreateGoodsPage from "../components/CreateGoodsPage";
import GoodsListForApprove from "../components/GoodsListForApprove";
import EditGoodsPage from "../components/EditGoodsPage";
import PreviewProductPage from "../components/PreviewProductPage";
import AdminPreviewProductPage from "../components/AdminPreviewProductPage";
import StoreListForApprove from "../components/store/StoreListForApprove";
import AdminStorePage from "../components/AdminStorePage";
import AgreementPage from "../components/AgreementPage";
import ForgotPassword from "../components/ForgotPassword";
import RepairPasswordPage from "../components/RepairPasswordPage";
import BookmarkPage from "../components/BookmarkPage";
import BasketPage from "../components/BasketPage";
import {connect} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from "@material-ui/core";
import PropTypes from 'prop-types';
import {sendNotification} from "../actions/notifications";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {
    createNew,
    getConversations,
    getDialog,
    getUnreadAction,
    setDialogId,
    setIsDialog,
    setOpenStatus
} from "../actions/messages";
import {refreshSession} from "../actions/authorization";
import AdminUsersPage from "../components/AdminUsersPage";
import Header from "../components/Header";
import MainMenu from "../components/MainMenu";
import Footer from "../components/Footer";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import moment from "moment";
import ConversationsList from "../components/ConversationsList";
import Conversation from "../components/Conversation";
import {getConversationExist} from "../api/MessageAPI";
import UserCabinetEditPage from "../components/UserCabinetEditPage";
import SearchPage from "../components/SearchPage";
import AboutUsPage from "../components/AboutUsPage";
import * as ReactGA from "react-ga";
import {YMInitializer} from "react-yandex-metrika";
import StoreListPage from "../components/store/StoreListPage";
import CollectionPage from "../components/CollectionPage";
import ConfirmEmailComponent from "../components/ConfirmEmailComponent";
import CollectionAdminPage from "../components/CollectionAdminPage";

const styles = theme => ({
    close: {
        padding: theme.spacing.unit,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing.unit * 16,
        right: theme.spacing.unit * 10,
        width: '93px',
        height: '50px',
        borderRadius: '25px',
        boxShadow: '0 6px 20px 0 rgba(167, 106, 118, 0.24)',
        backgroundColor: '#ffffff'
    },
    paper: {
        boxShadow: '-8px 16px 32px 0 rgba(64, 75, 105, 0.2)'
    }
});

const styles1 = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        wordWrap: 'break-word',
        maxWidth: '400px'
    },

});


const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

function MySnackbarContent(props) {
    const {classes, className, message, onClose, variant, ...other} = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)}/>
                    {message}
        </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon}/>
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

class AppRouter extends Component {

    queue = [];

    constructor(props) {
        super(props);
        moment.locale('ru');
        this.state = {
            open: true,
            messageInfo: {},
            messageInterval: null,
            conversationsInterval: null,
            messengerOpen: false,
        };
    }


    toggleDrawer = (open) => () => {
        ReactGA.event({
            category: 'Message',
            action: 'Pressed message action'
        });
        ReactGA.pageview('/message');
        if (!!this.props.messages.userPage) {
            getConversationExist(this.props.messages.userPage.id).then(
                response => {
                    let exist = JSON.parse(response);
                    if (exist.exist) {
                        this.props.dispatch(getDialog({id: exist.conversationId, size: 20})).then(() => {
                            this.props.dispatch(setDialogId(exist.conversationId));
                            this.props.dispatch(setIsDialog(true));
                            this.props.dispatch(setOpenStatus(open));
                            this.setState({
                                messengerOpen: open,
                            });
                        });

                    } else {
                        this.props.dispatch(createNew(true));
                        this.props.dispatch(setIsDialog(true));
                        this.props.dispatch(setOpenStatus(open));
                        this.setState({
                            messengerOpen: open,
                        });
                    }
                },
                error => {
                    console.log(error);
                }
            );
        } else {
            this.props.dispatch(setOpenStatus(open));

            this.setState({
                messengerOpen: open,
            });
        }


    };

    handleClick = message => () => {
        this.queue.push({
            message,
            key: new Date().getTime(),
        });

        if (this.state.open) {
            // immediately begin dismissing current message
            // to start showing new one
            this.setState({open: false});
        } else {
            this.processQueue();
        }
    };

    processQueue = () => {
        if (this.props.notification.queue.length > 0) {
            this.props.dispatch(sendNotification({
                messageInfo: this.props.notification.queue.shift(),
                open: true,
            }));
        }
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.dispatch(sendNotification({open: false, messageInfo: {message: '', key: new Date().getTime()}}));
    };

    handleExited = () => {
        this.processQueue();
    };


    componentDidMount() {
        let interval = setInterval(() => {
            if (this.props.user.name.length > 0) {

                this.props.dispatch(getUnreadAction()).then(
                    response => {
                        if (response) {
                            if (response.code === 401 || response.code === 403) {
                                if (localStorage.getItem('session')) {
                                    this.props.dispatch(refreshSession());
                                } else {
                                    clearInterval(this.state.messageInterval);
                                }
                            }
                        }
                    },
                    error => {
                        if (error.code === 401 || error.code === 403) {
                            if (localStorage.getItem('session')) {
                                this.props.dispatch(refreshSession());
                            } else {
                                clearInterval(this.state.messageInterval);
                            }
                        }
                    }
                );
            }
        }, 10000);


        let conversationInterval = setInterval(() => {
            if (this.props.user.name.length > 0) {

                this.props.dispatch(getConversations()).then(
                    response => {
                        if (response) {
                            if (response.code === 401 || response.code === 403) {
                                if (localStorage.getItem('session')) {
                                    this.props.dispatch(refreshSession());
                                } else {
                                    clearInterval(this.state.conversationsInterval);
                                }
                            }
                        }
                    },
                    error => {
                        if (error.code === 401 || error.code === 403) {
                            if (localStorage.getItem('session')) {
                                this.props.dispatch(refreshSession());
                            } else {
                                clearInterval(this.state.conversationsInterval);
                            }
                        }
                    }
                );
            }
        }, 10000);
        this.setState({messageInterval: interval, conversationsInterval: conversationInterval});
    }

    componentWillUnmount() {
        clearInterval(this.state.messageInterval)
    }


    render() {

        const {classes} = this.props;
        const {messageInfo} = this.props.notification;

        return (<BrowserRouter>
                <div>
                    <YMInitializer accounts={[56086378]} options={{
                        clickmap:true,
                        trackLinks:true,
                        accurateTrackBounce:true,
                        webvisor: true
                    }} />
                    <Header pr={this.props}/>
                    <MainMenu categories={this.props.dict ? this.props.dict.categories : {}}/>

                    <Switch>
                        <Route path="/" component={MainPage} exact={true}/>
                        <Route path="/login" component={LoginPage} exact={true}/>
                        <Route path="/registration" component={RegistrationPage}/>
                        <Route path="/manage/product/create" component={CreateGoodsPage} exact={true}/>
                        <Route path="/manage/product/edit/:id" component={EditGoodsPage} exact={true}/>
                        <Route path="/manage/preview/:id" component={ProductPage} exact={true}/>
                        <Route path="/product/:id" component={ProductPage}/>
                        <Route path="/catalog/:alias" component={CatalogPage} exact={true}/>
                        <Route path="/catalog/:alias/:child" component={CatalogPage}/>
                        <Route path="/search" component={SearchPage}/>
                        <Route path="/manage/store" component={CreateStorePage} exact={true}/>
                        <Route path="/manage/owner" component={OwnerStorePage} exact={true}/>
                        <Route path="/stores" component={StoreListPage} exact={true}/>
                        <Route path="/store/:storeId" component={StorePage}/>
                        <Route path="/cabinet" component={UserCabinetPage} exact={true} pr={this.toggleDrawer}/>
                        <Route path="/cabinet/edit" component={UserCabinetEditPage} exact={true}/>
                        <Route path="/user/:userId" component={UserPage}/>
                        <Route path="/admin/approve" component={GoodsListForApprove} exact={true}/>
                        <Route path="/admin/users" component={AdminUsersPage} exact={true}/>
                        <Route path="/admin/store/approve" component={StoreListForApprove} exact={true}/>
                        <Route path="/admin/store/preview/:storeId" component={AdminStorePage} exact={true}/>
                        <Route path="/admin/preview/:id" component={AdminPreviewProductPage}/>
                        <Route path="/admin/collection/:id" component={CollectionAdminPage}/>
                        <Route path="/agreement" component={AgreementPage} exact={true}/>
                        <Route path="/login/forgot" component={ForgotPassword} exact={true}/>
                        <Route path="/forgot/:key" component={RepairPasswordPage} exact={true}/>
                        <Route path="/bookmark" component={BookmarkPage} exact={true}/>
                        <Route path="/basket" component={BasketPage} exact={true}/>
                        <Route path="/about" component={AboutUsPage} exact={true}/>
                        <Route path="/collection/:id" component={CollectionPage} exact={true}/>
                        <Route path="/confirm/:hash" component={ConfirmEmailComponent} exact={true}/>
                        <Route component={NotFoundPage}/>
                    </Switch>
                    <Snackbar
                        key={messageInfo.key}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        open={this.props.notification.open}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                        onExited={this.handleExited}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        // message={<span id="message-id">{messageInfo.message}</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={this.handleClose}
                            >
                                <CloseIcon/>
                            </IconButton>,
                        ]}
                    >
                        <MySnackbarContentWrapper
                            onClose={this.handleClose}
                            variant={messageInfo.variant}
                            message={messageInfo.message}
                        />
                    </Snackbar>
                    {
                        !this.props.user.name ? '' :
                            <SwipeableDrawer
                                classes={{paper: classes.paper}}
                                // className={classes.root}
                                anchor="right"
                                // variant='persistent'
                                style={{
                                    boxShadow: '-8px 16px 32px 0 rgba(64, 75, 105, 0.2)'
                                }}
                                variant='persistent'
                                open={this.props.messages.isOpen}
                                onClose={this.toggleDrawer(false)}
                                onOpen={this.toggleDrawer(true)}
                            >
                                {
                                    this.props.messages.isDialog ?
                                        <Conversation/>
                                        :
                                        <ConversationsList/>
                                }
                            </SwipeableDrawer>
                    }
                    {
                        !this.props.user.name ? '' :
                            <div id='messengerBt' className={classes.fab} onClick={this.toggleDrawer(!this.props.messages.isOpen)}>
                                <div className='chart-button-container'>
                                    <img
                                        style={{marginBottom: '4px'}}
                                        src={this.props.messages.unread > 0 ? '/images/messages-filled.svg' : '/images/message-icon.svg'}
                                        className="chart-icon"/>
                                    <span className='chart-icon-text'>Чат</span>
                                </div>
                            </div>
                    }

                    <Footer/>
                </div>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        notification: state.notification,
        messages: state.messages,
        basket: state.basket,
    };
};

AppRouter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps)(AppRouter));

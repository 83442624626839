import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {getConversations, getDialog, setDialogId, setIsDialog, setOpenStatus} from "../actions/messages";

const styles = theme => ({
    close: {
        padding: theme.spacing.unit,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing.unit * 16,
        right: theme.spacing.unit * 10,
        width: '93px',
        height: '50px',
        borderRadius: '25px',
        boxShadow: '0 6px 20px 0 rgba(167, 106, 118, 0.24)',
        backgroundColor: '#ffffff'
    },
    paper: {
        boxShadow: '-8px 16px 32px 0 rgba(64, 75, 105, 0.2)'
    }
});


class ConversationsList extends React.Component {


    constructor(props) {
        super(props);
    }


    componentDidMount(){
        this.props.dispatch(getConversations());
    }

    render() {
        return (
            <div
                tabIndex={0}
                className='messenger-content-container'
            >
                <div
                    style={{
                        width: '360px',
                        // marginLeft: '24px'
                    }}
                >
                    <div className='messenger-header'>
                        <div className='inline-block' >
                            <span className={'messenger-header-text'}>Чаты</span>
                        </div>
                        <div className='inline-block' style={{marginLeft: '240px'}}>
                            <img onClick={() => {
                                this.props.dispatch(setOpenStatus(false));
                            }}  className='close' src="/images/close.svg" alt=""/>
                        </div>
                    </div>
                    <div className='messenger-content-list'>
                        {
                            (!this.props.messages || !this.props.messages.conversations.content) ? '' : this.props.messages.conversations.content.map((item) => {
                                return (
                                    <div key={item.id}>
                                        {
                                            item.users.filter(user => user.id !== this.props.user.id).map((user) => {
                                                return (
                                                    <div key={user.id} style={{paddingBottom: '15px', paddingTop: '8px', paddingLeft: '24px', marginLeft: '0px'}} className='messenger-item-box row'
                                                         onClick={() => {
                                                             this.props.dispatch(getDialog({id: item.id, size: 20})).then(() => {
                                                                 this.props.dispatch(setDialogId(item.id));
                                                                 this.props.dispatch(setIsDialog(true));
                                                             });
                                                         }}>
                                                        <div className='inline-block'  style={{
                                                            width: '40px',
                                                            display: 'inline-block',
                                                        }}>
                                                            <div className="user-conversation-photo"
                                                                 style={{
                                                                     backgroundImage: 'url("' + (!!user.photoMid ? user.photoMid : '/images/avatar.png') + '")',
                                                                     backgroundSize: (!!user.photoMid ? 'cover' : 'contain'),
                                                                     backgroundRepeat: 'no-repeat',
                                                                     backgroundPositionX: 'center',
                                                                     backgroundPositionY: 'center',
                                                                     borderRadius: '50%'
                                                                 }}
                                                            ></div>
                                                        </div>
                                                        <div className='inline-block' style={{
                                                            marginLeft: '12px',
                                                            width: '150px'
                                                        }}>
                                                            <div className='hide-text'>
                                                                <Link className='messenger-content-name' to={'/user/'+user.id}>{user.name}</Link>
                                                            </div>
                                                            <div className='messenger-content-message' style={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap'
                                                            }}>
                                                                {
                                                                    item.messages.content[0] ?
                                                                        <span >{item.messages.content[0].message ? item.messages.content[0].message : item.messages.content[0].files ? ('Файл: ' + item.messages.content[0].files[0].name) : ''}</span>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='inline-block' style={{
                                                            float: 'right',
                                                            marginRight: '20px',
                                                            width: '100px',
                                                            textAlign: 'end'
                                                        }}>
                                                            <div >
                                                            <span className='messenger-header-date'>{Math.round(moment(new Date()).diff(moment.utc(item.updatedDate, 'YYYY-MM-DDTHH:mm:ss').local(false))/ 86400000) > 1
                                                                ? moment.utc(item.updatedDate, 'YYYY-MM-DDTHH:mm:ss').local(false).format('DD/MM').toString()
                                                                : moment.utc(item.updatedDate, 'YYYY-MM-DDTHH:mm:ss').local(false).calendar()}</span>
                                                                {/*}}>{moment(item.updatedDate, 'YYYY-MM-DDTHH:mm:ss').calendar()}</span>*/}
                                                            </div >
                                                            <div>
                                                                {
                                                                    item.unreadCount > 0 ?

                                                                        <div className='conversation-unread-counter' style={{float: 'right'}}>
                                                                            <p>{item.unreadCount}</p>
                                                                        </div>

                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })

                                        }
                                    </div>
                                );
                            })
                        }

                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        notification: state.notification,
        messages: state.messages,
        basket: state.basket,
    };
};

ConversationsList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps)(ConversationsList));
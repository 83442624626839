import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import expensesReducer from '../reducers/expenses';
import filtersReducer from '../reducers/filters';
import categoryReducer from '../reducers/category';
import dictionary from "../reducers/dictionary";
import thunk from 'redux-thunk';
import user from "../reducers/user";
import settings from "../reducers/settings";
import authorization from "../reducers/authorization";
import basket from "../reducers/basket";
import notifications from "../reducers/notifications";
import messages from "../reducers/messages";
import localStorageValues from "../reducers/localStorageValues";
import userAgentUtil from "../reducers/userAgentUtil";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export default () => {
    const store = createStore(
        combineReducers({
            expenses: expensesReducer,
            filters: filtersReducer,
            category: categoryReducer,
            dictionary: dictionary,
            user: user,
            settings: settings,
            authorization: authorization,
            basket: basket,
            notification: notifications,
            messages: messages,
            localStorageValues: localStorageValues,
            userAgentUtil: userAgentUtil
        }),
        composeEnhancers(applyMiddleware(thunk))
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

    return store;
};

import React from 'react';
import {Link} from "react-router-dom";


const MainListProduct = (props) => (

    <div id="popular" style={{marginTop: '48px'}}>
        <div className="row">
            <div className="col-12">
                <Link to={props.url} className="header-list-goods">{props.name}</Link>
            </div>
        </div>
        <div className="row" style={{marginTop: '21px'}}>
            {
                props.productList ? props.productList.map((product) => (
                    <div className="col-lg-2" key={product.id} style={{minWidth: '213px', marginBottom: 20}}>
                        <Link className="link-block" to={"/product/" + product.id}>
                            <div className="main-card-goods-image" style={
                                {
                                    backgroundImage: 'url("'
                                        + (product.photos.length > 0
                                            ? product.photos.filter((ph) => {
                                                return ph.orderPlace === 1
                                            })[0].photoMin
                                            : '')
                                        + '")',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPositionX: 'center',
                                    backgroundPositionY: 'center',
                                    position: 'relative'
                                }}>
                                {
                                    product.isAvailable
                                        ?
                                        <div style={
                                            {
                                                width: '83px',
                                                height: '25px',
                                                backgroundColor: '#79CA1F',
                                                display: 'block',
                                                position: 'absolute',
                                                bottom: '0',
                                                borderRadius: '0 5px 0 0'
                                            }
                                        }><span style={
                                            {
                                                color: '#FFFFFF',
                                                fontFamily: 'Open Sans',
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                                marginLeft: '10px'
                                            }
                                        }>В наличии</span></div>
                                        :
                                        ''
                                }

                            </div>
                            <div className="main-card-goods-desc">
                                <div className="main-card-goods-name">
                                    <Link className="main-card-goods-name-text"
                                          to={"/product/" + product.id}><h3 className='goods-h3'>{product.name}</h3></Link>
                                </div>
                                <div className="main-card-goods-store">
                                    <Link to={'/store/' + product.store.id}
                                          className="main-card-goods-store-link"><h3 className='store-h3'>{product.store.name}</h3></Link>
                                </div>
                                <div className="main-card-goods-cost">
                                    {
                                        product.oldCost ?
                                            <span
                                                className="main-card-goods-cost crossed-red-cost" style={{fontSize: 11}} >{product.oldCost ? product.oldCost.cost : 0} {product.oldCost ? product.oldCost.currency : 'BYN '}  </span>
                                            : ''
                                    }
                                    <span
                                        className="main-card-goods-cost">{product.cost ? product.cost.cost : 0} {product.cost ? product.cost.currency : 'BYN'}</span>
                                </div>
                            </div>
                        </Link>
                    </div>

                )) : ''
            }


        </div>

    </div>
);

export default MainListProduct;
const settingsReducerDefaultState = {
    mainCategory: '',
    child: ''
};

export default (state = settingsReducerDefaultState, action) => {
    switch (action.type) {
        case 'MAIN_CATEGORY':
            return {
                ...state,
                ...action.settings
            };
        case 'CHILD_CATEGORY':
            return{
                ...state,
                ...action.settings
            };
        default:
            return state;
    }
};

import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Redirect} from "react-router-dom";
import {getSmsToConfirm, sendCodeToConfirm, updateUserInfo, uploadPhoto} from "../api/UserAPI";
import {changePhoto, loadUser, saveUser} from "../actions/user";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import {startLogoutAction} from "../actions/authorization";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import {resetPassword} from "../api/AuthorizationApi";
import {changeStatusByUser, getUserOrder} from "../api/OrderAPI";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {sendNotification} from "../actions/notifications";
import {reportOrder} from "../api/ReportAPI";
import InputMask from 'react-input-mask';
import {MaskedInput} from "react-text-mask";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};


const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
        width: '960px'
    },
    tabsIndicator: {
        backgroundColor: '#E60936',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        fontSize: '20px',
        lineHeight: '30px',
        fontFamily: [
            'Tanos'
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#283149',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#283149',
            outline: 0
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
});

class UserCabinetEditPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
            displayProgress: false,
            user: {
                name: this.props.user.name,
                country: this.props.user.country,
                city: this.props.user.city,
                phone: this.props.user.phone,
                description: this.props.user.description,
                address: this.props.user.address,
                phoneIsConfirmed: this.props.user.phoneIsConfirmed
            },
            logout: false,
            errorStyle: {
                borderBottom: '2px',
                borderBottomColor: '#E60936',
                borderBottomStyle: 'solid'
            },
            openDialog: false,
            openPhoneDialog: false,
            codeIsExist: false,
            phoneNumber: '',
            code: '',
            validate: false,
            errorText: '',
            inProcess: false,
            changePassword: {
                oldPassword: '',
                newPassword: '',
                repeatNewPassword: ''
            },
            orders: null,
            filter: {
                page: 0,
                size: 40,
                sort: 'createdDate,desc'
            },
            value: 0,
            deliveredDialog: false,
            deliveredInProgress: false,
            deliveredId: 0,
            messageReport: '',
            reportOpen: false,
            sendingInProgressReport: false,
            orderId: 0,
            successRedirect: false
        };
        moment.locale('RU');
    }

    componentWillMount() {
        this.checkAuthorization();
        this.loadUserOrders();
    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        if (!this.props.user.phoneIsConfirmed) {
            this.setState({openPhoneDialog: true});
        }
    }

    selectPhoto = () => {
        this.refs.uploadPhoto.click();
    };

    loadUserOrders = () => {
        getUserOrder(this.state.filter).then(
            response => {
                let orders = JSON.parse(response);
                this.setState({orders});
            },
            error => {
                console.log(error.message);
            }
        );
    };

    onProgress = (event) => {
        this.refs.mainPhoto.style.display = 'none';
        this.setState(() => ({displayProgress: true}));
        if (event.lengthComputable) {
            let complete = (event.loaded / event.total * 100 | 0);
            this.setState({progress: complete});
        }
    };

    uploadPhoto = () => {

        let formData = new FormData();
        formData.append('file', this.refs.uploadPhoto.files[0]);
        uploadPhoto(formData, this.onProgress).then(
            response => {
                this.props.dispatch(changePhoto(JSON.parse(response)));
                this.refs.mainPhoto.style.display = 'block';
                this.setState(() => ({displayProgress: false}));
            },
            error => {
                console.log(error);
                this.refs.mainPhoto.style.display = 'block';
                this.setState(() => ({displayProgress: false}));
            }
        );
    };

    nameOnChange = (e) => {
        let user = {...this.state.user};
        user.name = e.target.value;
        this.setState(() => ({user}));
    };

    countryOnChange = (e) => {
        let user = {...this.state.user};
        user.country = e.target.value;
        this.setState(() => ({user}));
    };

    cityOnChange = (e) => {
        let user = {...this.state.user};
        user.city = e.target.value;
        this.setState(() => ({user}));
    };

    addressOnChange = (e) => {
        let user = {...this.state.user};
        user.address = e.target.value;
        this.setState({user});
    };

    phoneOnChange = (e) => {
        // let user = {...this.state.user};
        // user.phone = e.target.value;
        this.setState(() => ({phone: e.target.value}));
    };

    descriptionOnChange = (e) => {
        let user = {...this.state.user};
        user.description = e.target.value;
        this.setState(() => ({user}));
    };

    saveChange = () => {
        updateUserInfo(this.state.user).then(
            response => {
                this.props.dispatch(saveUser(JSON.parse(response)));
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Данные сохранены',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
                this.setState({successRedirect: true});
            },
            error => {
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Произошла ошибка при сохранении данных',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
                console.log(error);
            }
        );
    };

    checkAuthorization = () => {
        if (!this.props.user.name) {
            this.setState({logout: true});
        }
    };

    onLogout = () => {
        this.props.dispatch(startLogoutAction()).then(() => {
            this.setState(() => ({logout: true}));
        });
    };

    onPasswordChangeDialog = () => {
        this.setState({openDialog: true});
    };

    onPhoneChangeDialog = () => {
        this.setState({openPhoneDialog: true});
    };

    onPasswordChange = () => {
        let changePassword = this.state.changePassword;
        let isValid = true;
        if (!changePassword.oldPassword || changePassword.oldPassword.trim().length < 1) {
            isValid = false;
            this.setState({validate: true})
        }
        if (!changePassword.newPassword || changePassword.newPassword.trim().length < 1) {
            isValid = false;
            this.setState({validate: true})
        }
        if (!changePassword.repeatNewPassword || changePassword.repeatNewPassword.trim().length < 1) {
            isValid = false;
            this.setState({validate: true})
        }
        if (changePassword.newPassword !== changePassword.repeatNewPassword) {
            isValid = false;
            this.setState({validate: true})
        }

        if (!isValid) {
            return;
        }

        this.setState({inProcess: true});
        resetPassword(this.state.changePassword).then(
            response => {
                localStorage.setItem("session", response);
                this.props.dispatch(loadUser());
                this.setState({inProcess: false, openDialog: false});
                this.onDialogClose();
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Пароль изменен',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                const err = JSON.parse(error.message);
                if (err.code === 51) {
                    this.setState({errorText: 'Неверный пароль'});
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Неверный пароль',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
                if (err.code === 44) {
                    this.setState({errorText: 'Новый ппароль неверный длины. Должен быть от 8 до 250 символов'});
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Новый пароль неверной длины. Должен быть от 8 до 250 символов.',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
                this.setState({inProcess: false});
            }
        );

    };

    sendOnOpen = (orderId) => {
        this.setState({deliveredDialog: true, deliveredId: orderId});
    };

    sendOnClose = () => {
        this.setState({deliveredDialog: false, deliveredId: 0});
    };

    onDialogClose = () => {
        if (!this.state.inProcess) {
            let changePassword = {
                oldPassword: '',
                newPassword: '',
                repeatNewPassword: ''
            };
            this.setState({openDialog: false, changePassword: changePassword, openPhoneDialog: false, code: '', codeIsExist: false});
        }
    };

    getOrderStatus = (status) => {
        switch (status) {
            case 2:
                return 'Подтвержден';
            case 1:
                return 'Ожидает ответа';
            case 3:
                return 'Отменен';
            case 4:
                return 'Готов к получению';
            case 5:
                return 'Получено';
        }
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    sendStatusToChange = () => {
        this.setState({deliveredInProgress: true});
        changeStatusByUser({orderId: this.state.deliveredId, status: 5, response: ''}).then(
            response => {
                this.loadUserOrders();
                this.setState({deliveredId: 0, deliveredDialog: false, deliveredInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Статус заказа изменен',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                console.log(error.message);
                this.setState({deliveredId: 0, deliveredDialog: false, deliveredInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при изменении статуса заказа',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };


    sendOnCloseReport = () => {
        this.setState({reportOpen: false, orderId: 0});
    };

    reportOnOpen = (orderId) => {
        this.setState({reportOpen: true, orderId: orderId});
    };

    sendReport = () => {
        this.setState({sendingInProgressReport: true});
        reportOrder(this.state.orderId, this.state.messageReport).then(
            response => {
                this.setState({messageReport: '', reportOpen: false, sendingInProgressReport: false, orderId: 0});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Жалоба отправлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({messageReport: '', reportOpen: false, sendingInProgressReport: false, orderId: 0});
            }
        );
    };

    onSendCode = () => {
        sendCodeToConfirm({number: this.state.phoneNumber.replace(/[^0-9]+/g, ''), code: this.state.code}).then(
            response => {
                this.props.dispatch(saveUser(JSON.parse(response)));
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Данные сохранены',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
                this.onDialogClose();
            },
            error => {
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Неверный код. Попробуйте еще раз.',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    onGetSms = () => {
        let phone = this.state.phoneNumber;
        if (!phone) {
            this.props.dispatch(sendNotification({
                open: true,
                messageInfo: {
                    message: 'Введите корректный номер телефона',
                    key: new Date().getTime(),
                    variant: 'error'
                }
            }));
            return;
        }
        phone = phone.replace(/[^0-9]+/g, '');
        if (phone.length !== 12) {
            this.props.dispatch(sendNotification({
                open: true,
                messageInfo: {
                    message: 'Введите корректный номер телефона',
                    key: new Date().getTime(),
                    variant: 'error'
                }
            }));
            return;
        }
        this.setState({inProcess: true}, () => {
            getSmsToConfirm({number: phone}).then(
                response => {
                    this.setState({inProcess: false});
                    this.onCodeExist();
                },
                error => {
                    const err = JSON.parse(error.message);
                    if (err.code === 54) {
                        this.props.dispatch(sendNotification({
                            open: true,
                            messageInfo: {
                                message: 'Слишком много попыток. Попробуйте позже.',
                                key: new Date().getTime(),
                                variant: 'error'
                            }
                        }));
                    }else {
                        this.props.dispatch(sendNotification({
                            open: true,
                            messageInfo: {
                                message: 'Не удалось отправить смс, проверьте номер телефона',
                                key: new Date().getTime(),
                                variant: 'error'
                            }
                        }));
                    }

                    this.setState({inProcess: false})
                }
            );
        });


    };

    onCodeExist = () => {
        if (!this.state.phoneNumber || this.state.phoneNumber.replace(/[^0-9]+/g, '').length !== 12){
            this.props.dispatch(sendNotification({
                open: true,
                messageInfo: {
                    message: 'Введите корректный номер телефона',
                    key: new Date().getTime(),
                    variant: 'error'
                }
            }));

            return;
        }
        this.setState({codeIsExist: true})
    };

    onChangeReport = (ev) => {

        let messageReport = ev.target.value;
        this.setState({messageReport});
    };

    render() {
        return (
            <div>
                {this.state.logout ? <Redirect to={'/'}/> : ''}
                {this.state.successRedirect ? <Redirect to={'/cabinet'}/> : ''}
                {this.props.user.name ?
                    <div className="store-box">
                        <div className="row">
                        <div style={{
                            width: '143px',
                            display: 'inline-block',
                            marginLeft: '15px',
                            marginTop: '27px'
                        }}>
                            <LinearProgress style={{display: this.state.displayProgress ? 'block' : 'none'}}
                                            ref='progress' variant="determinate" value={this.state.progress}/>
                            <div ref='mainPhoto' className="user-page-photo"
                                 style={{
                                     backgroundImage: 'url("' + (this.props.user.photoMid ? this.props.user.photoMid : '/images/avatar.png') + '")',
                                     backgroundSize: (this.props.user.photoMid ? 'cover' : 'contain'),
                                     backgroundRepeat: 'no-repeat',
                                     backgroundPositionX: 'center',
                                     backgroundPositionY: 'center',
                                     borderRadius: '50%'
                                 }}
                            >

                                <button type='file' className='btn btn-secondary create-btn-min photo-actions'
                                        onClick={this.selectPhoto}>Загрузить
                                </button>

                            </div>


                            {/*<div style={{marginTop: '8px', marginLeft: '23px'}} className="main-card-store-pin">*/}
                            {/*<span*/}
                            {/*    className="main-card-store-location">{moment(this.props.user.createdDate).fromNow(true)} на Crafty</span>*/}
                            {/*</div>*/}
                            <div className='d-flex justify-content-center' style={{
                                marginTop: '10px',
                                width: '143px',
                                marginLeft: '3px'
                            }}>
                                {/*<button className='btn btn-secondary create-btn-min'*/}
                                {/*        style={{borderRadius: '5px 0 0 5px'}}*/}
                                {/*        onClick={this.onPasswordChangeDialog}>Сменить пароль*/}
                                {/*</button>*/}
                                {/*<button className='basket-remove-button'*/}
                                {/*        onClick={this.onLogout}>Выйти*/}
                                {/*</button>*/}
                                <input ref='uploadPhoto' type='file' name='file' style={{display: 'none'}}
                                       onChange={this.uploadPhoto}/>
                            </div>
                        </div>


                        <div className="user-page-info" style={{marginTop: '32px'}}>
                            <div className='user-cabinet-edit-header'>
                                <span className='user-cabinet-edit-header'>Основная информация</span>
                            </div>
                            <div className='row'>
                                <div className='col' style={{maxWidth: '280px'}}>
                                    <div style={{marginTop: '16px'}} className="store-header-title">
                                        <span>Имя</span>
                                    </div>
                                    <div>
                                        <input type='text'
                                               className='input-text-element-280'
                                               value={this.state.user.name}
                                               onChange={this.nameOnChange}
                                               placeholder='Имя'
                                               style={{
                                                   paddingLeft: '20px',
                                                   marginTop: '10px'
                                               }}/>
                                    </div>
                                </div>
                                <div className='col' style={{maxWidth: '280px', marginLeft: '20px'}}>
                                    <div style={{marginTop: '16px'}} className="store-header-title">
                                        <span>Email</span>
                                    </div>
                                    <div>
                                        <input type='text'
                                               className='input-text-element-280'
                                               value={this.props.user.email}
                                               placeholder='Email'
                                               disabled={true}
                                               style={{
                                                   paddingLeft: '20px',
                                                   marginTop: '10px'
                                               }}/>

                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col' style={{maxWidth: '280px'}}>
                                    <div className="store-header-title" style={{marginTop: '20px'}}>
                                        <span>Страна</span>
                                    </div>
                                    <div>
                                        <input type='text'
                                               className='input-text-element-280'
                                               value={this.state.user.country}
                                               onChange={this.countryOnChange}
                                               placeholder='Страна'
                                               style={{
                                                   paddingLeft: '20px',
                                                   marginTop: '10px'
                                               }}/>
                                    </div>
                                </div>
                                <div className='col' style={{maxWidth: '280px', marginLeft: '20px'}}>
                                    <div className="store-header-title" style={{marginTop: '20px'}}>
                                        <span>Город</span>
                                    </div>
                                    <div>
                                        <input type='text'
                                               className='input-text-element-280'
                                               value={this.state.user.city}
                                               onChange={this.cityOnChange}
                                               placeholder='Город'
                                               style={{
                                                   paddingLeft: '20px',
                                                   marginTop: '10px'
                                               }}/>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col' style={{maxWidth: '280px'}}>
                                    <div className="store-header-title" style={{marginTop: '20px'}}>
                                        <span>Адрес</span>
                                    </div>
                                    <div>
                                        <input type='text'
                                               className='input-text-element-280'
                                               value={this.state.user.address}
                                               onChange={this.addressOnChange}
                                               placeholder='Адрес'
                                               style={{
                                                   paddingLeft: '20px',
                                                   marginTop: '10px'
                                               }}/>
                                    </div>
                                </div>

                            </div>

                            <div className='row'>
                                <div className='col' style={{maxWidth: '580px', marginTop: '16px'}}>
                                    <div className="store-header-title">
                                        <span>Обо мне</span>
                                    </div>
                                    <div>
                                    <textarea
                                        className='textarea-element-580'
                                        value={this.state.user.description}
                                        onChange={this.descriptionOnChange}
                                        placeholder='Опишите себя'
                                        style={{
                                            padding: '20px',
                                            marginTop: '10px'
                                        }}/>
                                    </div>
                                </div>

                            </div>


                            <div className="row" style={{marginLeft: '0px', marginTop: '32px'}}>
                                <div className='user-cabinet-edit-header'>
                                    <span className='user-cabinet-edit-header'>Подтверждение аккаунта</span>
                                </div>
                            </div>

                            <div className="row" style={{marginLeft: '0px', marginTop: '16px'}}>
                                <div className="col" style={{minWidth: '280px', paddingLeft: '0px'}}>
                                    <div className="store-header-title">
                                        <span>Телефон</span> <span style={this.props.user.phoneIsConfirmed ? {color: 'green'} : {color: 'red'}}>({this.props.user.phoneIsConfirmed ? 'Подтвержден' : "Не подтвержден"})</span>
                                    </div>
                                    <div>
                                        <input type='text'
                                               className='input-text-element-280'
                                               value={this.props.user.phone}
                                               onChange={this.phoneOnChange}
                                               placeholder='Телефон'
                                               disabled={true}
                                               style={{
                                                   paddingLeft: '20px',
                                                   marginTop: '10px'
                                               }}/>

                                    </div>
                                </div>

                                <div className="col" style={{minWidth: '280px', paddingLeft: '0px', marginLeft: '20px', marginTop: '30px'}}>
                                    <div style={{marginTop: '12px'}} className='user-cabinet-link'>
                                        <a onClick={this.onPhoneChangeDialog} href="javascript:void(0)">Изменить</a>
                                    </div>
                                </div>

                            </div>


                            <div className="row" style={{marginLeft: '0px', marginTop: '32px'}}>
                                <div className='user-cabinet-edit-header'>
                                    <span className='user-cabinet-edit-header'>Безопасность</span>
                                </div>
                            </div>
                            <div className="row" style={{marginLeft: '0px', marginTop: '16px'}}>
                                <div className="col" style={{minWidth: '280px', paddingLeft: '0px'}}>
                                    <div className="store-header-title">
                                        <span>Пароль</span>
                                    </div>
                                    <div>
                                        <input type='password'
                                               className='input-text-element-280'
                                               value={'password'}
                                               placeholder='Пароль'
                                               disabled={true}
                                               style={{
                                                   paddingLeft: '20px',
                                                   marginTop: '10px'
                                               }}/>

                                    </div>
                                </div>

                                <div className="col" style={{minWidth: '280px', paddingLeft: '0px', marginLeft: '20px', marginTop: '30px'}}>
                                    <div style={{marginTop: '12px'}} className='user-cabinet-link'>
                                        <a onClick={this.onPasswordChangeDialog} href="javascript:void(0)">Изменить</a>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div style={{
                                    marginTop: '32px',
                                    marginLeft: '15px'
                                }}>
                                    <button className="user-cabinet-save-btn " onClick={this.saveChange}>Сохранить</button>
                                </div>
                            </div>



                            <div className='row d-flex align-items-center' style={{
                                marginTop: '15px',
                                marginLeft: '0px'
                            }}>

                            </div>
                        </div>

                        </div>

                    </div>
                    : ''
                }

                <div>
                    <Dialog
                        open={this.state.openPhoneDialog}
                        onClose={this.onDialogClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title" style={{
                            color: '#283149',
                            fontFamily: 'Tinos',
                            fontSize: '30px',
                            lineHeight: '40px'
                        }}>Смена номера телефона</DialogTitle>
                        <DialogContent>
                            {
                                this.state.errorText ?
                                    <DialogContentText style={{
                                        color: '#E60936',
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                        lineHeight: '20px'
                                    }}>
                                        {this.state.errorText}
                                    </DialogContentText>
                                    : ''
                            }

                            <div className="store-header-title" style={{marginBottom: '20px'}}>
                                <span>Пример: +375 29 999 99 99</span>
                            </div>
                                <InputMask mask="+375 (\ 99 )\ 999 99 99"
                                           maskChar={""}
                                           autoFocus={!this.state.codeIsExist}
                                           disabled={this.state.codeIsExist}
                                           alwaysShowMask={true}
                                           className='input-text-element-280'
                                           value={this.state.phoneNumber}
                                           style={{paddingLeft: '10px'}}
                                           onChange={(val) => {
                                               this.setState({phoneNumber: val.target.value});
                                           }}
                                />

                            {
                                !this.state.codeIsExist ?
                                    <div>
                                        <a onClick={this.onCodeExist} href="javascript:void(0)">
                                            У меня уже есть код
                                        </a>
                                    </div>
                                    :
                                    <div style={{marginTop: '15px'}}>
                                        <InputMask mask="*****"
                                                   maskChar={""}
                                                   autoFocus={true}
                                                   style={{paddingLeft: '10px'}}
                                                   alwaysShowMask={true}
                                                   className='input-text-element-280'
                                                   value={this.state.code}
                                                   onChange={(val) => {
                                                       this.setState({code: val.target.value});
                                                   }}
                                        />
                                    </div>

                            }

                        </DialogContent>
                        <DialogActions>
                            <Button disabled={this.state.inProcess} onClick={this.onDialogClose} color="primary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.inProcess} onClick={this.state.codeIsExist ? this.onSendCode : this.onGetSms} color="secondary">
                                {this.state.codeIsExist ? 'Отправить' : 'Получить смс'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>





                <div>
                    <Dialog
                        open={this.state.openDialog}
                        onClose={this.onDialogClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title" style={{
                            color: '#283149',
                            fontFamily: 'Tinos',
                            fontSize: '30px',
                            lineHeight: '40px'
                        }}>Смена пароля</DialogTitle>
                        <DialogContent>
                            {
                                this.state.errorText ?
                                    <DialogContentText style={{
                                        color: '#E60936',
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                        lineHeight: '20px'
                                    }}>
                                        {this.state.errorText}
                                    </DialogContentText>
                                    : ''
                            }

                            <TextField
                                error={this.state.validate &&
                                (!this.state.changePassword.oldPassword
                                    || this.state.changePassword.oldPassword.trim().length < 1)
                                }
                                autoFocus
                                margin="dense"
                                label="Старый пароль"
                                type="password"
                                fullWidth
                                value={this.state.changePassword.oldPassword}
                                onChange={(val) => {
                                    let changePassword = this.state.changePassword;
                                    changePassword.oldPassword = val.target.value;
                                    this.setState({changePassword: changePassword});
                                }}
                            />
                            <TextField
                                error={this.state.validate
                                && (!this.state.changePassword.repeatNewPassword
                                    || this.state.changePassword.repeatNewPassword.trim().length < 1)}
                                margin="dense"
                                label="Новый пароль"
                                type="password"
                                fullWidth
                                value={this.state.changePassword.newPassword}
                                onChange={(val) => {
                                    let changePassword = this.state.changePassword;
                                    changePassword.newPassword = val.target.value;
                                    this.setState({changePassword: changePassword});
                                }}
                            />
                            <TextField
                                error={this.state.validate
                                && (this.state.changePassword.newPassword !== this.state.changePassword.repeatNewPassword)}
                                margin="dense"
                                label="Повторите новый пароль"
                                type="password"
                                fullWidth
                                value={this.state.changePassword.repeatNewPassword}
                                onChange={(val) => {
                                    let changePassword = this.state.changePassword;
                                    changePassword.repeatNewPassword = val.target.value;
                                    this.setState({changePassword: changePassword});
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={this.state.inProcess} onClick={this.onDialogClose} color="primary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.inProcess} onClick={this.onPasswordChange} color="secondary">
                                Сменить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>


            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        notification: state.notification,
        messages: state.messages
    };
};

UserCabinetEditPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps)(UserCabinetEditPage));

import React from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {Link, Redirect} from "react-router-dom";
import {updateUserInfo, uploadPhoto} from "../api/UserAPI";
import {changePhoto, loadUser, saveUser} from "../actions/user";
import {startLogoutAction} from "../actions/authorization";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import {changeStatusByUser, getUserOrderV2} from "../api/OrderAPI";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {sendNotification} from "../actions/notifications";
import {reportOrder} from "../api/ReportAPI";
import {getStore} from "../api/StoreAPI";
import {promSetUserPage} from "../actions/messages";
import Carousel, {Modal, ModalGateway} from "react-images";
import {deleteGift, getGifts, parseGiftUrl, saveGift} from "../api/GiftAPI";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LoadingPage from "./LoadingPage";
import Slide from "@material-ui/core/Slide/Slide";
import * as queryString from "query-string";
import {Helmet} from "react-helmet";
import * as VanillaSharing from 'vanilla-sharing';
import {logSocial} from "../api/LogAPI";
import {deletePush, loadValues, saveItem} from "../actions/localStorageValuesActions";
import {deleteClientId, saveClientId} from "../api/PushWebAPI";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import firebase from 'firebase/app';
import messaging from 'firebase/firebase-messaging';


function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3, paddingLeft: 0}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
        width: '960px'
    },
    tabsIndicator: {
        backgroundColor: '#E60936',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        fontSize: '20px',
        lineHeight: '30px',
        fontFamily: [
            'Tanos'
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#283149',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#283149',
            outline: 0
        },
    },
    tabSelected: {},
    paperWidthXl: {
        width: '795px'
    },
    titleRoot: {
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '24px',
        paddingBottom: '0px',
    },
    contentRoot: {
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingBottom: '24px',
        paddingTop: 0
    },
    typography: {
        padding: theme.spacing.unit * 3,
    },
});


class UserCabinetPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
            displayProgress: false,
            user: {
                id: this.props.user.id,
                name: this.props.user.name,
                country: this.props.user.country,
                city: this.props.user.city,
                phone: this.props.user.phone,
                description: this.props.user.description,
                address: this.props.user.address,
                photoMax: this.props.user.photoMax,
                photoMid: this.props.user.photoMid,
                photoMin: this.props.user.photoMin,
                phoneIsConfirmed: this.props.user.phoneIsConfirmed,
                confirmEmail: this.props.user.confirmEmail
            },
            logout: false,
            errorStyle: {
                borderBottom: '2px',
                borderBottomColor: '#E60936',
                borderBottomStyle: 'solid'
            },
            openDialog: false,
            validate: false,
            errorText: '',
            inProcess: false,
            changePassword: {
                oldPassword: '',
                newPassword: '',
                repeatNewPassword: ''
            },
            orders: null,
            filter: {
                page: 0,
                size: 40,
                sort: 'createdDate,desc'
            },
            gifts: {
                content: []
            },
            value: 1,
            deliveredDialog: false,
            deliveredInProgress: false,
            deliveredId: 0,
            messageReport: '',
            reportOpen: false,
            sendingInProgressReport: false,
            orderId: 0,
            store: null,
            anchorEl: null,
            orderStatusMenu: 0,
            photoIsOpen: false,
            giftIsOpen: false,
            giftIsOpenView: false,
            copied: false,
            gift: {
                url: '',
                title: '',
                description: '',
                image: ''
            },
            urlLoaded: false,
            loadingPage: false,
            titleError: false,
            descriptionError: false,
            imageError: false,
            urlError: false,
            giftToDelete: 0,
            deleteIsOpen: false,
            isEditing: false,
            pushClient: null,
            goodsPush: false,
            orderPush: false,
            isLoading: false

        };
        moment.locale('RU');
    }

    componentWillMount() {
        this.checkAuthorization();
        this.loadUserOrders();
        this.props.dispatch(loadValues());
    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        getGifts(this.props.user.id).then(
            response => {
                this.setState({gifts: JSON.parse(response)});
            },
            error => {
                // console.log(error);
            }
        );
        let params = queryString.parse(location.search);
        if (!params) {
            params.tab = '1';
            history.pushState(params, 'Кабинет', '?' + queryString.stringify(params));
        }else{
            if(params.tab && (params.tab === '0' || params.tab === '1')){
                this.setState({value: parseInt(params.tab)});
            }else{
                params.tab = '1';
                history.pushState(params, 'Кабинет', '?' + queryString.stringify(params));
            }
        }


    }

    selectPhoto = () => {
        this.refs.uploadPhoto.click();
    };

    loadUserOrders = () => {
        getUserOrderV2(this.state.filter).then(
            response => {
                let orders = JSON.parse(response);
                this.setState({orders});
            },
            error => {
                // console.log(error.message);
            }
        );

        if (this.props.user.storeId){
            getStore(this.props.user.storeId).then(
                response => {
                    let store = JSON.parse(response);
                    this.setState(() => ({
                        store: store
                    }));
                },
                error => {
                    // console.log(error);
                }
            );
        }

    };

    onProgress = (event) => {
        this.refs.mainPhoto.style.display = 'none';
        this.setState(() => ({displayProgress: true}));
        if (event.lengthComputable) {
            let complete = (event.loaded / event.total * 100 | 0);
            this.setState({progress: complete});
        }
    };

    uploadPhoto = () => {

        let formData = new FormData();
        formData.append('file', this.refs.uploadPhoto.files[0]);
        uploadPhoto(formData, this.onProgress).then(
            response => {
                this.props.dispatch(changePhoto(JSON.parse(response)));
                this.refs.mainPhoto.style.display = 'block';
                this.setState(() => ({displayProgress: false}));
            },
            error => {
                // console.log(error);
                this.refs.mainPhoto.style.display = 'block';
                this.setState(() => ({displayProgress: false}));
            }
        );
    };

    nameOnChange = (e) => {
        let user = {...this.state.user};
        user.name = e.target.value;
        this.setState(() => ({user}));
    };

    countryOnChange = (e) => {
        let user = {...this.state.user};
        user.country = e.target.value;
        this.setState(() => ({user}));
    };

    cityOnChange = (e) => {
        let user = {...this.state.user};
        user.city = e.target.value;
        this.setState(() => ({user}));
    };

    checkAuthorization = () => {
        if (!this.props.user.name) {
            this.setState({logout: true});
        }
    };

    onLogout = () => {
        this.props.dispatch(startLogoutAction()).then(() => {
            this.setState(() => ({logout: true}));
        });
    };

    sendOnOpen = (orderId) => {
        this.setState({deliveredDialog: true, deliveredId: this.state.orderId});
    };

    sendOnClose = () => {
        this.setState({deliveredDialog: false, deliveredId: 0});
    };

    onDialogClose = () => {
        if (!this.state.inProcess) {
            let changePassword = {
                oldPassword: '',
                newPassword: '',
                repeatNewPassword: ''
            };
            this.setState({openDialog: false, changePassword: changePassword});
        }
    };

    getOrderStatus = (status) => {
        switch (status) {
            case 2:
                return 'Подтвержден';
            case 1:
                return 'Ожидает ответа';
            case 3:
                return 'Отменен';
            case 4:
                return 'Отправлен';
            case 5:
                return 'Доставлен';
        }
    };

    handleChange = (event, value) => {
        let params = queryString.parse(location.search);
        params.tab = new String(value);
        history.pushState(params, 'Кабинет', '?' + queryString.stringify(params));
        this.setState({value});
    };

    sendStatusToChange = () => {
        this.setState({deliveredInProgress: true});
        changeStatusByUser({orderId: this.state.deliveredId, status: 5, response: ''}).then(
            response => {
                this.loadUserOrders();
                this.setState({deliveredId: 0, deliveredDialog: false, deliveredInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Статус заказа изменен',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                // console.log(error.message);
                this.setState({deliveredId: 0, deliveredDialog: false, deliveredInProgress: false});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при изменении статуса заказа',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };


    giftIsOpenView = (id) => {
        let gift = {};
        let filteredGift = this.state.gifts.content.filter(value => value.id === id);
        if (filteredGift){
            gift = filteredGift[0];
        }
        this.setState({giftIsOpenView: true, gift: gift});
    };

    giftOnCloseView = () => {
        this.setState({
            giftIsOpen: false,
            giftIsOpenView: false,
            loadingPage: false,
            titleError: false,
            descriptionError: false,
            imageError: false,
            urlError: false,
            urlLoaded: false,
            isEditing: false,
            gift: {
                url: '',
                title: '',
                description: '',
                image: ''
            }
        });
    };



    sendOnCloseReport = () => {
        this.setState({reportOpen: false, orderId: 0});
    };

    reportOnOpen = (orderId) => {
        this.setState({reportOpen: true, orderId: orderId});
    };

    Transition = (props) => {
        return <Slide direction="up" {...props} />;
    };


    sendReport = () => {
        this.setState({sendingInProgressReport: true});
        reportOrder(this.state.orderId, this.state.messageReport).then(
            response => {
                this.setState({messageReport: '', reportOpen: false, sendingInProgressReport: false, orderId: 0});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Жалоба отправлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.setState({messageReport: '', reportOpen: false, sendingInProgressReport: false, orderId: 0});
            }
        );
    };

    urlOnChange = (ev) => {
        let url = ev.target.value;
        this.setState({gift: {...this.state.gift, url: url}});
    };

    titleOnChange = (ev) => {
        let title = ev.target.value;
        if (title && title.length > 299) {
            title = title.substring(0, 298);
        }

        this.setState({gift: {...this.state.gift, title: title}});
    };

    descriptionOnChange = (ev) => {
        let description = ev.target.value;
        if (description && description.length > 1199) {
            description = description.substring(0, 1198);
        }

        this.setState({gift: {...this.state.gift, description: description}});
    };

    onChangeReport = (ev) => {

        let messageReport = ev.target.value;
        this.setState({messageReport});
    };

    handleCloseMenu = () => {
        this.setState({orderStatusMenu: 0, orderId: 0}, () => {
            document.removeEventListener('click', this.handleCloseMenu);
        });
    };

    handleClickMenu = (event, orderId, orderStatus) => {
        this.setState({orderId: orderId, orderStatusMenu: orderStatus}, () => {
            document.addEventListener('click', this.handleCloseMenu);
        });

    };


    onDeleteGiftClose = () => {
        this.setState({giftToDelete: 0, deleteIsOpen: false});
    };

    sendMessage = () => {
        let order = this.state.orders.content.filter(o => o.orderId === this.state.orderId)[0];


        this.props.dispatch(promSetUserPage(order.store.owner)).then(() => {
                document.getElementById('messengerBt').click();
            }
        );
        this.setState({orderId: 0});

    };

    toggleViewer = () => {
        this.setState(() => ({photoIsOpen: !this.state.photoIsOpen}));
    };

    giftIsOpen = () => {
        this.setState({giftIsOpen: true});
    };

    giftOnClose = () => {

        if (!this.state.isEditing && this.state.gift.title) {
            this.setState({loadingPage: true});
            deleteGift(this.state.gift.id).then(
                response => {
                    this.setState({gifts: JSON.parse(response), giftToDelete: 0,
                        deleteIsOpen: false,
                        giftIsOpen: false,
                        giftIsOpenView: false,
                        loadingPage: false,
                        titleError: false,
                        descriptionError: false,
                        imageError: false,
                        urlError: false,
                        urlLoaded: false,
                        isEditing: false,
                        gift: {
                            url: '',
                            title: '',
                            description: '',
                            image: ''
                        }});
                }, error => {
                    this.setState({giftToDelete: 0, deleteIsOpen: false, loadingPage: false, isEditing: false});

                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Ошибка при удалении данных, попробуйте позже или обновите страницу',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
            );
        }



        this.setState({
            giftIsOpen: false,
            giftIsOpenView: false,
            loadingPage: false,
            titleError: false,
            descriptionError: false,
            imageError: false,
            urlError: false,
            urlLoaded: false,
            isEditing: false,
            gift: {
                url: '',
                title: '',
                description: '',
                image: ''
            }
        });
    };


    sendForParse = () => {
        this.setState({loadingPage: true});
        parseGiftUrl({url: this.state.gift.url}).then(
            response => {
                this.setState({
                    gift: JSON.parse(response),
                    urlLoaded: true,
                    titleError: false,
                    descriptionError: false,
                    imageError: false,
                    urlError: false,
                    loadingPage: false});
            },
            error => {
                this.setState({urlError: true, loadingPage: false});
                // console.log(error);
            }
        );
    };

    saveGiftAfterEdit = () => {
        let gift = {...this.state.gift};

        if (!gift.title || !gift.title.trim().length === 0){
            this.setState({titleError: true});
            return;
        }

        gift.title = gift.title.trim();

        if (!!gift.description) {
            gift.description = gift.description.trim();
        }

        this.setState({loadingPage: true});
        saveGift(gift).then(
            response => {
                this.setState({
                    gifts: JSON.parse(response),
                    urlLoaded: false,
                    loadingPage: false,
                    giftIsOpen: false,
                    isEditing: false,
                    titleError: false,
                    descriptionError: false,
                    imageError: false,
                    urlError: false,
                    gift: {
                        url: '',
                        title: '',
                        description: '',
                        image: ''
                    }});
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Запись добавлена',
                        key: new Date().getTime(),
                        variant: 'success'
                    }
                }));
            },
            error => {
                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при сохранении данных, попробуйте позже или обновите страницу',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };

    onEditGift = (id) => {
        let gift = this.state.gifts.content.filter(value => value.id === id);


        this.setState({gift: gift[0], giftIsOpen: true, urlLoaded: true, isEditing: true});

    };

    onDeleteGift = (id) => {
        this.setState({giftToDelete: id, deleteIsOpen: true});
    };

    onDeleteGiftPressed = () => {
        this.setState({loadingPage: true});
        deleteGift(this.state.giftToDelete).then(
            response => {
                this.setState({gifts: JSON.parse(response), giftToDelete: 0, deleteIsOpen: false});
                this.setState({loadingPage: false});

            }, error => {
                this.setState({giftToDelete: 0, deleteIsOpen: false, loadingPage: false});

                this.props.dispatch(sendNotification({
                    open: true,
                    messageInfo: {
                        message: 'Ошибка при удалении данных, попробуйте позже или обновите страницу',
                        key: new Date().getTime(),
                        variant: 'error'
                    }
                }));
            }
        );
    };




    logSocialShared = (type) => {
        const userId = this.props.user.name ? this.props.user.id : null;
        logSocial({page: 'https://crafty.by/user/' + this.state.product.id, userId: userId, type: type});
    };



    sharePinterest= () => {
        VanillaSharing.pinterest({
            url: ('https://crafty.by/user/' + this.state.user.id),
            description: (this.state.user.name + '. ' + this.state.user.description),
            media: this.state.user.photoMax
        });
        this.logSocialShared('PINTEREST');

    };

    shareFacebook = () => {
        VanillaSharing.fbButton({
            url: ('https://crafty.by/user/' + this.state.user.id)
        });
        this.logSocialShared('FACEBOOK');

    };


    shareVk = () => {
        VanillaSharing.vk({
            url: ('https://crafty.by/user/' + this.state.user.id),
            title: this.state.user.name,
            description: this.state.user.description,
            image: this.state.user.photoMax,
            isVkParse: true,
        });
        this.logSocialShared('VK');

    };

    shareOk = () => {
        VanillaSharing.ok({
            url: ('https://crafty.by/user/' + this.state.user.id),
            title: this.state.user.name,
            image: this.state.user.photoMax
        });
        this.logSocialShared('OK');

    };

    shareViber = () => {
        VanillaSharing.viber({
            url: ('https://crafty.by/user/' + this.state.user.id),
            title: this.state.user.name,
        });
        this.logSocialShared('VIBER');

    };


    shareTelegram = () => {
        VanillaSharing.telegram({
            url: ('https://crafty.by/user/' + this.state.user.id),
            title: this.state.user.name,
        });
        this.logSocialShared('TELEGRAM');

    };

    shareWhatsApp = () => {
        VanillaSharing.whatsapp({
            url: ('https://crafty.by/user/' + this.state.user.id),
            title: this.state.user.name,
        });
        this.logSocialShared('WHATSAPP');
    };


    onSubscribe = () => {
        this.setState({isLoading: true});

        if (this.props.localStorageValues.pushClient){
            deleteClientId({clientId: this.props.localStorageValues.pushClient}).then(
                response => {
                    this.props.dispatch(deletePush());
                    this.setState({isLoading: false});
                }, error => {
                    this.props.dispatch(deletePush());
                    this.setState({isLoading: false});
                }
            ).catch(
                error => {
                    this.props.dispatch(deletePush());
                    this.setState({isLoading: false});
                }
            )
        }else{

            const messaging = firebase.messaging();
            // запрашиваем разрешение на получение уведомлений
            Notification.requestPermission()
                .then(() => {
                    // получаем ID устройства
                    messaging.getToken()
                        .then((currentToken) => {
                            if (currentToken) {
                                this.setState({isLoading: true});
                                saveClientId({clientId: currentToken}).then(
                                    response => {
                                        this.setState({isLoading: false});
                                        this.props.dispatch(saveItem({key: 'nt', value: currentToken, object: {pushClient: currentToken}}));

                                    }, error => {
                                        this.setState({isLoading: false});
                                        console.log(error);
                                    }
                                );
                            } else {
                                this.setState({isLoading: false});
                                console.warn('Не удалось получить токен.');
                            }
                        })
                        .catch((err) => {
                            this.setState({isLoading: false});
                            console.warn('При получении токена произошла ошибка.', err);
                        });
                })
                .catch((err) => {
                    this.setState({isLoading: false});
                    console.warn('Не удалось получить разрешение на показ уведомлений.', err);
                });
        }


    };



    render() {

        const {classes} = this.props;
        const {value} = this.state;

        return (
            <div>
                {this.state.logout ? <Redirect to={'/'}/> : ''}
                {this.props.user.name ?
                    <div className="store-box">
                        <Helmet>
                            <title>{this.props.user.name}</title>
                        </Helmet>
                        <div className="row">
                            <div style={{
                                width: '143px',
                                display: 'inline-block',
                                marginLeft: '15px',
                                marginTop: '27px'
                            }}>
                                <a href="javascript:void(0)">
                                    <div ref='mainPhoto' className="user-page-photo"
                                         onClick={() => {
                                             if (!!this.state.user.photoMax) {
                                                 this.toggleViewer();
                                             }
                                         }}
                                         style={{
                                             backgroundImage: 'url("' + (this.props.user.photoMid ? this.props.user.photoMid : '/images/avatar.png') + '")',
                                             backgroundSize: (this.props.user.photoMid ? 'cover' : 'contain'),
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPositionX: 'center',
                                             backgroundPositionY: 'center',
                                             borderRadius: '50%'
                                         }}
                                    >
                                    </div>
                                </a>
                                <div style={{marginTop: '8px', marginLeft: '23px'}} className="main-card-store-pin">
                            <span
                                className="main-card-store-location">{moment(this.props.user.createdDate).fromNow(true)} на Crafty</span>
                                </div>
                                <div className='d-flex justify-content-center' style={{
                                    marginTop: '10px',
                                    width: '143px',
                                    marginLeft: '3px'
                                }}>
                                    <button className='basket-remove-button'
                                            onClick={this.onLogout}>Выйти
                                    </button>
                                </div>
                            </div>


                            <div className="user-page-info" style={{marginTop: '32px'}}>
                                <div className='user-cabinet-name'>
                                    <span>{this.state.user.name}</span>
                                </div>
                                {
                                    (this.state.user.country || this.state.user.city || this.state.user.address) ?
                                        <div>
                                            <div style={{marginTop: '8px'}} className="main-card-store-pin">
                                                <div className="main-card-store-pin-icon"></div>
                                                <span
                                                    className="user-cabinet-location">{
                                                    (this.state.user.country ? (this.state.user.country) : '')
                                                    + (this.state.user.city ? (', ' + this.state.user.city) : '')
                                                    + (this.state.user.address ? (', ' + this.state.user.address) : '')
                                                }</span>
                                            </div>
                                        </div>
                                        : ''
                                }

                                <div style={{marginTop: '6px'}}>
                                    <span className='user-cabinet-location'>{this.state.user.phone}</span> <span className="user-cabinet-location" style={this.state.user.phoneIsConfirmed ? {color: 'green'} : {color: 'red'}}>({this.state.user.phoneIsConfirmed ? 'Подтвержден' : "Не подтвержден"})</span>
                                </div>

                                <div style={{marginTop: '6px'}}>
                                    <span className='user-cabinet-location'>{this.props.user.email}</span> <span className="user-cabinet-location" style={this.state.user.confirmEmail ? {color: 'green'} : {color: 'red'}}>({this.state.user.confirmEmail ? 'Подтвержден' : "Не подтвержден"})</span>
                                </div>

                                <div style={{marginTop: '12px'}} className='user-cabinet-link'>
                                    <Link to={'/cabinet/edit'}>Редактировать</Link>
                                </div>


                                <div className='row d-flex align-items-center' style={{
                                    marginTop: '15px',
                                    marginLeft: '0px'
                                }}>

                                </div>
                            </div>
                            {
                                this.props.user.description ?
                                    <div className="user-cabinet-page-description">
                                        <span className='user-cabinet-description'>{this.props.user.description}</span>
                                    </div>
                                    : ''
                            }

                            {
                                this.props.user.storeId && this.state.store && this.state.store.name ?
                                    <div style={{marginTop: '40px', marginLeft: '44px'}}>
                                        <div className='row d-flex align-items-center' style={{
                                            marginTop: '15px',
                                            marginLeft: '0px'
                                        }}>
                                            <div className='user-page-store-photo'
                                                 style={{
                                                     backgroundImage: 'url("' + this.state.store.photoMid + '")',
                                                     width: '50px',
                                                     height: '50px',
                                                     backgroundSize: 'cover',
                                                     backgroundRepeat: 'no-repeat',
                                                     backgroundPositionX: 'center',
                                                     backgroundPositionY: 'center'
                                                 }}
                                            ></div>
                                            <div style={{
                                                maxWidth: '200px', overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                color: '#1273CE',
                                                fontFamily: 'Open Sans'
                                            }}>
                                                <Link to={'/store/' + this.state.store.id}
                                                      className="name-owner align-middle"
                                                      style={{
                                                          marginLeft: '15px',
                                                          marginTop: '0'
                                                      }}>{this.state.store.name}</Link>
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            }

                            {/*<div style={{*/}
                            {/*    height: '1px',*/}
                            {/*    width: '960px',*/}
                            {/*    borderRadius: '1px',*/}
                            {/*    backgroundColor: '#CFD6D8',*/}
                            {/*    marginTop: '52px',*/}
                            {/*    marginLeft: '15px',*/}
                            {/*    display: 'block'*/}
                            {/*}}>*/}

                            {/*</div>*/}

                        </div>

                        {
                            (this.props.user.name && this.props.user.roles.filter(role => (role.name === 'ROLE_MANAGER'
                                || role.name === 'ROLE_ADMIN')).length > 0)
                                ? <div style={{marginTop: '20px', marginLeft: '15px'}}>
                                    <Link to={'/admin/approve'}>Модерация товаров</Link> <br/>
                                    <Link to={'/admin/store/approve'}>Модерация магазинов</Link>
                                </div>
                                : ''
                        }
                        {
                            (this.props.user.name && this.props.user.roles.filter(role => (role.name === 'ROLE_ADMIN')).length > 0)
                                ? <div style={{marginTop: '20px', marginLeft: '15px'}}>
                                    <br/>
                                    <Link to={'/admin/users'}>User List</Link>
                                </div>
                                : ''
                        }

                        {
                            (!this.props.userAgent.isIOS && (this.props.userAgent.isChrome || this.props.userAgent.isFirefox || this.props.userAgent.isOpera)) ?
                                <div>
                                    <div style={{marginTop: 40, width: 960}}>
                                        <span className='order-info-label'>Подключение Push уведомлений на этом устройстве</span>
                                    </div>
                                    <div style={{position: 'relative'}}>
                                        <FormControlLabel
                                            style={{display: this.state.isLoading ? 'none' : 'block'}}
                                            control={
                                                <Checkbox
                                                    icon={<div style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundImage: 'url(/images/default.png)',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPositionX: 'center',
                                                        backgroundPositionY: 'center',
                                                    }}>

                                                    </div>}
                                                    checkedIcon={<div style={{
                                                        width: '20px',
                                                        height: '20px',
                                                        backgroundImage: 'url(/images/filled.png)',
                                                        backgroundSize: 'cover',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPositionX: 'center',
                                                        backgroundPositionY: 'center',
                                                    }}>

                                                    </div>}
                                                    value={true}
                                                    checked={!!this.props.localStorageValues.pushClient}
                                                    onChange={this.onSubscribe}
                                                />
                                            }
                                            label={<span className='user-cabinet-description'>Включить уведомления</span>}
                                        />
                                        {
                                            this.state.isLoading ?
                                                <div  style={{position: 'relative', top: 0}}>
                                                    <CircularProgress />
                                                </div>
                                                : ''
                                        }
                                    </div>
                                </div>
                                :''
                        }


                        <Tabs
                            id='orders'
                            style={{marginTop: '30px'}}
                            value={value}
                            onChange={this.handleChange}
                            classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
                        >
                            <Tab
                                disableRipple
                                classes={{root: classes.tabRoot, selected: classes.tabSelected}}
                                label="Мои покупки"
                            />
                            <Tab
                                disableRipple
                                classes={{root: classes.tabRoot, selected: classes.tabSelected}}
                                label="Хочу в подарок"
                            />
                        </Tabs>

                        {value === 0 && <TabContainer style={{padding: '0px'}}>
                            <div style={{marginTop: '0px', width: '960px', marginLeft: '0px', padding: 0}}>
                                <div>
                                    {
                                        !!this.state.orders ? this.state.orders.content.map((order, idx) => {
                                                return (
                                                    <div style={{marginTop: idx === 0 ? '32px' : '48px'}}
                                                         key={order.orderId}>
                                                        <div className='order-header-div'>
                                                            <div className='inline-block'
                                                                 style={{marginLeft: '16px', marginTop: '14px'}}>
                                                                {
                                                                    order.store ?
                                                                        <Link to={'/store/' + order.store.id}
                                                                              className="order-header-store">{order.store.name}</Link>
                                                                        :
                                                                        <span className='order-header-store'>{order.storeName}</span>
                                                                }

                                                            </div>
                                                            <div className="inline-block"
                                                                 style={{marginLeft: '12px', marginTop: '16px'}}>
                                                                <span
                                                                    className='order-header-number'>Заказ № {order.orderId} от {moment(order.createdDate).format('DD MMMM, YYYY').toString()}</span>
                                                            </div>
                                                            <div className='inline-block' style={{
                                                                float: 'right',
                                                                marginRight: '16px',
                                                                marginTop: '16px'
                                                            }}>
                                                                <span
                                                                    className=''>{this.getOrderStatus(order.status)}</span>
                                                            </div>
                                                        </div>
                                                        <div className='row' style={{marginTop: '30px', marginLeft: '0px'}}>
                                                            <div className="order-goods-image col"
                                                                 style={ order.item ? {
                                                                     backgroundImage: 'url("' + (order.item.photos.filter((ph) => {
                                                                         return ph.orderPlace === 1
                                                                     }).length > 0 ? order.item.photos.filter((ph) => {
                                                                         return ph.orderPlace === 1
                                                                     })[0].photoMin : '') + '")',
                                                                     backgroundSize: 'cover',
                                                                     backgroundRepeat: 'no-repeat',
                                                                     backgroundPositionX: 'center',
                                                                     backgroundPositionY: 'center',
                                                                     maxWidth: '123px'
                                                                 } : {
                                                                     maxWidth: '123px',
                                                                     backgroundColor: '#F5F7F7'
                                                                 }}
                                                            >
                                                                {
                                                                    order.item ?
                                                                    order.status === 1
                                                                        ? <img
                                                                            style={{
                                                                                marginTop: '38px',
                                                                                marginLeft: '23px'
                                                                            }}
                                                                            src="/images/waiting.svg"
                                                                            className="status-icon"/>
                                                                        : order.status === 4 ?
                                                                        <img
                                                                            style={{
                                                                                marginTop: '38px',
                                                                                marginLeft: '23px'
                                                                            }}
                                                                            src="/images/sent.svg"
                                                                            className="status-icon"/>
                                                                        : ''
                                                                        : <div className='basket-remove-button' style={{
                                                                            marginTop: '52px',
                                                                            marginLeft: '8px'
                                                                        }}>товар удален</div>
                                                                }

                                                            </div>
                                                            <div className='col' style={{minWidth: '500px'}}>
                                                                <div style={{marginTop: '-6px', marginBottom: '8px'}}>
                                                                    {
                                                                        order.item ?
                                                                            <Link className="order-goods-name"
                                                                                  to={'/product/' + order.item.id}>{order.item.name}</Link>
                                                                            :
                                                                            <div>
                                                                                <span className='order-goods-name'>{order.goodsName}</span>
                                                                                {/*<span className='basket-remove-button'> (товар удален)</span>*/}
                                                                            </div>
                                                                    }

                                                                </div>
                                                                {order.address ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span className='order-info-label'>Адрес: </span>
                                                                        <span
                                                                            className='order-info-text'>{order.address}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {order.phone ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span className='order-info-label'>Телефон: </span>
                                                                        <span
                                                                            className='order-info-text'>{order.phone}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {order.delivery ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span
                                                                            className='order-info-label'>Тип доставки: </span>
                                                                        <span
                                                                            className='order-info-text'>{order.delivery}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {order.comment ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span
                                                                            className='order-info-label'>Комментарий: </span>
                                                                        <span
                                                                            className='order-info-text'>{order.comment}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {order.response ?
                                                                    <div style={{marginBottom: '6px'}}>
                                                                        <span
                                                                            className='order-info-label'>Ответ магазина: </span>
                                                                        <span
                                                                            className='order-info-text'>{order.response}</span>
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                            <div className='col'
                                                                 style={{textAlign: 'right', paddingRight: '20px'}}>
                                                                <span
                                                                    className='order-amount-cost'>{order.amount} X {order.cost} BYN</span>
                                                            </div>
                                                            <div className="col"
                                                                 style={{textAlign: 'right', paddingRight: '20px'}}>
                                                                <div>
                                                                    <span
                                                                        className='order-cost-sum'>{order.cost * order.amount} BYN</span>
                                                                </div>
                                                                <div className='order-action-menu'
                                                                     style={{marginTop: '12px'}}>
                                                                    <button
                                                                        className='order-action-menu'
                                                                        style={{backgroundColor: '#FFFFFF', border: 0}}
                                                                        onClick={(e) => {
                                                                            this.handleClickMenu(e, order.orderId, order.status);
                                                                        }}
                                                                    >
                                                                        Выберите

                                                                        <img id={'img-' + order.orderId}
                                                                             style={{marginLeft: '6px'}}
                                                                             src={order.orderId === this.state.orderId ? "/images/arrow-up.svg" : "/images/arrow-down.svg"}
                                                                             className="arrow-icon"/>
                                                                    </button>
                                                                    <div id={'menu' + order.orderId}
                                                                         className={this.state.orderId === order.orderId ? "dropdown-menu show" : 'dropdown-menu'}
                                                                         style={{top: '4rem'}}>

                                                                        {
                                                                            this.state.orderStatusMenu === 4 ?
                                                                                <div>
                                                                                    <button
                                                                                        className="dropdown-item dropdown-item-add"
                                                                                        onClick={(e) => {
                                                                                            this.sendOnOpen(order.orderId);
                                                                                            this.handleCloseMenu();
                                                                                        }}>
                                                                                        Подтведить получение
                                                                                    </button>

                                                                                </div>
                                                                                : ''
                                                                        }
                                                                        <button
                                                                            className="dropdown-item dropdown-item-add"
                                                                            onClick={() => {
                                                                                this.sendMessage();
                                                                                this.handleCloseMenu();
                                                                            }}>
                                                                            Написать продавцу
                                                                        </button>
                                                                        <button
                                                                            className="dropdown-item dropdown-item-add"
                                                                            onClick={() => {
                                                                                this.reportOnOpen(this.state.orderId);
                                                                                this.handleCloseMenu();
                                                                            }}>
                                                                            Пожаловаться
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : ''
                                    }
                                </div>
                            </div>

                        </TabContainer>
                        }
                        {value === 1 && <TabContainer style={{padding: '0px'}}>
                            <div>
                                <div style={{marginTop: 0, width: 960}}>
                                    <span className='order-info-label'>Добавляйте то, что хотите получить в подарок, и делитесь списком.</span>
                                </div>
                                <div className="store-header-product" style={{marginTop: 20}}>
                                    <span>Что я хочу в подарок</span>
                                    <div className='inline' style={{marginLeft: '2px', float: 'right', marginRight: '30px'}}>
                                        <button onClick={this.giftIsOpen} className='btn-link-danger-mid-button'>Добавить</button>
                                    </div>
                                </div>
                                <div style={{ marginTop: 20}}>
                                    <div>
                                        <span className='order-info-label'>Отправляйте ссылку на список друзьям и родственникам, чтобы они не ломали голову, что вам подарить!</span>
                                    </div>
                                    <div style={{width: 1010, marginTop: 20}} className='d-flex justify-content-start'>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none", paddingLeft: 0}} onClick={this.sharePinterest}>
                                            <img style={{width: 30, height: 30}} src="/images/pinterestshare.svg" alt="Поделиться в Pinterest"/>
                                        </button>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareFacebook}>
                                            <img style={{width: 30, height: 30}} src="/images/fbshare.svg" alt="Поделиться в Facebook"/>
                                        </button>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareVk}>
                                            <img style={{width: 30, height: 30}} src="/images/vkshare.svg" alt="Поделиться во Вконтакте"/>
                                        </button>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareOk}>
                                            <img style={{width: 30, height: 30}} src="/images/okshare.svg" alt="Поделиться в Одноклассниках"/>
                                        </button>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareTelegram}>
                                            <img style={{width: 30, height: 30}} src="/images/telegramshare.svg" alt="Поделиться в Telegram"/>
                                        </button>
                                        <button className='button-social'  style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareWhatsApp}>
                                            <img style={{width: 30, height: 30}} src="/images/whatsappshare.svg" alt="Поделиться в WhatsApp"/>
                                        </button>
                                        <button style={{backgroundColor: '#FFFFFF', border: "none", outline: "none"}} onClick={this.shareViber}>
                                            <img style={{width: 30, height: 30}} src="/images/vibershare.svg" alt="Поделиться в Viber"/>
                                        </button>
                                    </div>
                                    <div style={{marginTop: 20}}>
                                        <a target='_blank' href={'https://crafty.by/user/'+this.props.user.id}>{'https://crafty.by/user/'+this.props.user.id}</a>

                                        <CopyToClipboard text={'https://crafty.by/user/'+this.props.user.id}
                                                         onCopy={() => {
                                                             this.props.dispatch(sendNotification({
                                                                 open: true,
                                                                 messageInfo: {
                                                                     message: 'Ссылка скопирована',
                                                                     key: new Date().getTime(),
                                                                     variant: 'success'
                                                                 }
                                                             }));
                                                         }}>
                                            <button
                                                style={{
                                                    color: '#404b69',
                                                    fontFamily: "Open Sans",
                                                    background: '#FFFFFF',
                                                    outline: 'none',
                                                    borderRadius: '5px',
                                                    border: 'solid 1px #404b69',
                                                    marginLeft: 20,
                                                    fontSize: 16,
                                                    height: 50,
                                                    paddingLeft: 20,
                                                    paddingRight: 20
                                                }}>Копировать</button>
                                        </CopyToClipboard>
                                        {
                                            this.state.copied ?
                                                <span style={{marginLeft: 10}} className='order-info-label'>Скопировано</span>
                                                : ''
                                        }

                                    </div>
                                </div>
                                {
                                    this.state.gifts.content ?
                                        <div style={{marginTop: 40}}>

                                            {
                                                this.state.gifts.content.map(gift => {
                                                    return (
                                                        <div className="category-product-card" key={gift.id}>
                                                            <div className="search-card-goods-image"
                                                                 onClick={()=>{
                                                                     this.giftIsOpenView(gift.id);
                                                                 }}
                                                                 style={{
                                                                     backgroundImage: 'url("' + gift.image + '")',
                                                                     backgroundSize: 'contain',
                                                                     backgroundRepeat: 'no-repeat',
                                                                     backgroundPositionX: 'center',
                                                                     backgroundPositionY: 'center',
                                                                     position: 'relative'

                                                                 }}
                                                            >
                                                            </div>
                                                            <div className="main-card-goods-desc" style={{height: 70}}>
                                                                <div className="main-card-goods-name">
                                                                    <a className="main-card-goods-name-text"
                                                                       rel="noopener noreferrer"
                                                                       target='_blank'
                                                                       href={gift.url}>{gift.title}</a>
                                                                </div>
                                                                <div className="main-card-goods-store" style={{height: 22}}>
                                                                    <a href={gift.url}
                                                                       rel="noopener noreferrer"
                                                                       target='_blank'
                                                                       className="main-card-goods-store-link">{gift.siteName ? gift.siteName : ''}</a>
                                                                </div>
                                                            </div>

                                                            <div style={{
                                                                height: '1px',
                                                                width: '205px',
                                                                borderRadius: '1px',
                                                                backgroundColor: '#CFD6D8',
                                                                display: 'block'
                                                            }}>

                                                            </div>
                                                            <div>
                                                                <button onClick={() => {
                                                                    this.onEditGift(gift.id);
                                                                } } className='edit-button'>Редактировать</button>
                                                                <button onClick={() => {
                                                                    this.onDeleteGift(gift.id);
                                                                }} style={{marginLeft: 10}} className="delete-button">Удалить</button>
                                                            </div>

                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        : ''
                                }

                            </div>

                        </TabContainer>}

                    </div>
                    : ''
                }
                <div>
                    <Dialog
                        open={this.state.openDialog}
                        onClose={this.onDialogClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title" style={{
                            color: '#283149',
                            fontFamily: 'Tinos',
                            fontSize: '30px',
                            lineHeight: '40px'
                        }}>Смена пароля</DialogTitle>
                        <DialogContent>
                            {
                                this.state.errorText ?
                                    <DialogContentText style={{
                                        color: '#E60936',
                                        fontFamily: 'Open Sans',
                                        fontSize: '12px',
                                        lineHeight: '20px'
                                    }}>
                                        {this.state.errorText}
                                    </DialogContentText>
                                    : ''
                            }

                            <TextField
                                error={this.state.validate &&
                                (!this.state.changePassword.oldPassword
                                    || this.state.changePassword.oldPassword.trim().length < 1)
                                }
                                autoFocus
                                margin="dense"
                                label="Старый пароль"
                                type="password"
                                fullWidth
                                value={this.state.changePassword.oldPassword}
                                onChange={(val) => {
                                    let changePassword = this.state.changePassword;
                                    changePassword.oldPassword = val.target.value;
                                    this.setState({changePassword: changePassword});
                                }}
                            />
                            <TextField
                                error={this.state.validate
                                && (!this.state.changePassword.repeatNewPassword
                                    || this.state.changePassword.repeatNewPassword.trim().length < 1)}
                                margin="dense"
                                label="Новый пароль"
                                type="password"
                                fullWidth
                                value={this.state.changePassword.newPassword}
                                onChange={(val) => {
                                    let changePassword = this.state.changePassword;
                                    changePassword.newPassword = val.target.value;
                                    this.setState({changePassword: changePassword});
                                }}
                            />
                            <TextField
                                error={this.state.validate
                                && (this.state.changePassword.newPassword !== this.state.changePassword.repeatNewPassword)}
                                margin="dense"
                                label="Повторите новый пароль"
                                type="password"
                                fullWidth
                                value={this.state.changePassword.repeatNewPassword}
                                onChange={(val) => {
                                    let changePassword = this.state.changePassword;
                                    changePassword.repeatNewPassword = val.target.value;
                                    this.setState({changePassword: changePassword});
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={this.state.inProcess} onClick={this.onDialogClose} color="primary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.inProcess} onClick={this.onPasswordChange} color="secondary">
                                Сменить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.deliveredDialog}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Вы получили товар?
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <CircularProgress
                                style={{display: this.state.deliveredInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.sendOnClose} color="secondary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.deliveredInProgress} onClick={this.sendStatusToChange}
                                    color="primary">
                                Получил
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.reportOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.sendOnCloseReport}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title" className='d-flex justify-content-center'>
                            Пожаловаться на заказ
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <div>

                            </div>
                            <div>
                                <div className='d-flex justify-content-center'>
                                    <CircularProgress
                                        style={{display: this.state.sendingInProgressReport ? 'block' : 'none'}}
                                        color="secondary"/>
                                </div>
                                <textarea
                                    className='textarea-element'
                                    value={this.state.messageReport}
                                    onChange={this.onChangeReport}
                                    placeholder={'Введите сообщение'}
                                    style={{
                                        padding: '20px',
                                        marginTop: '10px',
                                        height: '150px'
                                    }}/>

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.sendOnCloseReport} color="secondary">
                                Отмена
                            </Button>
                            <button className='save-btn-40' disabled={this.state.sendingInProgressReport}
                                    onClick={this.sendReport}>
                                Отправить
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xl'}
                        classes={{paperWidthXl: classes.paperWidthXl}}
                        open={this.state.giftIsOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.giftOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle
                            id="alert-dialog-slide-title"
                            classes={{root: classes.titleRoot}}
                        >
                            <div className='row'>
                                <div className='col-md-10'>
                                    <span className='alert-dialog-slide-title'>Хочу в подарок</span>
                                </div>
                                <div className='col-md-2'>
                                    <img onClick={this.giftOnClose} src="/images/close.svg"
                                         className="close"/>
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent classes={{root: classes.contentRoot}}>
                            {
                                this.state.loadingPage ? <LoadingPage opacity={0.5} /> : ''
                            }
                            <div style={{display: 'block',}}>

                                <div>
                                    <div style={{display: 'block'}}>
                                        <div className="store-header-title" style={{marginTop: '20px'}}>
                                            <span>Вставьте ссылку на то, что хотите получить в подарок. Работает почти со всеми сайтами.</span>
                                        </div>
                                        <div className="store-header-title" style={{marginTop: '20px'}}>
                                            <span>Это может быть что угодно: товары, услуги, записи на мастер-классы и марафоны, сертификаты.</span>
                                        </div>
                                        <div>
                                            <input type='text'
                                                   disabled={(this.state.loadingPage || this.state.urlLoaded) && !this.state.urlError}
                                                   className='input-text-element-470'
                                                   value={this.state.gift.url}
                                                   onChange={this.urlOnChange}
                                                   placeholder='Ссылка'
                                                   style={{
                                                       paddingLeft: '20px',
                                                       marginTop: '10px',
                                                       width: '78%'
                                                   }}/>
                                            <button
                                                style={{marginLeft: 10, width: '20%'}}
                                                disabled={(this.state.loadingPage || this.state.urlLoaded) && !this.state.urlError}
                                                onClick={this.sendForParse}
                                                className='btn-link-info-mid-button'>Загрузить</button>

                                            <div className="d-flex justify-content-start">
                                                <span className='error-message' ref="nameError"
                                                      style={{display: (this.state.urlError ? 'block' : 'none')}}>Неправильный адрес или страница недоступна</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    height: '1px',
                                    width: '715px',
                                    borderRadius: '1px',
                                    backgroundColor: '#CFD6D8',
                                    marginTop: '20px',
                                    display: 'block'
                                }}>

                                </div>
                                {
                                    this.state.urlLoaded ?
                                        <div>
                                            <div style={{display: 'block', marginTop: 20, width: '100%'}} className='d-flex justify-content-center'>
                                                <div style={{
                                                    height: 200,
                                                    width: 200,
                                                    backgroundImage: 'url("' + this.state.gift.image + '")',
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPositionX: 'center',
                                                    backgroundPositionY: 'center',
                                                    position: 'relative'
                                                }}>

                                                </div>
                                            </div>

                                            <div style={{display: 'block'}}>
                                                <div className="store-header-title" style={{marginTop: '20px'}}>
                                                    <span>Название</span>
                                                </div>
                                                <div>
                                                    <input type='text'
                                                           className='input-text-element-100pr'
                                                           value={this.state.gift.title}
                                                           onChange={this.titleOnChange}
                                                           placeholder='Название (обязательно)'
                                                           style={{
                                                               paddingLeft: '20px',
                                                               marginTop: '10px'
                                                           }}/>
                                                    <div className="d-flex justify-content-start">
                                            <span className='error-message' ref="nameError"
                                                  style={{display: (this.state.titleError ? 'block' : 'none')}}>Название не может быть пустым</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{display: 'block'}}>
                                                <div className="store-header-title" style={{marginTop: '20px'}}>
                                                    <span>Описание</span>
                                                </div>
                                                <div>
                                        <textarea
                                            className='textarea-element-100pr'
                                            value={this.state.gift.description}
                                            onChange={this.descriptionOnChange}
                                            placeholder='Добавьте описание или оставьте комментарий (не обязательно)'
                                            style={{
                                                padding: '20px',
                                                marginTop: '10px'
                                            }}/>
                                                    <div className="d-flex justify-content-center">
                                            <span className='error-message' ref="nameError"
                                                  style={{display: this.state.descriptionError ? 'block' : 'none'}}>Описание путое</span>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                        : ''
                                }

                            </div>

                            <button style={{marginTop: '24px'}} disabled={this.state.sendingInProgress || !this.state.urlLoaded}
                                    onClick={this.saveGiftAfterEdit} className='btn-basket-order-dialog'>
                                Готово
                            </button>

                            <CircularProgress
                                style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>

                    </Dialog>
                </div>


                <div>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'xl'}
                        classes={{paperWidthXl: classes.paperWidthXl}}
                        open={this.state.giftIsOpenView}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.giftOnCloseView}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle
                            id="alert-dialog-slide-title"
                            classes={{root: classes.titleRoot}}
                        >
                            <div className='row' style={{marginLeft: 0, marginRight: 0}}>
                                <div className='col-10'>
                                    <span className='alert-dialog-slide-title'>{this.state.gift.siteName}</span>
                                </div>
                                <div className='col-2' style={{paddingTop: 10}}>
                                    <img onClick={this.giftOnCloseView} src="/images/close.svg"
                                         className="close"/>
                                </div>
                            </div>
                        </DialogTitle>
                        <DialogContent classes={{root: classes.contentRoot}}>
                            {
                                this.state.loadingPage ? <LoadingPage opacity={0.5} /> : ''
                            }
                            <div style={{display: 'block',}}>

                                <div>
                                    <div style={{display: 'block'}}>
                                        <div className="store-header-title" style={{marginTop: '20px'}}>
                                            <span>Ссылка на подарок</span>
                                        </div>
                                        <div style={{width: '100%', wordBreak: 'break-word'}}>
                                            <a target='_blank'
                                               rel="noopener noreferrer"
                                               style={{
                                                   marginTop: '10px',
                                                   width: '78%'
                                               }} href={this.state.gift.url}>{this.state.gift.url}</a>
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    height: '1px',
                                    width: '100%',
                                    borderRadius: '1px',
                                    backgroundColor: '#CFD6D8',
                                    marginTop: '20px',
                                    display: 'block'
                                }}>

                                </div>
                                <div>
                                    <div style={{display: 'block', marginTop: 20, width: '100%'}} className='d-flex justify-content-center'>
                                        <div style={{
                                            height: 400,
                                            width: 400,
                                            backgroundImage: 'url("' + this.state.gift.image + '")',
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPositionX: 'center',
                                            backgroundPositionY: 'center',
                                            position: 'relative'
                                        }}>

                                        </div>
                                    </div>

                                    <div style={{display: 'block'}}>
                                        <div className="store-header-title" style={{marginTop: '20px'}}>
                                            <span>Название</span>
                                        </div>
                                        <div>
                                                    <span
                                                        style={{
                                                            marginTop: '10px',
                                                            width: '100%'
                                                        }}>{this.state.gift.title}</span>
                                        </div>
                                    </div>
                                    {
                                        this.state.gift.description ?
                                            <div style={{display: 'block', marginBottom: 20}}>
                                                <div className="store-header-title" style={{marginTop: '20px'}}>
                                                    <span>Описание</span>
                                                </div>
                                                <div>
                                                    <span
                                                        className='gift-description'
                                                        style={{
                                                            marginTop: '10px'
                                                        }}>{this.state.gift.description}</span>
                                                </div>
                                            </div>
                                            :''
                                    }

                                </div>

                            </div>

                            {/*<button style={{marginTop: '24px'}} disabled={this.state.sendingInProgress}*/}
                            {/*        onClick={this.saveGiftAfterEdit} className='btn-basket-order-dialog'>*/}
                            {/*    Готово*/}
                            {/*</button>*/}

                            <CircularProgress
                                style={{display: this.state.sendingInProgress ? 'block' : 'none'}}
                                color="secondary"/>
                        </DialogContent>

                    </Dialog>
                </div>



                <div>
                    <Dialog
                        open={this.state.deleteIsOpen}
                        TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.giftOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Вы точно хотите удалить запись?"}
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            {
                                this.state.loadingPage ? <LoadingPage opacity={0.5} /> : ''
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onDeleteGiftClose} color="primary">
                                Отмена
                            </Button>
                            <Button disabled={this.state.deleteInProgress} onClick={this.onDeleteGiftPressed} color="secondary">
                                Удалить
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <ModalGateway>
                    {
                        this.state.photoIsOpen ?
                            <Modal onClose={this.toggleViewer}>
                                <Carousel views={[{source: this.state.user.photoMax}]} />
                            </Modal>
                            : ''
                    }
                </ModalGateway>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        notification: state.notification,
        messages: state.messages,
        localStorageValues: state.localStorageValues,
        userAgent: state.userAgentUtil
    };
};

UserCabinetPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps)(UserCabinetPage));

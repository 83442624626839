import React from "react";
import connect from "react-redux/es/connect/connect";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Link, Redirect} from "react-router-dom";
import {refreshSession} from "../actions/authorization";
import {approveGoods, getNotApprovedGoods, rejectGoods} from "../api/AdminGoodsAPI";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button/Button";


class GoodsListForApprove extends React.Component{

    constructor(props, context) {
        super(props, context);
        this.state = {
            filter: {
                page: 0,
                size: 100,
                category: props.match.params.alias,
                costFrom: props.dict.limits.min,
                costTo: props.dict.limits.max,
                color: ''

            },
            product: {
                content: []
            },
            loading: true,
            rejectIsOpen: false,
            comment: '',
            goodsIdToReject: 0
        }
    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        this.searchProduct();
    }

    searchProduct = () => {
        this.setState(()=>({loading: true}));
        getNotApprovedGoods({...this.state.filter}).then(
            response => {
                this.setState(() => ({
                    product: JSON.parse(response)
                }));
                this.setState(()=>({loading: false}));
            },
            error => {
                this.setState(()=>({loading: false}));
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403) {
                    this.props.dispatch(refreshSession()).then(() => {
                        this.searchProduct();
                    });
                }
            }
        );
    };

    approveProduct = (goodsId) => {
        approveGoods(goodsId).then(
            response => {
                this.searchProduct();
            },
            error => {
                this.setState(()=>({loading: false}));
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403) {
                    this.props.dispatch(refreshSession()).then(() => {
                        this.searchProduct();
                    });
                } else {
                    console.log(resp);
                }
            }
        );
    };

    onRejectPressed = (goodsId) => {
        this.setState({goodsId: goodsId, rejectIsOpen: true});
    };

    dialogOnClose = () => {
        this.setState({rejectIsOpen: false, goodsId: 0, comment: ''});
    };

    onChangeComment = (comment) => {
        this.setState({comment: comment.target.value})
    };

    sendReject = () => {
        rejectGoods(this.state.filter, {goodsId: this.state.goodsId, comment: this.state.comment}).then(
            response => {
                this.setState(() => ({
                    product: JSON.parse(response)
                }));
                this.dialogOnClose();
            },
            error => {

            }
        );
    };

    render() {
        return (
            <div>

                {this.props.user.roles.filter((role) => (role.name === 'ROLE_ADMIN' || role.name === 'ROLE_MANAGER')).length < 1 ? <Redirect to={'/'}/> : ''}

                <div className="category-product-box" style={{marginLeft: '100px'}}>


                    {   this.state.loading ? <CircularProgress color="secondary" style={{
                            marginLeft: '380px'
                        }} /> :
                        this.state.product.content.filter(value => value.name).map((product) => {
                            // console.log(product);
                            return (
                                <div key={product.id} className="category-product-card">
                                    <div className="search-card-goods-image"
                                         style={{
                                             backgroundImage: 'url("'
                                                 + ( product.photos.length > 0
                                                     ? product.photos.filter((ph)=>{return ph.orderPlace === 1})[0] ? product.photos.filter((ph)=>{return ph.orderPlace === 1})[0].photoMin : ''
                                                     : '' )
                                                 + '")',
                                             backgroundSize: 'cover',
                                             backgroundRepeat: 'no-repeat',
                                             backgroundPositionX: 'center',
                                             backgroundPositionY: 'center'
                                         }}
                                    >

                                    </div>
                                    <div className="main-card-goods-desc">
                                        <div className="main-card-goods-name">
                                            <Link className="main-card-goods-name-text"
                                                  to={'/admin/preview/' + product.id}>{product.name}</Link>
                                        </div>
                                        <div className="main-card-goods-store">
                                            <Link to={'/store/'+product.store.id}
                                                  className="main-card-goods-store-link">{product.store.name}</Link>
                                        </div>
                                        <div className="main-card-goods-cost">
                                                    <span
                                                        className="main-card-goods-cost">{product.cost ? product.cost.cost : 0} BYN</span>
                                        </div>
                                        <div style={{
                                            marginTop: '15px',
                                        }}>
                                            <button className='btn btn-secondary create-btn-min'
                                                    onClick={ (e) => {
                                                        this.approveProduct(product.id);
                                            } } >Approve</button>
                                            <button className='btn btn-secondary create-btn-min'
                                                    onClick={ (e) => {
                                                        this.onRejectPressed(product.id);
                                                    } } >Reject</button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }


                </div>

                <div>
                    <Dialog
                        open={this.state.rejectIsOpen}
                        // TransitionComponent={this.Transition}
                        keepMounted
                        onClose={this.dialogOnClose}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle id="alert-dialog-slide-title">
                            {"Причина отказа"}
                        </DialogTitle>
                        <DialogContent className='d-flex justify-content-center'>
                            <textarea
                                className='textarea-element'
                                value={this.state.comment}
                                onChange={this.onChangeComment}

                                style={{
                                    padding: '20px',
                                    marginTop: '10px'
                                }} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.dialogOnClose} color="secondary">
                                Отмена
                            </Button>
                            <Button  onClick={this.sendReject} color="primary">
                                Отказать
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>



            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

export default connect(mapStateToProps)(GoodsListForApprove);
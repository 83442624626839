import React from "react";


const RadioCrafty = () => {
    return (
        <div style={{width: '20px', height: '20px'}}>
            <img src="/images/default.svg"
                     className="crafty-radio" />
        </div>
    );
};

const RadioCraftyChecked = () => {
    return  (
        <div style={{width: '20px', height: '20px'}}>
            <img src="/images/on.svg"
                      className="crafty-radio" />
        </div>
    );
};

export {RadioCrafty, RadioCraftyChecked};

const userReducerDefaultState = {
    name: '',
    email: '',
    createdDate: '',
    updatedDate: '',
    phone: '',
    city: '',
    country: '',
    photoMin: '',
    photoMid: '',
    photoMax: '',
    description: '',
    confirmEmail: '',
    id: '',
    blocked: '',
    storeId: '',
    storeIsFilled: false,
};

export default (state = userReducerDefaultState, action) => {
    switch (action.type) {
        case 'SAVE_USER':
            return {
                ...state,
                ...action.user
    };
        case 'CHANGE_PHOTO':
            return {
                ...state,
                ...action.user
            };
        case 'DELETE_USER':
            return {
                ...state,
                ...userReducerDefaultState
            };
        case 'DELETE_STORE':
            return {
                ...state,
                ...action.user
            };
        default:
            return state;
    }
};

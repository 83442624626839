import React from 'react';
import {getFilteredGoods, goodsCollections, goodsCollectionsAll, goodsCollectionsMain} from "../api/GoodsAPI";
import SearchBlock from "./SearchBlock";
import AdvertisingBoxMain from "./AdvertisingBoxMain";
import MainListProduct from "./MainListProduct";
import AboutLine from "./AboutLine";
import MainPopularStore from "./MainPopularStore";
import {connect, Provider} from "react-redux";
import {refreshSession} from "../actions/authorization";
import { Message, StompSubscription, Client } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import {Helmet} from "react-helmet";
import * as ReactGA from "react-ga";
import {CircularProgress} from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";


export class MainPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            productList: {
                content: []
            },
            productListNew: {
                content: []
            },
            productNY: {
                content: []
            },
            productRec: {
                content: []
            },
            collections: [],
            collectionsLoading: true,
            collectionList: [],
            user: {}
        }
    }

    // sendMessage = (client) => {
    //     // console.log('send');
    //     // console.log(client);
    //     // client.publish({destination: '/spring-security-mvc-socket/secured/chat', body: JSON.stringify({text: 'Test message'})});
    //     client.publish({destination: '/spring-security-mvc-socket/secured/room', body: JSON.stringify({text: 'Test message'})});
    //
    //     client.publish({destination: '/spring-security-mvc-socket/secured/user/queue/specific-user', body: JSON.stringify({text: 'Test message'})});
    //     setTimeout(() => {this.sendMessage(client)}, 3000);
    // };


    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        this.updateGoods();
        ReactGA.event({
            category: 'MainPage',
            action: 'MainPage'
        });
        ReactGA.pageview('/');
    }


    updateGoods = () => {
        let productList, productNY, productRec;
        const filterNew = {
            page: 0,
            size: 12
        };
        getFilteredGoods(filterNew).then(
            response => {
                productList = JSON.parse(response);
                this.setState((state)=> {
                    return {productListNew: productList};
                });
            },
            error => {
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403){
                    this.props.dispatch(refreshSession()).then(() => {
                        this.updateGoods();
                    });
                }
            }
        );

        goodsCollectionsMain({
            page: 0,
            size: 6
        }).then(
            response => {
                let collection = JSON.parse(response);
                this.setState({collections: collection, collectionsLoading: false});
            }, error => {
                this.setState({collectionsLoading: false});
                console.log(error);
            }
        ).catch(
            error => this.setState({collectionsLoading: false})
        );

        goodsCollectionsAll().then(
            response => {
                let resp = JSON.parse(response);
                this.setState({collectionList: resp.content});
            }, error => {
                console.log(error);
            }
        ).catch(
            error => {
                console.log(error);
            }
        );

    };


    render() {
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            className: "center",
            centerMode: true,
            centerPadding: "300px",
        };
        return (
                <div className='row'>
                    <Helmet>
                        <title>Crafty - товары ручной работы, handmade(хендмейд), подарки</title>
                        <meta name="Description" content="Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы. Handmade и подарки" />
                        <meta name="description" content="Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы. Handmade и подарки" />
                        <meta name="og:description" content='Современный маркетплейс для людей, которые неравнодушны к товарам ручной работы. Handmade и подарки' />
                        <meta name="og:title" content={'Crafty - товары ручной работы, handmade(хендмейд), подарки'} />
                        <meta property="og:image" content={'https://crafty.by/images/logo.png'} />
                        <link rel="canonical" href={'https://crafty.by'} />
                        <meta name="keywords" content="crafty, ремесло, ручная работа, авторская работа, handmade, хендмейд" />
                        <meta property="og:type" content="website" />
                        <meta property="og:site_name" content="Crafty.by" />
                    </Helmet>
                    <SearchBlock/>
                    <div  style={{minWidth: '1230px', marginTop: 50, minHeight: 100}}>
                        <Slider {...settings}>
                            {
                                this.state.collectionList ?
                                    this.state.collectionList.map((value, index) => {
                                        return (
                                            <Link key={index} to={'/collection/'+value.collectionId} className='adv-link'>
                                                <img src={value.imageUrl} alt={value.title} style={{width: '100%'}}/>
                                            </Link>
                                        );
                                    })
                                    : ''
                            }

                        </Slider>
                    </div>
                    {/*<AdvertisingBoxMain/>*/}
                    {
                        this.state.collectionsLoading ?
                            <div className='d-flex justify-content-center'>
                                <CircularProgress color="secondary"/>
                            </div>
                            :
                            this.state.collections ?
                                this.state.collections.map(collection => {
                                    return (<MainListProduct key={collection.collectionId}
                                                             url={'/collection/' + collection.collectionId}
                                                             name={collection.title}
                                                             productList={collection.goodsList ? collection.goodsList.content : []}/>);
                                })
                                : ''
                    }
                    {/*<MainListProduct url='/collection/1' name='Новый год' productList={this.state.productNY ? this.state.productNY.content : []}/>*/}
                    {/*<MainListProduct url='/collection/2' name='Рекомендуемое' productList={this.state.productRec ? this.state.productRec.content : []}/>*/}
                    <MainListProduct  url='/search' name='Новое' productList={this.state.productListNew ? this.state.productListNew.content : []}/>
                    <AboutLine/>
                    <MainPopularStore stores={this.props.dict.stores}/>
                </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        basket: state.basket,
        messages: state.messages
    };
};

export default connect(mapStateToProps)(MainPage);
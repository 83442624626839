const settingsReducerDefaultState = {
    unread: 0,
    conversations: [],
    isOpen: false,
    isDialog: false,
    dialogId: null,
    dialog : {},
    userPage: null,
    isNew: false,
    messageBox: null

};

export default (state = settingsReducerDefaultState, action) => {
    switch (action.type) {
        case 'SAVE_UNREAD':
            return {
                ...state,
                ...action.messages
            };
        case 'SAVE_CONVERSATIONS':
            return {
                ...state,
                ...action.messages
            };
        case 'CHANGE_STATUS':
            return {
                ...state,
                ...action.messages
            };
        case 'IS_DIALOG':
            return {
                ...state,
                ...action.messages
            };
        case 'DIALOG_ID':
            return {
                ...state,
                ...action.messages
            };
        case 'SAVE_DIALOG':
            return {
                ...state,
                ...action.messages
            };
        case 'SET_MESSAGE_BOX':
            return {
                ...state,
                ...action.messages
            }
        case 'SCROLL_DOWN':
            if (!!state.messageBox){
                state.messageBox.current.scrollTop = state.messageBox.current.scrollHeight;
            }
            return {
                ...state
            }
        case 'UPDATE_DIALOG':
            let dialog = Object.assign({}, state.dialog);
            dialog.messages.content.push(action.message);
            return {
                ...state,
                ...dialog
            };
        case 'UPDATE_CONVERSATIONS':
            let conversations = Object.assign({}, state.conversations)
            if (conversations.content){
                if (conversations.content.filter(conv => conv.id === action.message.conversationId).length > 0){
                    let exConv = conversations.content.filter(conv => conv.id !== action.message.conversationId);
                    let conv = conversations.content.filter(conv => conv.id === action.message.conversationId)[0];
                    conv.messages.content = [action.message, ...conv.messages.content];
                    conv.unreadCount += 1;
                    conversations.content = [conv, ...exConv];
                    console.log(conversations);
                    return {
                        ...state,
                        conversations
                    };
                } else {
                    return {
                        ...state
                    };
                }
            }else {
                return {
                    ...state
                };
            }
        case 'USER_PAGE':
            return {
                ...state,
                ...action.messages
            };
        case 'CREATE_NEW':
            return {
                ...state,
                ...action.messages
            };
        default:
            return state;
    }
};

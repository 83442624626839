import {url} from '../settings/Settings';
import * as cookie from "react-cookies";


const logEvent = (log) => {
    return new Promise(function(resolve, reject) {
        log.token = cookie.load('_web');
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/log/web', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify(log));
    });

};
const logSocial = (log) => {
    return new Promise(function(resolve, reject) {
        log.token = cookie.load('_web');
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/log/social', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };

        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send(JSON.stringify(log));
    });

};

export {logEvent, logSocial};
import {url} from '../settings/Settings';
import cookie from "react-cookies";

const getUserOrder = (filter) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('GET', url + '/v1.0/order/user?page='
            + (filter.page || 0) + '&size='+filter.size
            + (filter.sort ? '&sort='+filter.sort : '')
            , true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };
        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};

const getUserOrderV2 = (filter) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('GET', url + '/v2.0/order/user?page='
            + (filter.page || 0) + '&size='+filter.size
            + (filter.sort ? '&sort='+filter.sort : '')
            , true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };
        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};


const getStoreOrder = (filter) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('GET', url + '/v1.0/order/store?page='
            + (filter.page || 0) + '&size='+filter.size
            + (filter.sort ? '&sort='+filter.sort : '')
            , true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };
        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};


const getStoreOrderv2 = (filter) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('GET', url + '/v2.0/order/store?page='
            + (filter.page || 0) + '&size='+filter.size
            + (filter.sort ? '&sort='+filter.sort : '')
            , true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };
        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };

        xhr.send();
    });
};


const changeStatus = (status) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/order/store', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };
        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };
        xhr.send(JSON.stringify(status));
    });
};

const changeStatusByUser = (status) => {
    return new Promise(function(resolve, reject) {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url + '/v1.0/order/user', true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
        xhr.setRequestHeader('web', cookie.load('_web'));

        let session = JSON.parse(localStorage.getItem("session"));
        if (session)
            xhr.setRequestHeader('Authorization', 'token ' + session.token);
        xhr.onload = function() {
            if (this.status === 200) {
                resolve(this.response);
            } else {
                let error = new Error(this.responseText);
                error.code = this.status;
                reject(error);
            }
        };
        xhr.onerror = function() {
            reject(new Error("Network Error"));
        };
        xhr.send(JSON.stringify(status));
    });
};


export {getUserOrder, getStoreOrder, changeStatus, changeStatusByUser, getUserOrderV2, getStoreOrderv2};



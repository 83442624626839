import {msgsound} from "../assets/asstes";
import {logEvent} from "../api/LogAPI";


const checkAudioAllowed = () => {

    const unlockAudio = () => {
        try {
            document.body.removeEventListener('click', unlockAudio);
            document.body.removeEventListener('touchstart', unlockAudio);

            const sound = new Audio(msgsound);

            sound.play();
            // sound.pause();
            // sound.currentTime = 0;
        }catch (e) {
        }

    };

    let check = new Audio(msgsound);
    check.volume = 1;
    check.play()
        .catch(
        error => {
            try{
                document.body.addEventListener('click', unlockAudio);
                document.body.addEventListener('touchstart', unlockAudio);
            }catch (e) {
                console.log(e);
            }
        }
    )
}

const playNewMessage = () => {

    let audio = new Audio("/sound/msgsound.mp3");
    audio.play().catch(
        error => {
            logEvent(
                {
                    type: 'ERROR',
                    errorStack: JSON.stringify(error.message),
                    page: window.location.href,
                    comment: 'playNewMessage exception'
                }
            ).catch(
                error => {

                    console.log(error);
                }
            );
        }
    );
};

const playSendMessage = () => {

    let audio = new Audio("/sound/sendmessage.mp3");
    audio.play().catch(
        error => {
            logEvent(
                {
                    type: 'ERROR',
                    errorStack: JSON.stringify(error.message),
                    page: window.location.href,
                    comment: 'playNewMessage exception'
                }
            ).catch(
                error => {

                    console.log(error);
                }
            );
        }
    );
};



export {playNewMessage, checkAudioAllowed, playSendMessage};
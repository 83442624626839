import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {
    createNew,
    getConversations,
    getDialog,
    setDialog,
    setDialogId,
    setIsDialog, setMessageBox,
    setOpenStatus, setUserPage
} from "../actions/messages";
import {deleteMessageFile, sendMessageToConversation, sendMessageToUser, uploadMessageFile} from "../api/MessageAPI";
import {sendNotification} from "../actions/notifications";
import LinearProgress from "@material-ui/core/LinearProgress";
import {getStore} from "../api/StoreAPI";

const styles = theme => ({
    close: {
        padding: theme.spacing.unit,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing.unit * 16,
        right: theme.spacing.unit * 10,
        width: '93px',
        height: '50px',
        borderRadius: '25px',
        boxShadow: '0 6px 20px 0 rgba(167, 106, 118, 0.24)',
        backgroundColor: '#ffffff'
    },
    paper: {
        boxShadow: '-8px 16px 32px 0 rgba(64, 75, 105, 0.2)'
    }
});


class Conversation extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            filter: {
                page: 0,
                size: 20,
            },
            message: '',
            messageInterval: null,
            scrolled: false,
            files: [],
            uploadingInProgress: false,
            progress: 0,
            scrollPosition: 0,
            storeConversation: null,
            pageHeight: document.documentElement.clientHeight,
            sendingInProgress: false
        };
        this.messageBox = React.createRef();
        this.fileInput = React.createRef();
        this.messageDate = null;
        this.headerRef = React.createRef();
        this.inputRef = React.createRef();
        this.heightChecker = null;
        moment.locale('ru');
    }


    fileOnChange = (ev) => {
        if (ev.target.files[0] == null) {
            return;
        }
        ev.persist();
        if ((ev.target.files[0].size / 1000 / 1000) > 100) {

            this.props.dispatch(sendNotification({
                open: true,
                messageInfo: {
                    message: 'Файл слишком большой',
                    key: new Date().getTime(),
                    variant: 'error'
                }
            }));
            ev.target.value = "";
            return;
        }
        this.setState({uploadingInProgress: true});
        let formData = new FormData();
        formData.append('file', ev.target.files[0]);
        this.messageBox.current.scrollTop = this.messageBox.current.scrollHeight;
        uploadMessageFile(formData, this.onProgress).then(
            response => {
                let file = JSON.parse(response);
                let state = {...this.state};
                state.files.push(file);
                this.setState({...state});
                ev.target.value = "";
                this.setState({uploadingInProgress: false, progress: 0});
                this.messageBox.current.scrollTop = this.messageBox.current.scrollHeight;

            },
            error => {
                console.log(error);
                this.setState({uploadingInProgress: false, progress: 0});
            }
        );
    };

    onProgress = (event) => {

        if (event.lengthComputable) {
            let complete = (event.loaded / event.total * 100 | 0);
            this.setState({progress: complete});
        }
    };

    removeMessageFile = (fileId) => {
        let state = {...this.state};
        state.files = state.files.filter(file => file.fileId !== fileId);
        this.setState({...state});
    };

    onSendMessage = () => {
        console.log(this.state.sendingInProgress);
        if (this.state.sendingInProgress){
            return;
        }
        this.setState({sendingInProgress: true}, () => {
            const message = this.state.message.trim();
            if (message.length === 0 && this.state.files.length === 0) {
                this.setState({message: '', sendingInProgress: false});
                return;
            }
            let query = this.props.messages.isNew ? {
                message: this.state.message,
                userId: this.props.messages.userPage.id,
                fileIds: this.state.files.map(file => file.fileId)

            } : {
                message: this.state.message,
                conversationId: this.props.messages.dialogId,
                fileIds: this.state.files.map(file => file.fileId)
            };

            let requestMethod = this.props.messages.isNew ? sendMessageToUser : sendMessageToConversation;
            requestMethod(query, this.state.filter).then(
                response => {
                    if(!this.props.messages.isNew){
                        let conversation = JSON.parse(response);
                        conversation.messages.content = conversation.messages.content.reverse();
                        this.props.dispatch(setDialog(conversation));
                        this.setState({message: '', files: [], sendingInProgress: false});
                        if (!this.state.scrolled) {
                            this.messageBox.current.scrollTop = this.messageBox.current.scrollHeight;
                        }
                    }else {
                        let conversation = JSON.parse(response);
                        this.props.dispatch(setDialogId(conversation.id));
                        this.props.dispatch(getDialog({id: conversation.id, size: 20})).then(()=>{
                            this.props.dispatch(createNew(false));
                            this.setState({message: '', files: [], sendingInProgress: false});
                        });
                    }
                },
                error => {
                    this.setState({sendingInProgress: true});
                    this.props.dispatch(sendNotification({
                        open: true,
                        messageInfo: {
                            message: 'Сообщение не было доставлено. Попробуйте обновить страницу.',
                            key: new Date().getTime(),
                            variant: 'error'
                        }
                    }));
                }
            );
        });

    };

    messageOnChange = (ev) => {
        let message = ev.target.value;
        this.setState(() => ({message: message}));
    };

    onElementHeightChange = () => {
        let lastHeight = this.state.pageHeight;
        let newHeight = document.documentElement.clientHeight;
        if( lastHeight !== newHeight )
            this.messageBox.current.style.height = (document.documentElement.clientHeight - this.headerRef.current.clientHeight - this.inputRef.current.clientHeight) + 'px';
            this.setState({pageHeight: newHeight});
            if( this.heightChecker )
                clearTimeout(this.heightChecker);

            this.heightChecker = setTimeout(this.onElementHeightChange, 200);
    };

    componentDidMount() {
        this.props.dispatch(setMessageBox(this.messageBox));
        this.messageBox.current.style.height = (document.documentElement.clientHeight - this.headerRef.current.clientHeight - this.inputRef.current.clientHeight) + 'px';
        this.onElementHeightChange();

        if (!this.props.messages.isNew) {
            this.props.dispatch(getConversations());
        }
        if (!this.props.messages.isNew && !!this.props.messages.dialog && !!this.props.messages.dialog.messages && !!this.props.messages.dialog.messages.content) {
            this.messageBox.current.scrollTop = this.messageBox.current.scrollHeight;
        }
        if (!this.props.messages.isNew) {
            let interval = setInterval(() => {
                    this.props.dispatch(getDialog({
                        id: this.props.messages.dialogId,
                        page: this.state.filter.page,
                        size: this.state.filter.size
                    }))
                }
                , 30000);
            this.setState({messageInterval: interval});

        }
        let user = this.props.messages.isNew ? this.props.messages.userPage : this.props.messages.dialog.users.filter(user => user.id !== this.props.user.id)[0];
        if(user.storeIsFilled){
            getStore(user.storeId).then(
                response => {
                    let store = JSON.parse(response);
                    this.setState({storeConversation: store})
                }, error => {
                    console.log(error);
                }
            );
        }

    }

    componentWillUnmount() {
        clearInterval(this.state.messageInterval);
        clearTimeout(this.heightChecker);
        this.props.dispatch(setUserPage(null));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.messages.isNew && !!this.props.messages.dialog && !!this.props.messages.dialog.messages.content) {
            if (prevProps.messages.dialog.messages.content.length !== this.props.messages.dialog.messages.content.length && this.state.scrolled === false) {
                this.messageBox.current.scrollTop = this.messageBox.current.scrollHeight;
            }
            if (prevProps.messages.dialog.messages.content.length !== this.props.messages.dialog.messages.content.length && this.state.scrolled === true){
                this.messageBox.current.scrollTop = this.messageBox.current.scrollHeight - this.state.scrollPosition;
            }
        }

        if (this.state.uploadingInProgress !== prevState.uploadingInProgress){
            this.messageBox.current.scrollTop = this.messageBox.current.scrollHeight;
        }

    }

    onResize = () => {
        console.log('resize');
        this.messageBox.current.style.height = (document.documentElement.clientHeight - this.headerRef.current.clientHeight - this.inputRef.current.clientHeight) + 'px';
    };

    downloadAdditionalMessages = () => {
        this.props.dispatch(getDialog({
            id: this.props.messages.dialogId,
            page: this.state.filter.page,
            size: this.state.filter.size
        }))
    };

    render() {

        let user = this.props.messages.isNew ? this.props.messages.userPage : this.props.messages.dialog.users.filter(user => user.id !== this.props.user.id)[0];
        if (!user)
            return (<div></div>);
        return (
            <div
                tabIndex={0}
                className='messenger-content-container'
            >
                <div
                    style={{
                        width: '360px',
                    }}
                >
                    <div ref={this.headerRef} className='messenger-header' style={{
                        marginTop: '0',
                        paddingTop: '27px',
                        marginLeft: '0px',
                        borderBottom: '1px solid rgb(207, 214, 216)',
                        height: '102px'
                    }}>
                        <div className='inline-block' style={{height: '33px', marginLeft: '24px'}}>
                            <img onClick={() => {
                                this.props.dispatch(setIsDialog(false));
                                this.props.dispatch(setDialogId(null));
                                this.props.dispatch(createNew(false));
                                this.props.dispatch(setDialog({}));
                            }} className='close' src="/images/back.svg" alt=""/>
                        </div>
                        <div className='inline-block' style={{
                            width: '48px',
                            marginLeft: '24px'
                        }}>
                            <div className="user-conversation-photo-head"
                                 style={{
                                     backgroundImage: 'url("' + (!!user ? !!user.photoMid ? user.photoMid : '/images/avatar.png' : '') + '")',
                                     backgroundSize: (!!user ? !!user.photoMid ? 'cover' : 'contain' : ''),
                                     backgroundRepeat: 'no-repeat',
                                     backgroundPositionX: 'center',
                                     backgroundPositionY: 'center',
                                     borderRadius: '50%'
                                 }}
                            ></div>
                        </div>
                        <div className='inline-block' style={{marginLeft: '12px', width: '138px'}}>
                            <div className='dialog-user-name hide-text'>
                                <Link to={'/user/'+user.id}>{user.name}</Link>
                            </div>
                            <div className='dialog-user-store hide-text' style={ !this.state.storeConversation ? {visibility: 'hidden'} : {display: 'block'}}>
                                <Link to={'/store/'+ (this.state.storeConversation ? this.state.storeConversation.id : 0) }>{this.state.storeConversation ? this.state.storeConversation.name : user.name}</Link>
                            </div>
                        </div>
                        <div className='inline-block' style={{marginLeft: '52px', height: '34px'}}>
                            <img onClick={() => {
                                this.props.dispatch(setOpenStatus(false));
                                this.props.dispatch(createNew(false));
                                this.props.dispatch(setIsDialog(false));
                                this.props.dispatch(setDialog({}));
                            }} className='close' src="/images/close.svg" alt=""/>
                        </div>
                    </div>
                    <div
                        onScrollCapture={()=>{
                            if (!this.props.messages.isNew) {
                                if (this.messageBox.current.scrollTop === 0){
                                    let filter = {...this.state.filter};
                                    if (filter.size < this.props.messages.dialog.messages.totalElements){
                                        filter.size = filter.size + 20;
                                        this.setState({filter: filter, scrollPosition: this.messageBox.current.scrollHeight}, ()=> {
                                            this.downloadAdditionalMessages();
                                        });
                                    }
                                }
                                let position = this.messageBox.current.offsetHeight + this.messageBox.current.scrollTop;
                                if (position >= (this.messageBox.current.scrollHeight - 20)){
                                    this.setState(()=>({scrolled: false}));
                                } else {
                                    this.setState(()=>({scrolled: true}));
                                }
                            }

                        }}
                        ref={this.messageBox}
                        className='messenger-content' style={{marginTop: '14px'}}>
                        {
                            (!this.props.messages.dialog || !this.props.messages.dialog.messages || !this.props.messages.dialog.messages.content) ? '' : this.props.messages.dialog.messages.content.map((item) => {
                                let date = '';
                                if (!!this.messageDate){
                                    if (moment.duration(this.messageDate.diff(moment.utc(item.createdDate, 'YYYY-MM-DD').local(false))).asDays() !== 0) {
                                        date = Math.round(moment(new Date()).diff(moment.utc(item.createdDate, 'YYYY-MM-DD').local(false))/ 86400000) > 0
                                        ?
                                            moment.utc(item.createdDate, 'YYYY-MM-DD').local(false).format('DD MMM')
                                        :
                                            moment.utc(item.createdDate, 'YYYY-MM-DD').local(false).calendar(null, {
                                                sameDay: () => {
                                                    return 'Сегодня';
                                                }
                                            });
                                        this.messageDate = moment.utc(item.createdDate, 'YYYY-MM-DD').local(false);
                                    }
                                } else {
                                    this.messageDate = moment.utc(item.createdDate, 'YYYY-MM-DD').local(false);
                                    // date =
                                }
                                return (
                                    <div key={item.id}

                                    >
                                        {
                                            <div className='d-flex justify-content-center message-date-period'>
                                                <span>{date}</span>
                                            </div>
                                        }
                                        <div
                                            className={item.user.id == this.props.user.id ? 'd-flex justify-content-end' : ''}
                                        >
                                            <div
                                                className={item.user.id == this.props.user.id ? 'dialog-owner-message' : 'dialog-user-message'}>
                                                {
                                                    !!item.message ?
                                                        <span style={{whiteSpace: 'pre-wrap'}}>{item.message}</span>
                                                        :
                                                        ''
                                                }
                                                {
                                                    item.files.map((file) => {
                                                        return (
                                                            <div key={file.fileId}>
                                                                <a className={item.user.id == this.props.user.id ? 'dialog-owner-link' : 'dialog-user-link'} href={file.link} target='_blank'>{'Файл: ' + file.name}</a>
                                                                {

                                                                    (file.name.toLowerCase().includes('.jpg')) ||
                                                                    (file.name.toLowerCase().includes('.jpeg')) ||
                                                                    (file.name.toLowerCase().includes('.png'))
                                                                    ? <div
                                                                            style={{
                                                                                width: 200,
                                                                                height: 200,
                                                                                backgroundImage: 'url("' + file.link + '")',
                                                                                backgroundSize: 'cover',
                                                                                backgroundRepeat: 'no-repeat',
                                                                                backgroundPositionX: 'center',
                                                                                backgroundPositionY: 'center'
                                                                            }}
                                                                        ></div>
                                                                    : ''
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }

                                            </div>
                                        </div>
                                        <div
                                            className={item.user.id == this.props.user.id ? 'dialog-message-date d-flex justify-content-end' : 'dialog-message-date'}
                                            style={item.user.id == this.props.user.id ? {
                                                marginRight: '24px',
                                                marginTop: '12px'
                                            } : {marginLeft: '24px'}}
                                        >
                                            <span>{moment.utc(item.createdDate, 'YYYY-MM-DDTHH:mm:ss').local(false).format('HH:mm').toString()}</span>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        <div style={{marginLeft: '24px', marginBottom: '10px', background: 'rgba(255, 255, 255, 0.5)', position: 'fixed', bottom: '10rem'}}>
                            {
                                this.state.files.length > 0 ?
                                    this.state.files.map((file) => {
                                        return (
                                            <div key={file.fileId} >
                                                <div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'inline-block', width: '250px'}}>
                                                    <a href={file.link} target='_blank'>{file.name}</a>
                                                </div>
                                                <div style={{display: 'inline-block', float: 'right', marginRight: '45px'}}>
                                                    <a
                                                        style={{marginLeft: '10px', color: 'black'}} href="javascript:void(0)"
                                                        onClick={() => {
                                                            this.removeMessageFile(file.fileId);
                                                        }}>X</a>
                                                </div>

                                            </div>
                                        );
                                    })
                                    : ''
                            }
                            {
                                this.state.uploadingInProgress ?
                                    <div>
                                        <LinearProgress style={{width: '280px'}} variant="determinate"
                                                        value={this.state.progress}/>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>

                    <div ref={this.inputRef} className='message-input-box d-flex justify-content-center'>
                        <div style={{marginTop: '25px'}}>
                            <input ref={this.fileInput} style={{display: 'none'}} type="file"
                                   onChange={this.fileOnChange}/>
                            <button className='message-attache-button' onClick={() => {
                                this.fileInput.current.click();
                            }}><img style={{paddingBottom: '4px'}} src="/images/plus.svg" alt=""/></button>
                        </div>
                        <div style={{marginTop: '25px'}} className='message-input-element'>
                            <textarea
                                autoFocus={true}
                                onKeyPressCapture={(event) => {
                                    if (event.ctrlKey && event.charCode === 13 && !this.sendingInProgress) {
                                        this.onSendMessage();
                                    }
                                }}
                                value={this.state.message} onChange={this.messageOnChange}
                                placeholder='Введите сообщение...'/>
                        </div>
                        <div style={{marginTop: '25px'}}>
                            <button disabled={this.state.sendingInProgress} className='message-send-button' onClick={() => {
                                if (!this.state.sendingInProgress){
                                    this.onSendMessage();
                                }
                            }}><img
                                style={{paddingBottom: '4px'}} src="/images/send.svg" alt=""/></button>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        notification: state.notification,
        messages: state.messages,
        basket: state.basket,
    };
};

Conversation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps)(Conversation));
import React from 'react';
import {connect} from "react-redux";
import {
    addGoodsToCollection,
    deleteGoodsFromCollection,
    goodsCollections,
    goodsCollectionsAdmin, updateCollectionOrder
} from "../api/GoodsAPI";
import {Link} from "react-router-dom";
import {refreshSession} from "../actions/authorization";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Pagination from "material-ui-flat-pagination";
import * as ReactGA from "react-ga";


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
        color: '#5B6273',
        fontFamily: "Open Sans",
        fontSize: '12px',
        lineHeight: '17px'
    },
    textSecondary: {
        opacity: '0.5',
        color: '#283149',
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '19px'
    },
    textPrimary: {
        color: '#4A90E2', fontFamily: 'Open Sans', fontSize: '14px', lineHeight: '19px'
    }
});


class CollectionAdminPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentCategory: {},
            availableCategories: [],
            filter: {
                page: 0,
                size: 16

            },
            collection: {
                title: '',
                goodsAdminList: {
                    content: []
                }
            },
            value: {
                min: props.dict.limits.min,
                max: props.dict.limits.max
            },
            loading: true,
            offset: 0,
            limit: 1,
            total: 1,
            goodsId: null,
            orderNew: null

        }
    }

    componentDidMount() {
        if (window){
            window.scrollTo(0, 0);
        }
        this.searchCollection();

    }

    componentWillUnmount() {

    }

    componentWillMount() {
        // const parsed = queryString.parse(location.search);
        // let filter = {...this.state.filter};
        // this.setState({filter});
    }


    searchCollection = () => {
        if (typeof window !== 'undefined'){
            window.scrollTo(0, 0);
        }
        this.setState(()=>({loading: true}));
        // let cat = typeof this.props.match.params.child === 'string' ? this.props.match.params.child : this.props.match.params.alias;
        goodsCollectionsAdmin(this.props.match.params.id, this.state.filter).then(
            response => {
                let resp  = JSON.parse(response);
                    this.setState(() => ({
                        collection: resp,
                        limit: resp.size,
                        total: resp.totalElements

                }));
                this.setState(()=>({loading: false}));
            },
            error => {
                this.setState(()=>({loading: false}));
                let resp = JSON.parse(error.message);
                if (resp.status === 401 || resp.status === 403) {
                    if (typeof localStorage !== 'undefined'){
                        if (!!localStorage.getItem("session")){
                            this.props.dispatch(refreshSession()).then(() => {
                                this.searchCollection();
                            });
                        }

                    }

                }
            }
        );
    };

    hexToRGB = (hex) => {
        const RGB_HEX = /^#?(?:([\da-f]{3})[\da-f]?|([\da-f]{6})(?:[\da-f]{2})?)$/i;

        const [, short, long] = String(hex).match(RGB_HEX) || [];

        if (long) {
            const value = Number.parseInt(long, 16);
            return [value >> 16, value >> 8 & 0xFF, value & 0xFF];
        } else if (short) {
            return Array.from(short, s => Number.parseInt(s, 16)).map(n => (n << 4) | n);
        }

    };

    onChangeOrderNew = (ev) => {
        this.setState({orderNew: ev.target.value});
    };

    onChangeGoodsId = (ev) => {
        this.setState({goodsId: ev.target.value});
    };

    onAddGoodsToCollection = () => {
        addGoodsToCollection(this.props.match.params.id, {goodsId: this.state.goodsId, order: this.state.orderNew}).then(
            response => {
                this.setState({goodsId: '', orderNew: ''});
                this.searchCollection();
            }, error => {
                this.setState({goodsId: '', orderNew: ''});
                console.log(error);
            }
        ).catch(error => console.log(error));
    };

    onDeleteGoodsFromCollection = () => {
        deleteGoodsFromCollection(this.props.match.params.id, {goodsId: this.state.goodsId}).then(
            response => {
                this.setState({goodsId: '', orderNew: ''});
                this.searchCollection();
            }, error => {
                this.setState({goodsId: '', orderNew: ''});
                console.log(error);
            }
        ).catch(error => console.log(error));
    };

    onUpdateCollectionOrder = () => {
        updateCollectionOrder(this.props.match.params.id).then(
            response => {
                this.searchCollection();
            }, error => {
                console.log(error);
            }
        );
    };

    onOrderChange = (ev) => {
        let filter = this.state.filter;
        filter.sort = ev.target.value;
        this.setState({filter: filter});
        this.searchCollection();
    };

    changePage = (e, offset, page) => {
        let filter = this.state.filter;
        filter.page = page - 1;
        this.setState(() => ({filter: filter, offset: offset}));
        this.searchCollection();

    };

    render() {

        const { classes, theme } = this.props;

        return (
            <div>

                <div id="path-category">
                    <span>Главная  / {this.state.collection.title}  </span>
                </div>
                <div className="row catalog-box">
                    <div className="col">
                        <div className="row">
                            <div className="col-lg-12">
                                <input type="text"
                                       className='input-text-element-225'
                                       placeholder={'goodsId'}
                                       value={this.state.goodsId}
                                       onChange={this.onChangeGoodsId}
                                       style={{
                                           paddingLeft: '20px',
                                           marginTop: '10px'
                                       }}/>
                                <input type="text"
                                       className='input-text-element-225'
                                       value={this.state.orderNew}
                                       onChange={this.onChangeOrderNew}
                                       placeholder={'order to sort'}
                                       style={{
                                           paddingLeft: '20px',
                                           marginTop: '10px'
                                       }}/>
                                <button className='btn-link-danger-mid' style={{paddingTop: 0}} onClick={this.onAddGoodsToCollection}>Добавить</button>
                                <button className='btn-link-danger-mid' style={{paddingTop: 0}} onClick={this.onDeleteGoodsFromCollection}>Удалить</button>
                                <button className='btn-link-danger-mid' style={{paddingTop: 0}} onClick={this.onUpdateCollectionOrder}>Обновить</button>

                            </div>
                        </div>
                        <div className="category-product-box" style={{marginTop: '15px'}}>


                            {   this.state.loading ? <CircularProgress color="secondary" style={{
                                marginLeft: '380px'
                                }} /> :
                                this.state.collection.goodsAdminList.content.length > 0 ?
                                this.state.collection.goodsAdminList.content.map((product) => {
                                    return (
                                        <div key={product.id} className="category-product-card">
                                            <div>
                                                <span>{product.order}</span>
                                            </div>
                                            <Link className='link-block' to={'/product/' + product.id}>
                                            <div className="search-card-goods-image"
                                                 style={{
                                                     backgroundImage: 'url("' + product.photos.filter((ph) => {
                                                         return ph.orderPlace === 1
                                                     })[0].photoMin + '")',
                                                     backgroundSize: 'cover',
                                                     backgroundRepeat: 'no-repeat',
                                                     backgroundPositionX: 'center',
                                                     backgroundPositionY: 'center',
                                                     position: 'relative'
                                                 }}
                                            >
                                                {
                                                    product.isAvailable
                                                        ?
                                                        <div style={
                                                            {
                                                                width: '83px',
                                                                height: '25px',
                                                                backgroundColor: '#79CA1F',
                                                                display: 'block',
                                                                position: 'absolute',
                                                                bottom: '0',
                                                                borderRadius: '0 5px 0 0'
                                                            }
                                                        }><span style={
                                                            {
                                                                color: '#FFFFFF',
                                                                fontFamily: 'Open Sans',
                                                                fontSize: '12px',
                                                                lineHeight: '20px',
                                                                marginLeft: '10px'
                                                            }
                                                        }>В наличии</span> </div>
                                                        :
                                                        ''
                                                }

                                            </div>
                                            <div className="main-card-goods-desc">
                                                <div className="main-card-goods-name">
                                                    <Link className="main-card-goods-name-text"
                                                          to={'/product/' + product.id}>{product.name}</Link>
                                                </div>
                                                <div className="main-card-goods-store">
                                                    <Link to={'/store/'+product.store.id}
                                                       className="main-card-goods-store-link">{product.store.name}</Link>
                                                </div>
                                                <div className="main-card-goods-cost">
                                                    {
                                                        product.oldCost ?
                                                            <span
                                                                className="main-card-goods-cost crossed-red-cost" style={{fontSize: 11}} >{product.oldCost ? product.oldCost.cost : 0} {product.oldCost ? product.oldCost.currency : 'BYN '}  </span>
                                                            : ''
                                                    }
                                                    <span
                                                          className="main-card-goods-cost">{product.cost.cost} BYN</span>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    );
                                })
                                :
                                    <div className='d-flex justify-content-center'>
                                        <span>Ничего не найдено</span>
                                    </div>
                            }


                        </div>
                        <div className={'d-flex justify-content-center'}>
                                <Pagination
                                    limit={this.state.collection.goodsAdminList.size}
                                    offset={this.state.offset}
                                    total={this.state.collection.goodsAdminList.totalElements}
                                    currentPageColor={'primary'}
                                    otherPageColor={'secondary'}
                                    classes={{textSecondary: classes.textSecondary, textPrimary: classes.textPrimary}}
                                    onClick={(e, offset, page) => this.changePage(e, offset, page)}
                                />
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        dict: state.dictionary.dict,
        user: state.user,
        settings: state.settings,
        basket: state.basket,
        messages: state.messages
    };
};

CollectionAdminPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps)(CollectionAdminPage));
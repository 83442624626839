import {refreshToken, logout} from "../api/AuthorizationApi";
import {deleteUser, saveUser} from "./user";
import {saveBasket} from "./basket";
import {setConversations} from "./messages";


export const saveSession = (session) => ({
    type: 'SAVE_SESSION',
    session: session
});


export const refreshSession = () => {
    return (dispatch) => {
        return refreshToken().then(
            response => {
                localStorage.setItem('session', response);
                dispatch(saveSession(JSON.parse(response)));
            },
            error => {
                localStorage.removeItem('session');
                dispatch(startLogoutAction());
                console.log(error);
            }
        );
    };
};



export const logoutAction = () => ({
    type: 'LOGOUT',
    authorization: {},
});

export const startLogoutAction = () => {
    try{
        window.crafty.stompClient.deactivate();
    }catch (e) {
        console.error(e);
    }
    return (dispatch) => {
        return logout().then(
            response => {
                localStorage.removeItem('session');
                dispatch(logoutAction());
                dispatch(deleteUser());
                dispatch(saveBasket({count: 0, items: []}));
                dispatch(setConversations({}))
            },
            error => {
                localStorage.removeItem('session');
                dispatch(logoutAction());
                dispatch(deleteUser());
                dispatch(saveBasket({count: 0, items: []}));
                dispatch(setConversations({}))

            }
        );
    };
};